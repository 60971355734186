import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter, Redirect } from 'react-router-dom';
import moment from 'moment';

import * as actions from '../../../actions';

// import LeftImage from '../../../images/info-image-three.jpg';
import RightImage from '../../../images/info-image-one.jpg';

class Receipt extends Component {
    constructor(props) {
        super(props);
        this.state = {
            date: '',
            delivery: this.props.delivery,
            fetching: true
        }
    }

    componentDidMount() {
        let id = this.props.match.params.id;
        this.props.getDelivery(id)
            .then(resp => {
                this.setState({
                    delivery: resp.body.data.delivery,
                    fetching: false
                })
            })
    }

    renderContent() {
        if (!this.state.fetching) {
            return (<>
            <Row center="xs">
                <Col xs={12} md={12}>
                    <div className="receipt__header">
                        <h3>YOUR MEAL IS NOW CONFIRMED.</h3>
                    </div>
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={4}>
                    <div className="receipt__left__block">
                        <p>
                            your food will be delivered at<br/> 
                            <span style={{ color:  `rgb(2, 12, 126)` }}>{this.state.delivery.deliveryTime} on {moment(this.state.delivery.deliveryDate.split("T")[0]).format("MMMM DD, YYYY")}.</span>
                        </p>
                        {/* <img src={LeftImage} alt="meal-prepping" className="receipt__left__image" /> */}
                    </div>
                </Col>
                <Col xs={12} md={4}>
                    <div className="receipt__center__block">
                        <Row center="xs">
                            <Col xs={12}>
                                <p className="receipt__menu__header"><span style={{ color: `rgb(34, 56, 119)` }}>meal of the month</span></p>
                            </Col>
                        </Row>
                        <Row center="xs">
                            <Col xs={12}>
                                <p className="receipt__menu__body">
                                    includes <span style={{ color: `rgb(34, 56, 119)` }}>sides and desserts</span> <br />
                                    serves <span style={{ color: `rgb(34, 56, 119)` }}>{this.state.delivery.partySize}</span>
                                </p>
                            </Col>
                        </Row>
                    </div>
                        <Row center="xs">
                            <Col xs={12}>
                                <div className="receipt__price__block">
                                    <p style={{ marginBottom: 0 }}>deposit: $50</p>
                                    <p style={{ marginBottom: 0 }}>final bill: $150</p>
                                    <hr />
                                    <p style={{ marginBottom: 0 }}>total price: $200</p>
                                </div>
                            </Col>
                        </Row>
                </Col>
                <Col xs={12} md={4}>
                    <div className="receipt__right__block">
                        <p>
                            meal will be delivered to: <br />
                            {this.state.delivery.streetAddress} <br />
                        </p>
                        <img src={RightImage} alt="family-meal" className="receipt__right__image" />
                    </div>
                </Col>
            </Row>

            <Row center="xs">
                <Col xs={12} md={12}>
                    <p className="receipt__payment__text">(your payment is due 30 days after your delivery day)</p>
                </Col>
            </Row>

            <Row center="xs">
                <Col xs={12} md={12}>
                    <p className="receipt__email__text">If there are immediate questions - email reservations@eatplacemat.com</p>
                </Col>
            </Row>
            </>)
        }
    }

    render() {
        return (<Grid>
            {this.renderContent()}
        </Grid>)
    }
}

function mapStateToProps(state) { return state }
export default withRouter(connect(mapStateToProps, actions)(Receipt))