import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import moment from 'moment';

import ConfirmationPageImage from '../../../images/confirmation-page-image.JPG';
import LeftImage from '../../../images/info-image-four.jpg';

class ConfirmationPage extends Component {
    constructor(props) {
        super(props);
        this.state = this.props.init;

        console.log(this.state);
    }

    renderMenu() {
        const mealType = this.state.reservation.mealType;
        const menu = this.state.reservation.menu;
        
        // Party
        if (mealType === "party") {
            return menu.dishes.map(item => { return <p>{item.name}</p> });
        // Spring & Fall Dinners
        } else if (mealType === "fall + winter dinner" || mealType === "spring + summer dinner") {
            console.log(menu);
            return (<>
                <p className="confirmation__menu__subheader">Starter</p>
                <p className="confirmation__body__text confirmation__menu__item">{menu.starter.name}</p>
                <p className="confirmation__menu__subheader">We've got the salad</p>
                <p className="confirmation__menu__subheader">Entree</p>
                <p className="confirmation__body__text confirmation__menu__item">{menu.entree.name}</p>
                <p className="confirmation__menu__subheader">Sides</p>
                <p className="confirmation__body__text confirmation__menu__item">{menu.sides[0].sideOne.name}</p>
                <p className="confirmation__body__text confirmation__menu__item">{menu.sides[1].sideTwo.name}</p>
                <p className="confirmation__menu__subheader">and, we've got the dessert.</p>
            </>)
        // Brunch
        } else if (mealType === "brunch") {
            return (<>
                <p className="confirmation__menu__subheader">Entrees</p>
                <p className="confirmation__body__text confirmation__menu__item">{menu.entree[0].entreeOne.name}</p>
                <p className="confirmation__body__text confirmation__menu__item">{menu.entree[1].entreeTwo.name}</p>
                <p className="confirmation__menu__subheader">Sides</p>
                <p className="confirmation__body__text confirmation__menu__item">{menu.sides[0].sideOne.name}</p>
                <p className="confirmation__body__text confirmation__menu__item">{menu.sides[1].sideTwo.name}</p>
            </>);
            
        // Favorites 
        } else if (mealType === "favorites") {
            return (<>
                <p className="confirmation__menu__subheader">Favorites</p>
                <br />
                <p className="confirmation__body__text confirmation__menu__item">
                    We’ll bring our best. It’s as simple as it sounds!<br /> 
                    Let us take care of the menu by preparing 6 of our most popular dishes.<br />
                    From starter to dessert!
                </p>
            </>)
        }
        return (<>

        </>)
    }

    renderDietaryRestrictions(restrictions) {
        let appliedRestrictions = [];
        restrictions.map(restriction => { 
            if (restriction.required) { return appliedRestrictions.push(restriction.type.slice(0,1).toUpperCase() + restriction.type.slice(1, restriction.type.length)) }
            else return null;
         }) 
         console.log(appliedRestrictions);
         if (appliedRestrictions[0] === "None" && appliedRestrictions.length === 1) {
             return <span>N/A</span>
         } else {
             return <span>{appliedRestrictions.join(', ').split(0, appliedRestrictions.length-1)}</span>  
         }
    }

    render() {
        const { reservationTime : time, reservationSize, reservationDate : date } = this.state.reservation;
        let price = 0;
        if (reservationSize < 8) {
            switch (reservationSize) {
                case ("2"):
                case ("3"):
                    price = 300;
                    break;
                case ("4"):
                    price = 400;
                    break;
                case("5"):
                case("6"):
                case("7"):
                    price = 450;
                    break;
                default:
                    break;
            }
        } else {
            price = reservationSize * 60;
        }
        return (<>
            <div className="confirmation__header__wrapper"><span className="confirmation__header">Your reservation is now confirmed!</span></div>
            <div className="confirmation__header__wrapper"><span className="confirmation__header">Thank you from the Placemat team.</span></div>
            <Grid fluid className="confirmation__container">
                <Row center="xs">
                    <Col xs={10} md={3} className="confirmation__column one">
                        <div className="confirmation__card three hidden__on__mobile">
                            <Row>
                                <img src={ConfirmationPageImage} className="confirmation__right__image" alt="confirmation__right" />
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={5} className="confirmation__column two">
                        <div className="confirmation__card two">
                        <Row>
                            <Col xs={12}>
                                <p className="confirmation__your__menu__header">your <span style={{ color: `rgb(34, 56, 119)` }}>menu</span></p>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                {this.renderMenu()}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="confirmation__price__wrapper">
                                    total price: ${price}
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12}>
                                <div className="confirmation__price__wrapper" style={{ fontSize: `16px`, paddingBottom: `2.5%` }}>
                                    (this <span style={{ fontStyle: 'italic' }}>includes</span> your deposit)
                                </div>
                            </Col>
                        </Row>
                        </div>
                    </Col>
                    <Col xs={10} md={3} className="confirmation__column three">
                        <div className="confirmation__card one">
                        <Row center='xs' style={{ marginBottom: `1rem` }}>
                            <Col xs={12}>
                                <p className="confirmation__menu__subheader">
                                    reservation details
                                </p>
                            </Col>
                        </Row>
                        <Row className="confirmation__itinerary__row">
                            <Col xs={12}>
                                <p className="confirmation__body__text">Date: {moment(date.split('T')[0]).format("MMMM D, YYYY")}</p>
                            </Col>
                        </Row>
                        <Row className="confirmation__itinerary__row">
                            <Col xs={12}>
                                <p className="confirmation__body__text">Party Size: {reservationSize}</p>
                            </Col>
                        </Row>
                        <Row className="confirmation__itinerary__row">
                            <Col xs={12}>
                                <p className="confirmation__body__text">Dietary Restrictions: {this.renderDietaryRestrictions(this.state.reservation.dietaryRestrictions)}</p>
                            </Col>
                        </Row>
                        <Row className="confirmation__itinerary__row">
                            <Col xs={12}>
                                <p className="confirmation__body__text">Placemat Arrival: {moment(time).utcOffset('-0600').subtract(90, 'minutes').format("h:mma")}</p>
                            </Col>
                        </Row>
                        <Row className="confirmation__itinerary__row">
                            <Col xs={12}>
                                <p className="confirmation__body__text">Reservation Time: {moment(time).utcOffset('-0600').format("h:mma")}</p>
                                {/* <p className="confirmation__body__text">Reservation Time: {moment(time).utc().format("h:mma")}</p> */}
                            </Col>
                        </Row>
                        <Row className="confirmation__itinerary__row">
                            <Col xs={12}>
                                <p className="confirmation__body__text">Placemat Departure: {moment(time).utcOffset('-0600').add(90, 'minutes').format("h:mma")}</p>
                            </Col>
                        </Row>
                        </div>
                        
                    </Col>
                    <Col xs={10} md={3} className="confirmation__column one">
                        <div className="confirmation__card three shown__on__mobile">
                            <Row>
                                <img src={ConfirmationPageImage} className="confirmation__right__image" alt="confirmation__right" />
                            </Row>
                        </div>
                    </Col>
                </Row>
                <Row center="xs" style={{ marginTop: `2.5rem`}}>
                    <Col xs={10}>
                    <p className="confirmation__body__text">(subsequent to the event, the final invoice will be sent to your email)</p>
                    </Col>
                </Row>
                <Row center="xs" style={{ marginTop: `1rem`}}>
                    <Col xs={10}>
                    <p className="confirmation__body__text" style={{ fontSize: `24px`, color: `rgb(34, 56, 119)` }}>we are looking forward to cooking our feel-good food for you and your guests!</p>
                    </Col>
                </Row>
                <Row center="xs" style={{ marginTop: `1rem`}}>
                    <Col xs={11}>
                    <p className="confirmation__body__text confirmation__questions">If there are immediate questions please don't hesitate - email us at <br /><a style={{ textDecoration: 'underline' }} href="@mailto:reservations@eatplacemat.com">reservations@eatplacemat.com</a></p>
                    </Col>
                </Row>
            </Grid>
        </>)
    }
}

export default ConfirmationPage;