import React, { Component } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';

import awsmobile from '../../../aws-exports';
import * as actions from '../../../actions';

// Components
import Navigation from '../navigation/navigation';
import InputField from '../../components/InputField';
import PrimaryButton from '../../components/PrimaryButton';

Amplify.configure(awsmobile);
const uuidv1 = require('uuid/v1');

class InviteChefs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            step: 'form',
            name: '',
            email: '',
            phone: '',
            isButtonDisabled: true
        }

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.switchStep = this.switchStep.bind(this);
        this.handleButtonEnable = this.handleButtonEnable.bind(this);
        this.inviteChef = this.inviteChef.bind(this);
    }

    componentDidMount() {
        return this.setState({ loading: true }, () => {
            return Auth.currentAuthenticatedUser()
                .then(user => {
                    if (user.signInUserSession.idToken.payload['cognito:groups'][0] !== 'admins') {
                        alert('Incorrect account type.');
                        return Auth.signOut()
                            .then(() => {
                                return this.setState({ loading: false }, () => this.props.history.push('/admin'))
                            })
                    } else {
                        return this.props.hydrateUser(user.attributes.sub, 'admin')
                            .then(() => {
                                return this.setState({ loading: false })
                            })
                    }
                })
            .catch(error => {
                console.log(error);
                return this.props.history.push('/admin')
            })
        })
    }

    handleNameChange(event) {
        this.setState({ 
            name: event.target.value
        }, () => {
            this.handleButtonEnable()
        })
    }

    handleEmailChange(event) {
        this.setState({
            email: event.target.value
        }, () => {
            this.handleButtonEnable()
        })
    }

    handlePhoneChange(event) {
        this.setState({
            phone: event.target.value
        }, () => {
            this.handleButtonEnable()
        })
    }

    handleButtonEnable() {
        if (this.state.email && this.state.name && this.state.phone) {
            this.setState({ isButtonDisabled: false })
        } else {
            this.setState({ isButtonDisabled: true })
        }
    }

    switchStep(step) {
        this.setState({
            step: step
        })
    }

    inviteChef() {
        let id = uuidv1();
        let password = `Temp-${id}`
        this.setState({ loading: true  }, () =>{
            return Auth.signUp({
                username: this.state.email, password: password,
                attributes: { email: this.state.email }
            }).then(resp => {
                let chef = {
                    id: resp.userSub,
                    name: this.state.name,
                    email: this.state.email,
                    phone: this.state.phone,
                    temporaryPassword: password,
                    userType: 'chef'
                }
                this.props.createChef(chef)
                    .then(resp => {
                        if (resp.code === 200) {
                            // let url = `http://localhost:3000/chefs/confirm-account/?id=${chef.id}`
                            let url = `https://reservations.eatplacemat.com/chefs/confirm-account/?id=${chef.id}`
                            let email = {
                                recipient: this.state.email,
                                sender: 'no-reply@eatplacemat.com',
                                subject: `Congrats!`,
                                html: `<div>
                                    <h3>You've been invited to be a Placemat Chef!</h3>
    
                                    <p><a href=${url}>Please confirm your account</a></p>
                                </div>`
                            }
                            this.props.sendEmail(email);
                            this.setState({ loading: false })
                        } else {
                            alert("Error!")
                            this.setState({ loading: false })
                        }
                    })
            })
        })
    }

    renderContent() {
        switch (this.state.step) {
            case('form'):
                return (<>
                    <Row center="xs">
                        <Col xs={12} md={5}>
                            <InputField placeholder="name" value={this.state.name} onChange={this.handleNameChange} type="text" />
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={5}>
                            <InputField placeholder="email" value={this.state.email} onChange={this.handleEmailChange} type="text" />
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={5}>
                            <InputField placeholder="phone" value={this.state.phone} onChange={this.handlePhoneChange} type="text" />
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={5}>
                            <PrimaryButton onClick={() => this.switchStep('confirm')} label="Next" disabled={this.state.isButtonDisabled} />
                        </Col>
                    </Row>
                </>)
            case('confirm'):
                return (<>
                    <Row center="xs">
                        <Col xs={12} md={6}>
                            <p>Please confirm the following information is correct:</p>
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={6}>
                            <p>Name: {this.state.name}</p>
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={6}>
                            <p>Phone: {this.state.email}</p>
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={6}>
                            <p>Email: {this.state.phone}</p>
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={5}>
                            <PrimaryButton onClick={this.inviteChef} label="Invite Chef" />
                        </Col>
                    </Row>
                </>)
            case('success'):
                return (<>
                
                </>)
            default:
                return;
        }
        return (<>
        
        </>)
    }

    render() {
        return (<div>
            <Navigation />
            <Grid fluid>
                <Row center="xs">
                    <Col xs={12} md={12}>
                        <h3>Invite Chefs</h3>
                    </Col>
                </Row>
                {this.renderContent()}
                {/* Loading */}
                <Row center="xs" style={{ visibility: this.state.loading ? 'visible' : 'hidden' }}>
                    <div className="loading-icon" style={{ 
                        position: 'fixed',
                        top: `30%`,
                        textAlign: 'center',
                        zIndex: 10,
                    }}>
                        <ReactLoading type="bubbles" color="black" height={100} width={150} />
                    </div>
                </Row>
            </Grid>
        </div>)
    }
}

function mapStateToProps(state) { return state }
export default withRouter(connect(mapStateToProps, actions)(InviteChefs));