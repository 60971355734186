import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import PrimaryButton from '../../components/PrimaryButton';
import TextArea from '../../components/TextArea';

// ========================= //
// ===== WHY PLACEMAT? ===== //
// ========================= //
class ApplicationThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            additionalInfo: this.props.application.additionalInfo || '',
            isButtonDisabled: true
        }
    
        this.handleAdditionalInfoChange = this.handleAdditionalInfoChange.bind(this); 
    }

    handleAdditionalInfoChange(event) {
        this.setState({ additionalInfo: event.target.value })
    }

    handleStepChange(step) { 
        let stepThreeApplication = {
            // NOT PART OF THIS STEP
            firstName: this.props.application.firstName,
            lastName: this.props.application.lastName,
            email: this.props.application.email,
            // PART OF THIS STEP
            city: this.props.application.city,
            state: this.props.application.state,
            zip: this.props.application.zip,
            travelAvailability: this.props.application.travelAvailability,
            // NOT PART OF THIS STEP
            additionalInfo: this.state.additionalInfo
        }
        this.props.toggleStep(step, stepThreeApplication)
    }

    render() {
        return (
            <Grid fluid>
                <Row around="xs">
                    <p>Step Three</p>
                </Row>
                <Row>
                    <Col xs={12} md={6} mdOffset={3}>
                        <TextArea 
                            value={this.state.additionalInfo}
                            placeholder="Anything else you'd like us to know?"
                            onChange={this.handleAdditionalInfoChange}
                        />
                    </Col>
                </Row>
                <Row around="xs">
                    <Col xs={12} md={6}>
                        <PrimaryButton onClick={() => this.handleStepChange('two')} label="Back" />
                    </Col>
                    <Col xs={12} md={6}>
                        <PrimaryButton onClick={() => this.handleStepChange('confirm')} label="Next" />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default ApplicationThree;