import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';


import * as actions from '../../../actions';

// Components
import InputField from '../../components/InputField'
import PrimaryButton from '../../components/PrimaryButton';

// Styles
import './login.css';

// Images
import Logo from '../../../images/logo.png';
import Icon from '../../../images/icon.png';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            showEmailError: false,
            emailErrorMessage: '',
            password: '',
            showPasswordError: false,
            passwordErrorMessage: '',
            isButtonDisabled: true
        }

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value }, () => this.handleButtonEnable())
    }

    handlePasswordChange(event) {
        this.setState({ password: event.target.value }, () => this.handleButtonEnable())
    }

    handleButtonEnable() {
        if (this.state.email !== '' && this.state.password !== '') {
            this.setState({ isButtonDisabled: false })
        } else {
            this.setState({ isButtonDisabled: true })
        }
    }

    handleLoginSubmit() {
        this.props.tryLogin({
            email: this.state.email,
            password: this.state.password
        })
        .then(resp => {
            if (resp.code === 200) {
                console.log(resp);
                return this.props.history.push('/chefs/dashboard')
            } else {
                console.log(resp);
            }
        })
    }

    render() {
        return (
        <div className="login-body">
        <div className="login-card">
        <Grid fluid>
            <Row center="xs" className="login-card-header">
                <Col xs={12} md={1}>
                    <img src={Icon} alt="icon" className="login-card-icon" />
                </Col>
                <Col md={1} />
                <Col xs={12} md={8}>
                    <img src={Logo} alt="logo" className="login-card-logo" />
                </Col>
                <Col md={2} />
            </Row>
            <Row around="xs">
                <Col xs={12} md={3}>
                    <InputField
                        value={this.state.email}
                        placeholder="Email"
                        onChange={this.handleEmailChange}
                        showError={this.state.showEmailError}
                        errorMessage={this.state.emailErrorMessage}
                    />
                </Col>
            </Row>
            <Row around="xs">
                <Col xs={12} md={3}>
                    <InputField
                        value={this.state.password}
                        placeholder="Password"
                        type="password"
                        onChange={this.handlePasswordChange}
                        showError={this.state.showPasswordError}
                        errorMessage={this.state.passwordErrorMessage}
                    />
                </Col>
            </Row>
            <Row around="xs">
                <Col xs={12} md={3}>
                    <PrimaryButton
                        onClick={this.handleLoginSubmit}
                        label="Login"
                        disabled={this.state.isButtonDisabled}
                    />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={3}>
                    <a href="/chefs/application">Join our team!</a>
                </Col>
            </Row>
        </Grid>
        </div>
        </div>)
    }
}

function mapStateToProps(state) {
    return {
        state
    }
}

export default withRouter(connect(mapStateToProps, actions)(Login));