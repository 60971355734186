import React, { Component } from 'react';
import { connect } from 'react-redux';
import { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Grid, Row, Col } from 'react-flexbox-grid';
import {
    // CardElement,
    Elements,
    CardNumberElement,
    CardExpiryElement,
    CardCvcElement,
    injectStripe,
    StripeProvider,
    ElementsConsumer
  } from '@stripe/react-stripe-js';

import * as actions from '../../actions';

import "./Stripe.css";


// Custom styling can be passed to options when creating an Element.

const ELEMENT_OPTIONS = {
    style: {
      base: {
        fontSize: '18px',
        color: '#424770',
        letterSpacing: '0.025em',
        '::placeholder': {
          color: '#aab7c4',
        },
        width: `100%`
      },
      invalid: {
        color: '#9e2146',
      },
    },
  };

class CheckoutForm extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        postal: '',
        errorMessage: null,
        paymentMethod: null,
        paymentIntent: null,
        fetching: false
      };
    }

    componentDidMount() {
        this.setState({
            loading: true,
            reservation: this.props.reservation
        })

        this.getPaymentIntent();
       
    }

    getPaymentIntent = async () => {
        await fetch('https://gjocqucvnh.execute-api.us-east-1.amazonaws.com/prod/stripe/paymentIntent', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                amount: 5000
            })
        }).then(response => response.json())
        .then(data => { 
            console.log(data);
            this.setState({
                paymentIntent: data.body,
                loading: false
            }, () => { console.log(this.state.paymentIntent)
        })})
        .catch(err => { console.log(err) })
    }
  
    handleSubmit = async (event) => {
        this.setState({ fetching: true })
            event.preventDefault();
            const {stripe, elements} = this.props;
            const postal = this.state.postal;
            const name = this.props.reservation.name;
    
            if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
            }
    
            const cardNumber = elements.getElement(CardNumberElement);
            let payment_method = {
                card: cardNumber,
                billing_details: {
                    name: name,
                    address: {
                        postal_code: postal
                    }
                },
            }
            // console.log(payment_method);
            const payload = await stripe.confirmCardPayment(`${this.state.paymentIntent.client_secret}`, { payment_method })
            
            if (payload.error) {
                console.log('[error]', payload.error);
                this.setState({
                  errorMessage: payload.error.message,
                  paymentMethod: null,
                  fetching: false
                });
              } else {
                console.log('[PaymentMethod]', payload.paymentMethod);
                this.setState({
                  paymentMethod: payload.paymentMethod,
                  errorMessage: null,
                  fetching: false
                });
                this.props.confirmPayment(true);
              }
        
  
    };

    renderErrors() {
        if (this.state.errorMessage) {
            return <p>{this.state.errorMessage}</p>
        }
    }
    
    renderContent() {
        if (this.state.loading) {
            return;
        } else {
            const {stripe} = this.props;
            const { postal, paymentMethod, errorMessage } = this.state;
            return (<>
             <form onSubmit={this.handleSubmit}>
            <Row>
                <Col xs={12} md={12}>
                    <CardNumberElement
                        id="cardNumber"
                        options={ELEMENT_OPTIONS}
                        />
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={4}>
                    <CardExpiryElement
                        id="expiry"
                        placeholder="MM/YY"
                        options={ELEMENT_OPTIONS}
                    />
                </Col>
                <Col xs={12} md={3}>
                    <CardCvcElement
                        id="cvc"
                        placeholder="CVC"
                        options={ELEMENT_OPTIONS}
                    />
                </Col>
                <Col xs={12} md={5}>
                    <input
                        id="postal"
                        required
                        placeholder="Zip Code"
                        value={postal}
                        onChange={(event) => {
                            this.setState({postal: event.target.value});
                        }}
                    />
                </Col>
            </Row>
            {this.renderErrors()}
            {paymentMethod && (
                <p>Got PaymentMethod: {paymentMethod.id}</p>
            )}
            <Row>
                <Col xs={12} md={6}>
                    <button onClick={this.props.navigateToConfirm}>
                        Go Back
                    </button>
                </Col>
                <Col xs={12} md={6}>
                    { this.state.fetching ? <p>Processing...</p> :
                        <button type="submit" disabled={!stripe}>
                            Pay
                        </button>
                    }
                </Col>
            </Row>
        </form>
            </>)
        }
    }
  
    render() {
  
      return (<Grid fluid id="stripe-form">
        {this.renderContent()}
        </Grid>);
    }
  }
  
class InjectedCheckoutForm extends Component {
    render() {
        return (
        <ElementsConsumer>
          {({stripe, elements}) => (
            <CheckoutForm stripe={stripe} elements={elements} reservation={this.props.reservation}  />
          )}
        </ElementsConsumer>
        )
    }
}
  
  // Make sure to call `loadStripe` outside of a component’s render to avoid
  // recreating the `Stripe` object on every render.
  const stripePromise = loadStripe('pk_live_51HIZpaD903v3xS7AnMhufOXzhOL0mL52kzC4A4DIgxWsEvPBdGprgBQhNtYzRRssm7OKtAGmOpGoC1IODq9jZgnE00ETKmYSb0');
  
class App extends Component {
    componentDidMount() {
        this.setState({
            reservation: this.props.reservation
        })
    }
    render() {
        return (
          <Elements stripe={stripePromise}>
            {/* <InjectedCheckoutForm reservation={this.props.reservation} /> */}
            <ElementsConsumer>
                {({stripe, elements}) => (
                    <CheckoutForm stripe={stripe} elements={elements} confirmPayment={this.props.confirmPayment} reservation={this.props.reservation} navigateToConfirm={this.props.navigateToConfirm}  />
                )}
            </ElementsConsumer>
          </Elements>
        );
    }
  };

function mapStateToProps(state) { return state }
export default connect(mapStateToProps, actions)(App);