import React, { Component } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import ReactLoading from 'react-loading';

import * as actions from '../../../actions';
import awsmobile from '../../../aws-exports';

import InputField from '../../components/InputField';
import PrimaryButton from '../../components/PrimaryButton';
import Logo from '../../../images/logo.png'; 


Amplify.configure(awsmobile);

class ConfirmChef extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            verifyNewPassword: '',
            code: '',
            step: 'code',
            // step: 'password',
            displayErrorMessage: false,
            errorMessage: ''
        }

        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleVerifyNewPasswordChange = this.handleVerifyNewPasswordChange.bind(this);
        this.handleCodeChange = this.handleCodeChange.bind(this);
        this.confirmChef = this.confirmChef.bind(this);
        this.changePassword = this.changePassword.bind(this);
    }
    
    handleNewPasswordChange(event) { this.setState({ newPassword: event.target.value }) }
    handleVerifyNewPasswordChange(event) { this.setState({ verifyNewPassword: event.target.value })}
    handleCodeChange(event) { this.setState({ code: event.target.value }) }

    componentDidMount() {
        let id = this.props.location.search.split('=')[1];
        this.props.getChef(id)
            .then(resp => {
                this.setState({
                    chef: resp.message
                })
            })
    }

    confirmChef() {
        this.setState({ loading: true }, () => {
            return this.props.confirmChef(this.state.chef.email, this.state.code)
                .then(resp => {
                    if (resp.code === 200) {
                        this.setState({ 
                            loading: false, 
                            step: 'password' 
                        })
                    } else {
                        alert("Error")
                    }
                })
        })
    }

    changePassword() {
        
        if (this.state.newPassword !== this.state.verifyNewPassword) {
            this.setState({
                displayErrorMessage: true,
                errorMessage: 'Passwords must match!'
            })
        } else {
            let username = this.state.chef.email;
            let password = this.state.chef.temporaryPassword
            this.setState({ loading: true }, () => {
                return Auth.signIn(username, password)
                    .then(user => {
                        console.log(user);
                        console.log(this.state.chef.temporaryPassword);
                        return Auth.changePassword(user, this.state.chef.temporaryPassword, this.state.newPassword);
                    })
                        .then(data => {
                            if (data === "SUCCESS") {
                                this.setState({ 
                                    loading: false,
                                    step: "success"
                                })
                            }
                        })
                .catch(err => console.log(err));
            })
        }
    }

    renderErrorMessage() {
        if (this.state.displayErrorMessage) {
            return <p>{this.state.errorMessage}</p>
        }
    }

    renderForm() {
        switch (this.state.step) {
            case ('code'):
                return (<>
                    <Row center="xs">
                        <Col xs={12} md={6}>
                            <p>Please enter the code that was emailed to you.</p>
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={6}>
                            <InputField placeholder="Code" value={this.state.code} onChange={this.handleCodeChange} type="Code" />
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={6}>
                            <PrimaryButton onClick={this.confirmChef} label="Submit" />
                        </Col>
                    </Row>
                </>)
            case ('password'):
                return (<>
                    <Row center="xs">
                        <Col xs={12} md={12}>
                            <p style={{ textAlign: 'center' }}>Passwords must meet the following requirements:</p>
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={4}>
                            <ul style={{ listStyle: 'none', textAlign: 'left' }}>
                                <li>Be at least 8 characters long</li>
                                <li>At least one capital letter</li>
                                <li>At least one lower case character</li>
                                <li>At least one number</li>
                                <li>At least one symbol (ex. !@#$%^)</li>
                            </ul>
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={6}>
                            <InputField placeholder="Password" value={this.state.newPassword} onChange={this.handleNewPasswordChange} type="password" />
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={6}>
                            <InputField placeholder="Verify Password" value={this.state.verifyNewPassword} onChange={this.handleVerifyNewPasswordChange} type="password" />
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={8}>
                            {this.renderErrorMessage()}
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={6}>
                            <PrimaryButton onClick={this.changePassword} label="Submit" />
                        </Col>
                    </Row>
                </>)
            case("success"):
                return (<>
                    <Row center="xs">
                        <Col xs={12} md={6}>
                            <h3>You're all set!</h3>
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={6}>
                            <p>You will receive an email as soon as a meal is ready to sign up for.</p>
                        </Col>
                    </Row>
                </>)
            default: return;
        }
    }

    render() {
        return (<div>
            <Grid fluid>
                <Row center="xs" className="dashboard-card-header">
                    <Col md={2} />
                    <Col xs={12} md={8}>
                        <img src={Logo} alt="logo" className="dashboard-card-logo" />
                    </Col>
                    <Col md={2} />
                </Row>
                {this.renderForm()}
                {/* Loading */}
                <Row center="xs" style={{ visibility: this.state.loading ? 'visible' : 'hidden' }}>
                    <div className="loading-icon" style={{ 
                        position: 'fixed',
                        top: `30%`,
                        textAlign: 'center',
                        zIndex: 10,
                    }}>
                        <ReactLoading type="bubbles" color="black" height={100} width={150} />
                    </div>
                </Row>
            </Grid>
        </div>)
    }
}

function mapStateToProps(state) { return { state } }
export default withRouter(connect(mapStateToProps, actions)(ConfirmChef))