import React, { Component } from 'react';
import { connect } from 'react-redux';
import Amplify, { Auth } from 'aws-amplify';
import awsmobile from '../../aws-exports';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';

import * as actions from '../../actions';

// Stylesheets
import './landing.css';

// Images
import Icon from '../../images/icon.png';
import Logo from '../../images/logo.png'; 

Amplify.configure(awsmobile);

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: true
        }
    }

    componentDidMount() {
        return Auth.currentAuthenticatedUser()
            .then(user => {
                if (user.signInUserSession.idToken.payload['cognito:groups'][0] !== 'chefs') {
                    alert('Incorrect account type.');
                    return Auth.signOut()
                        .then(() => {
                            this.toggleFetching();
                            return this.setState({ isAuthenticated: false }, () => this.props.history.push('/'))
                        })
                } else {
                    return this.props.hydrateUser(user.attributes.sub, 'chef')
                        .then(() => {
                            this.toggleFetching();  
                            return this.setState({ isAuthenticated: true }, () => this.props.history.push('/chefs/dashboard'));
                        })
                }
            })
            .catch(error => {
                this.toggleFetching();
                return this.props.history.push('/chefs/login')
            })
    }

    toggleFetching() {
        return this.setState({ fetching: !this.state.fetching }, () => console.log(this.state))
    }

    renderContent() {
        if (this.state.fetching) {
            console.log("Is fetching");
            return <p>Loading...</p>
        } else {
            console.log("Done fetching");
            return;
        }
    }

    render() {
        return (
        <div className="landing-body">
            <div className="landing-card">
                <Grid fluid>
                    <Row center="xs" className="landing-card-header">
                        <Col xs={12} md={1}>
                            <img src={Icon} alt="icon" className="landing-card-icon" />
                        </Col>
                        <Col md={1} />
                        <Col xs={12} md={8}>
                            <img src={Logo} alt="logo" className="landing-card-logo" />
                        </Col>
                        <Col md={2} />
                    </Row>
                    {this.renderContent()}
                </Grid>
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return { state }
}

export default withRouter(connect(mapStateToProps, actions)(Landing));