// Dietary Restrictions
import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import PrimaryButton from '../../components/PrimaryButton';
import InputField from '../../components/InputField';

// Cohost Email
class ReservationThree extends Component {
    constructor(props) {
        super(props);
        this.state = {
            cohostName: this.props.reservation.cohostName || '',
            cohostEmail: this.props.reservation.cohostEmail || ''
        }

        this.handleCohostNameChange = this.handleCohostNameChange.bind(this);
        this.handleCohostEmailChange = this.handleCohostEmailChange.bind(this);
    }

    handleStepChange(step) { 
        let stepThreeReservation = {
            // PART OF THIS STEP
            cohostName: this.state.cohostName,
            cohostEmail: this.state.cohostEmail,
            // NOT PART OF THIS STEP
            name: this.props.reservation.name,
            email: this.props.reservation.email,
            phone: this.props.reservation.phone,
            reservationDate: this.props.reservation.reservationDate,
            partySize: this.props.reservation.partySize,
            zipCode: this.props.reservation.zipCode,
            reservationSize: this.props.reservation.reservationSize,
            dietaryRestrictions: this.props.reservation.dietaryRestrictions,
            referral: this.props.reservation.referral,
            giftCode: this.props.reservation.giftCode
        }

        this.props.toggleStep(step, stepThreeReservation)
    }
    handleCohostNameChange(event) { this.setState({ cohostName: event.target.value })}
    handleCohostEmailChange(event) { this.setState({ cohostEmail: event.target.value })}

    render() {
        return (<Grid fluid>
            {/* FORM FIELDS */}
            <Row center="xs">
                <Col xs={12} md={8}>
                    <p style={{ fontSize: `32px`, marginTop: 0, marginBottom: 0 }}>Step Two</p>
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={8}>
                    <p>Will anyone be cohosting?</p>
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={8}>
                    <p>If you don't have a cohost or are unsure, just click next.</p>
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={5}>
                    <InputField placeholder="Cohost Name" value={this.state.cohostName} onChange={this.handleCohostNameChange} type="text" />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={5}>
                    <InputField placeholder="Cohost Email" value={this.state.cohostEmail} onChange={this.handleCohostEmailChange} type="text" />
                </Col>
            </Row>
            {/* END FORM FIELDS */}

            <Row around="xs">
                <Col md={3} />
                <Col xs={12} md={3}>
                    <PrimaryButton onClick={() => this.handleStepChange('one')} label="Back" />
                </Col>
                <Col xs={12} md={3}>
                    <PrimaryButton 
                        onClick={() => this.handleStepChange('four')} 
                        label="Next" 
                        disabled={this.state.isButtonDisabled}
                    />
                </Col>
                <Col md={3} />
            </Row>
        </Grid>)
    }
}

export default ReservationThree;