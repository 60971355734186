import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import PrimaryButton from '../../components/PrimaryButton';
import InputField from '../../components/InputField';


class ReservationOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            name: this.props.reservation.name || '',
            email: this.props.reservation.email || '',
            phone: this.props.reservation.phone || '',
            confirmEmail: '',
            focused: false,
            isButtonDisabled: true,
            displayErrors: false,
            errorMessage: ''
        }

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleConfirmEmailChange = this.handleConfirmEmailChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
    }

    componentDidMount() {
        this.handleButtonEnable();
        console.log(this.props)
    }

    handleNameChange(event) { this.setState({ name: event.target.value }, () => this.handleButtonEnable()) }
    handleEmailChange(event) { this.setState({ email : event.target.value }, () => this.handleButtonEnable()) }
    handleConfirmEmailChange(event) { this.setState({ confirmEmail: event.target.value }, () => this.compareEmails()) }
    handlePhoneChange(event) {  this.setState({ phone: event.target.value }, () => this.handleButtonEnable()) }

    handleButtonEnable() {
        if (this.state.name !== '' && this.state.email !== '' && this.state.email === this.state.confirmEmail && this.state.phone) {
            this.setState({ isButtonDisabled: false })
        } else {
            this.setState({ isButtonDisabled: true })
        }
    }

    compareEmails() {
        if (this.state.email !== this.state.confirmEmail) {
            this.setState({ 
                displayErrors: true,
                errorMessage: 'Emails do not match!'
            }, () => this.handleButtonEnable())
        } else if (this.state.email === this.state.confirmEmail) {
            this.setState({
                displayErrors: false,
                errorMessage: ''
            }, () => this.handleButtonEnable())
        }
    }

    handleStepChange(step, direction) { 
        let stepOneReservation = {
            // PART OF THIS STEP
            name: this.state.name,
            email: this.state.email,
            phone: this.state.phone,
            // NOT PART OF THIS STEP
            zipCode: this.props.reservation.zipCode,
            reservationDate: this.props.reservation.reservationDate,
            reservationSize: this.props.reservation.reservationSize,
            partySize: this.props.reservation.partySize,
            cohostName: this.props.reservation.cohostName,
            cohostEmail: this.props.reservation.cohostEmail,
            dietaryRestrictions: this.props.reservation.dietaryRestrictions,
            referral: this.props.reservation.referral,
            giftCode: this.props.reservation.giftCode
        }

        var re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

        if (direction === 'backward') {
            this.props.toggleStep(step, stepOneReservation);
        } else if (!re.test(String(stepOneReservation.email).toLowerCase())) {
            this.setState({ 
                showEmailError: true,
                emailErrorMessage: 'Please enter a valid email.'  
            })
        } else {
            this.props.toggleStep(step, stepOneReservation)
        }
    }

    render() {
        return (<Grid fluid>
            <Row center="xs">
                <Col xs={12} md={8}>
                    <p style={{ fontSize: `32px`, marginTop: 0, marginBottom: 0 }}>Step One</p>
                </Col>
            </Row>

            <Row center="xs">
                <Col xs={12} md={8}>
                    <p>Let's get this party started!</p>
                </Col>

            </Row>
            {/* FORM FIELDS */}
            <Row center="xs">
                <Col xs={12} md={4}>
                    <InputField placeholder="Name" value={this.state.name} onChange={this.handleNameChange} type="text" />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={4}>
                    <InputField placeholder="Email" value={this.state.email} onChange={this.handleEmailChange} type="text" />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={4}>
                    <InputField placeholder="Confirm Email" value={this.state.confirmEmail} onChange={this.handleConfirmEmailChange} showError={this.state.displayErrors} errorMessage={this.state.errorMessage} type="text" />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={4}>
                    <InputField placeholder="Phone" value={this.state.phone} onChange={this.handlePhoneChange} type="text" />
                </Col>
            </Row>
            {/* END FORM FIELDS */}



            <Row around="xs">
                <Col md={3}></Col>
                <Col xs={12} md={3}>
                    <PrimaryButton onClick={() => this.handleStepChange('landing', 'backward')} label="Back" />
                </Col>
                <Col xs={12} md={3}>
                    <PrimaryButton 
                        onClick={() => this.handleStepChange('three', 'forward')} 
                        label="Next" 
                        disabled={this.state.isButtonDisabled}
                    />
                </Col>
                <Col md={3}></Col>
            </Row>

        </Grid>)
    }
}

export default ReservationOne;