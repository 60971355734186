const party = [
    {
        course: 'party',
        name: 'GF + Dairy-free Ice Cream Sammies',
        imageUrl: 'https://images.pexels.com/photos/3193301/pexels-photo-3193301.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Cast Iron Potatoes + Parsley Oil',
        imageUrl: 'https://images.pexels.com/photos/1829109/pexels-photo-1829109.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'GF Cornbread Waffles + Honey Adobo Jam',
        imageUrl: 'https://images.pexels.com/photos/3471413/pexels-photo-3471413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Hanger Steak + Chimichurri',
        imageUrl: 'https://images.pexels.com/photos/3193296/pexels-photo-3193296.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Spiced Pulled Pork + Mustard Seed + Local Sourdough',
        imageUrl: 'https://images.pexels.com/photos/3471267/pexels-photo-3471267.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Market Vegetable Toasts',
        imageUrl: 'https://images.pexels.com/photos/3471474/pexels-photo-3471474.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Ginger Steak Salad Wrap Board + Pink Radish Sauce',
        imageUrl: 'https://images.pexels.com/photos/3471303/pexels-photo-3471303.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Cashew Queso + Seasonal Vegetables + Cassava Flour Chips',
        imageUrl: 'https://images.pexels.com/photos/3471322/pexels-photo-3471322.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Whipped Ricotta + Chorizo + Seasonal Vegetables + Local Sourdough',
        imageUrl: 'https://images.pexels.com/photos/1829160/pexels-photo-1829160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Baked Cauliflower Hummus + Lamb + Seasonal Vegetables',
        imageUrl: 'https://images.pexels.com/photos/3471366/pexels-photo-3471366.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Green Sauce + Almond Flour Crust Pizza',
        imageUrl: 'https://images.pexels.com/photos/3471388/pexels-photo-3471388.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Sweet Potato Biscuit Monkey Bread',
        imageUrl: 'https://images.pexels.com/photos/3193285/pexels-photo-3193285.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Seasonal Pizza',
        imageUrl: 'https://images.pexels.com/photos/3471236/pexels-photo-3471236.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Classic GF Chocolate Chip Cookies',
        imageUrl: 'https://images.pexels.com/photos/3193304/pexels-photo-3193304.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Cassava Flour Street Tacos',
        imageUrl: 'https://images.pexels.com/photos/1829113/pexels-photo-1829113.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Kaleslaw',
        imageUrl: 'https://images.pexels.com/photos/3193293/pexels-photo-3193293.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Seasonal Squash Salad',
        imageUrl: 'https://images.pexels.com/photos/3193295/pexels-photo-3193295.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'Walking Tacos + Cassava Flour Chips',
        imageUrl: 'https://images.pexels.com/photos/3471546/pexels-photo-3471546.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'party',
        name: 'GF Cast Iron Corncake',
        imageUrl: 'https://images.pexels.com/photos/3471551/pexels-photo-3471551.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    }
]
















export default party;