import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import Amplify, { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';

import awsmobile from '../../../aws-exports';
import * as actions from '../../../actions';

// Stylesheets

// Components
import Navigation from '../navigation/navigation';

Amplify.configure(awsmobile);
var _ = require('lodash');

class Calendar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            return Auth.currentAuthenticatedUser()
                .then(user => {
                    if (user.signInUserSession.idToken.payload['cognito:groups'][0] !== 'admins') {
                        alert('Incorrect account type.');
                        return Auth.signOut()
                            .then(() => {
                                return this.setState({ loading: false }, () => this.props.history.push('/admin'))
                            })
                    } else {
                        return this.props.hydrateUser(user.attributes.sub, 'admin')
                            .then(() => {
                                return this.props.getReservations()
                                    .then(resp => {
                                        let allReservations = resp.reservations;
                                        let unconfirmedReservations = _.filter(resp.reservations, { 'isConfirmed': false })
                                        unconfirmedReservations.sort((a, b) => new Date(a.reservationDate) - new Date(b.reservationDate))
                                        let confirmedReservations = _.filter(resp.reservations, { 'isConfirmed': true })
                                        confirmedReservations.sort((a, b) => new Date(a.reservationDate) - new Date(b.reservationDate))
                                        return this.setState({ loading: false, allReservations, unconfirmedReservations, confirmedReservations }, () => console.log(this.state))
                                    })
                            })
                    }
                })
            .catch(error => {
                return this.props.history.push('/admin')
            })
        })
    }

    createEvent() {
            // var datetime = reservation.reservationDate.split('T')[0] + 'T' + reservation.reservationTime.split('T')[1]
            var datetime = new Date();
            var event = {
                "summary": "CONFIRMED: " + "Justin",
                "location": "2107 Eastwood Avenue" + ' ' + "Nashville" + ', ' + "TN" + ' ' + "37212",
                "description": 'Confirmed Dinner.',
                "start": {
                  "dateTime": datetime,
                  "timeZone": 'America/Chicago',
                },
                "end": {
                    "dateTime": datetime,
                    "timeZone": 'America/Chicago'
                }
            }
            return this.props.addToCalendar(event)
                .then(resp => {
                    console.log(resp);
                })
    }

    renderContent() {
        if (!this.state.loading) {
            return (<>
                <Row around="xs">
                    <h3>Calendar</h3>
                </Row>
                <Row center="xs">
                    <Col xs={12}>
                        {/* <button onClick={this.createEvent.bind(this)}>Create Event</button> */}
                        <iframe title="calendar" src="https://calendar.google.com/calendar/embed?src=ro3tsg0vjooqkj0om00dprqn7g%40group.calendar.google.com&ctz=America%2FChicago" style={{ border: 0, margin: '0 auto' }} width="800" height="600" frameborder="0" scrolling="no"></iframe>
                    </Col>
                </Row>
            </>)
        }
    }

    render() {
        return (<div>
            <Navigation />
            <Grid fluid>
                {this.renderContent()}
            </Grid>
        </div>)
    }
}

function mapStateToProps(state) {
    return {
        state
    }
}
export default withRouter(connect(mapStateToProps, actions)(Calendar))