import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import ReactLoading from 'react-loading';


import * as actions from '../../../actions';

// Styles
import './dashboard.css';

// Components
import EmptySlot from './EmptySlot';
import FilledSlot from './FilledSlot';

const R = require('ramda');
var _ = require('lodash');

class Reservation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fetching: false,
            loading: false,
            chef: this.props.chef,
            reservation: this.props.reservation
        }

        this.signupChef = this.signupChef.bind(this);
        this.removeChef = this.removeChef.bind(this);
        
    }

    componentDidMount() {
        
    }

     signupChef(chef, reservation) {
        this.setState({ loading: true }, () => {
            this.props.signupForReservation(chef, reservation)
                .then(resp => {
                    this.setState({
                        chef: resp.chef,
                        reservation: resp.reservation,
                        loading: false
                    })
                })
        })
    }

    removeChef(chef, reservation) {
        this.setState({ loading: true }, () => {
            this.props.removeFromReservation(chef, reservation)
                .then(resp => {
                    this.setState({
                        chef: resp.chef,
                        reservation: resp.reservation,
                        loading: false
                    })
                })
        })
    }

    renderEmptyChefs() {
        console.log("Empty")
        let emptyChefs = [];
        let currentChefRegistered = null;
        let emptySlots;
        if (!this.state.reservation.confirmedChefs) {
            emptySlots = this.state.reservation.requiredChefs;
        } else {
            emptySlots = this.state.reservation.requiredChefs - this.state.reservation.confirmedChefs.length
            
            R.findIndex(R.propEq('chef', this.state.chef.id))(this.state.reservation.confirmedChefs) === 0 ? currentChefRegistered = true : currentChefRegistered = false;
        }

        if (currentChefRegistered && currentChefRegistered === true) {
            for (var i = 0; i < emptySlots; i++) {
                emptyChefs.push(
                    <Row key={i} start="xs">
                        <EmptySlot isCurrentChefRegistered={true} signupChef={() => this.signupChef(this.state.chef, this.state.reservation)} />
                    </Row>
                )
            }
            return emptyChefs;
        } else {
            for (var j = 0; j < emptySlots; j++) {
                emptyChefs.push(
                    <Row key={j} start="xs">
                        <EmptySlot isCurrentChefRegistered={false} signupChef={() => this.signupChef(this.state.chef, this.state.reservation)} />
                    </Row>
                )
            }
            return emptyChefs;
        }
    }

    renderConfirmedChefs() {
        console.log(this.state.reservation)
        if (!this.state.reservation.confirmedChefs) {
            return;
        } else {
            return this.state.reservation.confirmedChefs.map((chef, index) => {
                return (
                    <Row key={index} start="xs">
                        <FilledSlot key={index} chef={chef} removeChef={() => this.removeChef(this.state.chef, this.state.reservation)} />
                    </Row>
                )
            })
        }
    }

    renderContent() {
        if (!this.state.fetching) {
            const { name, zipCode, reservationSize, dietaryRestrictions } = this.state.reservation;
            const requiredDietaryRestrictions = _.filter(dietaryRestrictions, { 'required' : true });
            return (<>
                <Row>
                    <Col xs={12} md={12}>
                        <h3 className="reservation-name">{name}</h3>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row center="xs">
                            <h4 className="reservation-column-header">Details</h4>
                        </Row>
                        <div className="details-container">
                            <Row>
                                <p>Zip Code: {zipCode}</p>
                            </Row>
                            <Row>
                                <p>Size of Party: {reservationSize}</p>
                            </Row>
                            <Row>
                                <p>Dietary Restrictions:</p>
                            </Row>
                            <Row>
                                {requiredDietaryRestrictions.map((restriction, index) => <div key={index} className="dietary-restriction-tag-container">
                                <p className="dietary-restriction-tag">{restriction.type.charAt(0).toUpperCase() + restriction.type.slice(1)}</p>
                                </div>)}
                            </Row>
                        </div>
                    </Col>
                    <Col xs={12} md={6}>
                        <Row center="xs">
                            <h4 className="reservation-column-header">Current Chefs</h4>
                        </Row>
                        {this.renderConfirmedChefs()}
                        {this.renderEmptyChefs()}
                        {/* {this.renderCurrentChefs()} */}
                    </Col>
                </Row>

            </>)
        } 
    }

    render() {
       
        return (<div className="reservation-container">
            <Grid fluid>
                {this.renderContent()}
                {/* Loading */}
                <Row center="xs" style={{ visibility: this.state.loading ? 'visible' : 'hidden' }}>
                    <div className="loading-icon" style={{ 
                        position: 'fixed',
                        top: `30%`,
                        textAlign: 'center',
                        zIndex: 10,
                    }}>
                        <ReactLoading type="bubbles" color="black" height={100} width={150} />
                    </div>
                </Row>
            </Grid>
        </div>)
    }
}

function mapStateToProps(state) {
    return {
        chef: state.auth
    }
}

export default connect(mapStateToProps, actions)(Reservation);