import React, { Component } from 'react';
import { Grid, Row } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import Amplify, { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';

import awsmobile from '../../../aws-exports';
import * as actions from '../../../actions';

// Stylesheets
import './dashboard.css';

// Components
import Navigation from '../navigation/navigation';

Amplify.configure(awsmobile);

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true
        }
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            return Auth.currentAuthenticatedUser()
                .then(user => {
                    if (user.signInUserSession.idToken.payload['cognito:groups'][0] !== 'admins') {
                        alert('Incorrect account type.');
                        return Auth.signOut()
                            .then(() => {
                                return this.setState({ loading: false }, () => this.props.history.push('/admin'))
                            })
                    } else {
                        return this.props.hydrateUser(user.attributes.sub, 'admin')
                            .then(resp => {  
                                return this.setState({ loading: false }, () => this.props.history.push('/admin/dashboard'));
                            })
                    }
                })
            .catch(error => {
                return this.props.history.push('/admin')
            })
        })
    }

    renderContent() {
        if (!this.state.loading) {
            return (<>
                <Row around="xs">
                    <h3>Placemat Admin Console</h3>
                </Row>
                <Row center="xs">
                <iframe title="calendar" src="https://calendar.google.com/calendar/embed?src=ro3tsg0vjooqkj0om00dprqn7g%40group.calendar.google.com&ctz=America%2FChicago" style={{ border: 0, margin: '0 auto' }} width="800" height="600" frameBorder="0" scrolling="no"></iframe>
                </Row>
            </>)
        }
    }

    render() {
        return (<div>
            <Navigation />
            <Grid fluid>
                {this.renderContent()}
            </Grid>
        </div>)
    }
}

function mapStateToProps(state) {
    return {
        state
    }
}
export default withRouter(connect(mapStateToProps, actions)(Dashboard))