const brunch = [
    // {
    //     course: 'starters',
    //     name: 'Sweet Potato Biscuit Monkey Bread',
    //     imageUrl: 'https://picsum.photos/200'
    // },
    // {
    //     course: 'starters',
    //     name: 'Cassava Flour Street Tacos',
    //     imageUrl: 'https://picsum.photos/200'
    // },
    // {
    //     course: 'starters',
    //     name: 'Seasonal Sweet + Savory Sourdough Toasts',
    //     imageUrl: 'https://picsum.photos/200'
    // },
    // {
    //     course: 'starters',
    //     name: 'Grapefruit + Blood Orange + Mango + Coconut Milk + Turmeric Smoothies',
    //     imageUrl: 'https://picsum.photos/200'
    // },
    // {
    //     course: 'starters',
    //     name: 'GF Seasonal Galette',
    //     imageUrl: 'https://picsum.photos/200'
    // },
    // {
    //     course: 'starters',
    //     name: 'Blueblueberry GF Muffins + Honey Butter',
    //     imageUrl: 'https://picsum.photos/200'
    // },
    // {
    //     course: 'starters',
    //     name: 'Local Sourdough French Toast Sticks',
    //     imageUrl: 'https://picsum.photos/200'
    // },
    {
        course: 'entrees',
        name: 'Chorizo + Local Sourdough Strata',
        imageUrl: 'https://img1.southernliving.timeinc.net/sites/default/files/styles/medium_2x/public/image/2018/01/main/caramelized_onion_spinach_pork_strata_2548401_slowc_131.jpg?itok=-WePBLuY'
    },
    {
        course: 'entrees',
        name: 'Cassava Breakfast Tacos',
        imageUrl: 'https://images.pexels.com/photos/1829113/pexels-photo-1829113.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'entrees',
        name: 'Sweet Potato Biscuit Sammies + Pulled Pork + Goat Cheese',
        imageUrl: 'https://images.pexels.com/photos/3426583/pexels-photo-3426583.jpeg?auto=compress&cs=tinysrgb&dpr=3&h=750&w=1260'
    },
    {
        course: 'entrees',
        name: 'Sweet Potato Belgian Waffle + Fried Egg + Avocado',
        imageUrl: 'https://scontent.fbna1-2.fna.fbcdn.net/v/t31.0-8/21056106_116918342303444_459246619728163902_o.jpg?_nc_cat=100&_nc_ohc=9ZoUOMc-_aMAQldGB5QHW6sEd6YWG0fYl2UBQA2ad7pZl3wIPEQP5psFg&_nc_ht=scontent.fbna1-2.fna&oh=d239551610ad18675316effe6e7c4575&oe=5E6CFFF7'
    },
    {
        course: 'entrees',
        name: 'Farmers Market Omelettes',
        imageUrl: 'https://scontent.fbna1-2.fna.fbcdn.net/v/t1.0-9/p960x960/45132897_257832278212049_3631193573715607552_o.jpg?_nc_cat=100&_nc_ohc=8c0e5JbA9loAQmjSw8Xv35eOralutLaUfatAbBt9kViiohZbl0Tvyvwdw&_nc_ht=scontent.fbna1-2.fna&oh=7a6a8d58af8a6b6cb2130d648f4f364d&oe=5E9A1AAF'
    },
    {
        course: 'entrees',
        name: 'Shakshouka + Poached Eggs + Local Sourdough',
        imageUrl: 'https://scontent.fbna1-2.fna.fbcdn.net/v/t1.0-9/42487021_246904332638177_8360404547495002112_o.jpg?_nc_cat=106&_nc_ohc=dwCDLzGBgY4AQm9hFT7DXqCDYZK9dObvyQLB7-6JKSmKM8j4OpTz7rIJg&_nc_ht=scontent.fbna1-2.fna&oh=0972037bca2b1219884782209c14ca0e&oe=5EA8C64F'
    },
    {
        course: 'entrees',
        name: 'Fried Egg + Kale + Turmeric + Parsley Oil',
        imageUrl: 'https://assets.epicurious.com/photos/5a05db158f079c1ff0b6a184/6:4/w_620%2Ch_413/fried-brown-rice-with-kale-and-turmeric-recipe-BA-111017.jpg'
    },
    {
        course: 'entrees',
        name: 'GF Cheesy Quiché + Seasonal Vegetables',
        imageUrl: 'http://www.thecomfortofcooking.com/wp-content/uploads/2014/04/CheesyHamCheddarScallionQuiche-5.jpg'
    },
    {
        course: 'sides',
        name: 'Seasonal Melon Salad + Honey + Chili Flake',
        imageUrl: 'https://images.pexels.com/photos/3426622/pexels-photo-3426622.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'Sweet Potato Biscuit Monkey Bread',
        imageUrl: 'https://images.pexels.com/photos/3193285/pexels-photo-3193285.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'Seasonal Sweet + Savory Sourdough Toasts',
        imageUrl: 'https://scontent.fbna1-1.fna.fbcdn.net/v/t1.0-9/40087862_238461196815824_7894239903972589568_o.jpg?_nc_cat=108&_nc_ohc=hnzn3EQW0AcAQnEYG-LnY-KV2J6bUyp38TyewY3DSJ-FWlpuTqZHJq8ow&_nc_ht=scontent.fbna1-1.fna&oh=9624107925777bbca36891c5de44c995&oe=5E6887FD'
    },
    {
        course: 'sides',
        name: 'GF Lemon Poppy Seed Pancakes',
        imageUrl: 'https://images.pexels.com/photos/3426569/pexels-photo-3426569.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'GF Cornbread Waffles',
        imageUrl: 'https://images.pexels.com/photos/1829119/pexels-photo-1829119.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'Granola Board + Seasonal Fruit',
        imageUrl: 'https://images.pexels.com/photos/3471229/pexels-photo-3471229.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'Sweet Potato + Brussel Hash',
        imageUrl: 'https://scontent.fbna1-1.fna.fbcdn.net/v/t1.0-9/41725285_243577159637561_4481319969853800448_o.jpg?_nc_cat=104&_nc_ohc=yjNSGaCOGRkAQkSs2XZVahw0KO_hVmeiA3kwnY8xodRR5484oDKBjzsOQ&_nc_ht=scontent.fbna1-1.fna&oh=b99d4d31d7c0a47cbfa8cbe1f0366e88&oe=5EB2B881'
    },
    {
        course: 'sides',
        name: 'Acorn Squash Donuts + Pumpkin Seed + Honey + Chili Flake',
        imageUrl: 'https://images.pexels.com/photos/3193303/pexels-photo-3193303.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'GF Seasonal Galette',
        imageUrl: 'https://images.pexels.com/photos/3426584/pexels-photo-3426584.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'Local Sourdough French Toast Sticks',
        imageUrl: 'https://www.halfbakedharvest.com/wp-content/uploads/2014/03/Coffee-Caramelized-Croissant-French-Toast-Sticks.-7.jpg'
    },
    {
        course: 'sides',
        name: 'Porter Road Pasture Raised Sausage + Bacon',
        imageUrl: 'https://cdn.shopify.com/s/files/1/2373/1239/products/product-pork-bacon_2x_f401b456-3255-4fc4-82d4-875c5f95db8a_1024x1024.jpg?v=1509561893'
    }
    
]

export default brunch;
// Sides








