import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
// don't forget to import 'compose' from redux when in development
import { 
    // compose,
    createStore, applyMiddleware } from 'redux';
import reduxThunk from 'redux-thunk';

import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import App from './app/App';
import reducers from './reducers';
import * as serviceWorker from './serviceWorker';

import "./main.css";

const store =
createStore(
  reducers,
  {},
  // compose(
    applyMiddleware(reduxThunk)
    // , 
    // window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
  // )
)

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.querySelector('#root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
