import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import * as actions from '../../../actions';

// Components
import PrimaryButton from '../../components/PrimaryButton';
import SuccessModal from './SuccessModal';

// Styles
import './application.css';

// ======================== //
// ===== Confirmation ===== //
// ======================== //
class ConfirmApplication extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSuccessModal: false
        }
        this.toggleSuccessModal = this.toggleSuccessModal.bind(this);
    }

    toggleSuccessModal() {
        this.setState({ showSuccessModal: !this.state.showSuccessModal })
      }

    handleStepChange(step) { 
        let confirmStepApplication = {
            // NOT PART OF THIS STEP
            firstName: this.props.application.firstName,
            lastName: this.props.application.lastName,
            email: this.props.application.email,
            city: this.props.application.city,
            state: this.props.application.state,
            zip: this.props.application.zip,
            travelAvailability: this.props.application.travelAvailability,
            additionalInfo: this.props.application.additionalInfo
        }
        this.props.toggleStep(step, confirmStepApplication)
    }

    submitApplication() {
        let confirmStepApplication = {
            // NOT PART OF THIS STEP
            firstName: this.props.application.firstName,
            lastName: this.props.application.lastName,
            email: this.props.application.email,
            city: this.props.application.city,
            state: this.props.application.state,
            zip: this.props.application.zip,
            travelAvailability: this.props.application.travelAvailability,
            additionalInfo: this.props.application.additionalInfo || "N/A"
        }

        let chefEmail = {
            recipient: this.props.application.email,
            sender: 'applications@eatplacemat.com',
            subject: 'We Got Your Application!',
            html: `<div>
                <h3>Thanks for your interest in Placemat!</h3>
                <p>We've received your application and will get back to you shortly.</p>
            </div>`
        }

        let placematEmail = {
            recipient: 'reservations@eatplacemat.com',
            sender: 'applications@eatplacemat.com',
            subject: 'New Application Received',
            html: `<div>    
                <h3>New Chef Application!</h3>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
                <p></p>
            </div>`
        }
        this.props.createChefApplication(confirmStepApplication)
            .then(resp => {
                if (resp.code === 200) {
                    this.props.sendEmail(chefEmail)
                    this.props.sendEmail(placematEmail)
                    this.toggleSuccessModal()
                }
            })
    }

    goHome() {
        this.toggleSuccessModal()
        this.props.history.push('/')
    }

    render() {
        const { firstName, lastName, email, city, state, zip, travelAvailability } = this.props.application
        let additionalInfo = '';
        if (this.props.application.additionalInfo) { 
            additionalInfo = this.props.application.additionalInfo 
        } else { additionalInfo = 'N/A' }

        return (<>
        <Grid fluid>
            <Row around="xs">
                <p>Confirm!</p>
            </Row>
            {/* Name */}
            <Row>
                <Col xs={12} md={3}>
                    <p className="confirmation-page-label">Name:</p>
                </Col>
                <Col xs={12} md={9}>
                    <p className="confirmation-page-value">{firstName} {lastName}</p>
                </Col>
            </Row>

            {/* Email */}
            <Row>
                <Col xs={12} md={3}>
                    <p className="confirmation-page-label">Email:</p>
                </Col>
                <Col xs={12} md={9}>
                    <p className="confirmation-page-value">{email}</p>
                </Col>
            </Row>

            {/* Location */}
            <Row>
                <Col xs={12} md={3}>
                    <p className="confirmation-page-label">Location:</p>
                </Col>
                <Col xs={12} md={9}>
                    <p className="confirmation-page-value">{city}, {state} {zip}</p>
                </Col>
            </Row>

            {/* Travel Availability */}
            <Row>
                <Col xs={12} md={3}>
                    <p className="confirmation-page-label">Travel Availability:</p>
                </Col>
                <Col xs={12} md={9}>
                    <p className="confirmation-page-value">{travelAvailability}</p>
                </Col>
            </Row>

            {/* Additional Info */}
            <Row>
                <Col xs={12} md={3}>
                    <p className="confirmation-page-label">Additional Info:</p>
                </Col>
                <Col xs={12} md={9}>
                    <p className="confirmation-page-value">{additionalInfo}</p>
                </Col>
            </Row>

            <Row around="xs">
                <Col xs={12} md={6}>
                    <PrimaryButton onClick={() => this.handleStepChange('three')} label="Back" />
                </Col>
                <Col xs={12} md={6}>
                    <PrimaryButton onClick={() => this.submitApplication()} label="Submit!" />
                </Col>
            </Row>
        </Grid>
        <SuccessModal
            isOpen={this.state.showSuccessModal}
            onClose={() => this.goHome()}
        />
        </>)
    }
}

function mapStateToProps(state) {
    return {
        state
    }
}

export default withRouter(connect(mapStateToProps, actions)(ConfirmApplication));