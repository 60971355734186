import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import moment from 'moment';
import ReactLoading from 'react-loading';

import * as actions from '../../../actions';

// Components
import PrimaryButton from '../../components/PrimaryButton';
import SuccessModal from './SuccessModal';
import Confirm from './ConfirmationForm/Confirm';
import Edit from './ConfirmationForm/Edit';

// Styles
import './reservation.css';

var _ = require('lodash');

// ======================== //
// ===== Confirmation ===== //
// ======================== //
class ConfirmReservation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showSuccessModal: false,
            displayEditForm: false,
            reservation: this.props.reservation,
            dbReservation: null,
            loading: false
        }
        this.toggleSuccessModal = this.toggleSuccessModal.bind(this);
        this.renderDietaryRestrictions = this.renderDietaryRestrictions.bind(this);
        this.toggleEditForm = this.toggleEditForm.bind(this);
        this.submitReservation = this.submitReservation.bind(this);
        this.updateReservation = this.updateReservation.bind(this);
    }

    componentDidMount() {
        // this.props.getReservations()
        //     .then(resp => {
        //         let unavailableDates = _.filter(resp.reservations, { 'isConfirmed': true })
        //         this.setState({ unavailableDates: unavailableDates })
        //     })
    }

    toggleSuccessModal() {
        this.setState({ showSuccessModal: !this.state.showSuccessModal })
    }

    toggleEditForm() {
        this.setState({ displayEditForm: !this.state.displayEditForm })
    }

    handleStepChange(step) { 
        let confirmStepReservation = {
            // NOT PART OF THIS STEP
            name: this.props.reservation.name,
            email: this.props.reservation.email,
            phone: this.props.reservation.phone,
            reservationDate: this.props.reservation.reservationDate,
            zipCode: this.props.reservation.zipCode,
            reservationSize: this.props.reservation.reservationSize,
            partySize: this.props.reservation.partySize,
            cohostName: this.props.reservation.cohostName,
            cohostEmail: this.props.reservation.cohostEmail,
            dietaryRestrictions: this.props.reservation.dietaryRestrictions,
            referral: this.props.reservation.referral,
            giftCode: this.props.reservation.giftCode
        }
        this.props.toggleStep(step, confirmStepReservation)
    }

    updateReservation(updatedReservation) {
        this.setState({ 
            reservation: updatedReservation
        }, () => {
            this.setState({ displayEditForm: false })
        })
    }

    submitReservation() {
        this.setState({ loading: true }, () => {
            let stringifyDietaryRestrictions = [];
            if (this.state.reservation.dietaryRestrictions[0].none) stringifyDietaryRestrictions.push('none');
            if (this.state.reservation.dietaryRestrictions[1].nut) stringifyDietaryRestrictions.push('nut');
            if (this.state.reservation.dietaryRestrictions[2].vegan) stringifyDietaryRestrictions.push('vegan');
            if (this.state.reservation.dietaryRestrictions[3].keto) stringifyDietaryRestrictions.push('keto');
            if (this.state.reservation.dietaryRestrictions[4].shellfish) stringifyDietaryRestrictions.push('shellfish');
            if (this.state.reservation.dietaryRestrictions[5].peanut) stringifyDietaryRestrictions.push('peanut');
            if (this.state.reservation.dietaryRestrictions[6].vegetarian) stringifyDietaryRestrictions.push('vegetarian');
            if (this.state.reservation.dietaryRestrictions[7].gluten) stringifyDietaryRestrictions.push('gluten');
            if (this.state.reservation.dietaryRestrictions[8].paleo) stringifyDietaryRestrictions.push('paleo');
            if (this.state.reservation.dietaryRestrictions[9].soy) stringifyDietaryRestrictions.push('soy');
            if (this.state.reservation.dietaryRestrictions[10].whole30) stringifyDietaryRestrictions.push('whole30');
            if (this.state.reservation.dietaryRestrictions[11].grain) stringifyDietaryRestrictions.push('grain');
            if (this.state.reservation.dietaryRestrictions[12].dairy) stringifyDietaryRestrictions.push('dairy');
    
    
            console.log("STRINGIFY", stringifyDietaryRestrictions);
    
            let confirmStepReservation = {
                // NOT PART OF THIS STEP
                name: this.state.reservation.name,
                email: this.state.reservation.email,
                phone: this.state.reservation.phone,
                reservationDate: this.state.reservation.reservationDate,
                zipCode: this.state.reservation.zipCode,
                reservationSize: this.state.reservation.reservationSize,
                partySize: this.state.reservation.partySize,
                cohostName: this.state.reservation.cohostName || "N/A",
                cohostEmail: this.state.reservation.cohostEmail || "N/A",
                dietaryRestrictions: this.state.reservation.dietaryRestrictions,
                referral: this.state.reservation.referral || "N/A",
                giftCode: this.state.reservation.giftCode || "N/A",
                isConfirmed: false
            }
            console.log(confirmStepReservation);
    
            this.props.createReservation(confirmStepReservation)
                .then(resp => {
                    console.log(resp);
                    // let devUrl=`http://localhost:3000/hosts/reservation/confirm/`
                    let prodUrl=`https://reservations.eatplacemat.com/hosts/reservation/confirm/`
                    if (resp.code === 200) {
                        let formattedDate = moment(this.state.reservation.reservationDate).utc().format("MMMM D, YYYY");
                        // PARTY SIZE REMOVED FOR NOW
                        // for ${this.state.reservation.partySize}
                        let hostEmail = {
                            recipient: this.state.reservation.email,
                            sender: 'reservations@eatplacemat.com',
                            subject: `Confirm your Placemat reservation for ${formattedDate}`,
                            html: `<div>
                                <p>Hi ${this.state.reservation.name},</p>
    
                                <p>Thank you for planning a reservation with Placemat on ${formattedDate}!</p>
    
                                <p>In order to confirm this reservation we ask that you <a style="color:E4AF0A;" href=${prodUrl}${resp.reservation.id}>choose your menu + submit deposit</a> in the amount of $50. This will secure your reservation date and is applied toward your final invoice.</p> 
    
                                <p>We understand plans change; your deposit is refundable up until 5 days prior to your reservation date. If it is less than 5 days prior, the deposit is non-refundable.</p>
    
                                <p>Our meals start at $60 per person, which include five family-style courses sourced organically + locally.</p>
    
                                <p>We are looking forward to you + your guests to experience Placemat and what we are so passionate about -- feel-good food and wholesome hospitality.</p>
    
                                <p>See you soon,</p>
                                <p>The Placemat Team</p>
    
                                <a href="https://www.eatplacemat.com">eatplacemat.com</a>
                                
                            </div>`
                        }

                        // PARTY SIZE REMOVED FOR NOW
                        // for ${this.state.reservation.partySize}
                        let cohostEmail = {
                            recipient: this.state.reservation.cohostEmail,
                            sender: 'reservations@eatplacemat.com',
                            subject: `You’ve been added as a cohost for a Placemat Reservation on ${formattedDate}`,
                            html: `<div>
                                <p>Hello,</p>
                                <p>You were added as a cohost for a reservation with Placemat on ${formattedDate}!</p>
                                <p>In order to confirm this reservation we ask that you <a style="color:E4AF0A;" href=${prodUrl}${resp.reservation.id}>choose your menu + submit deposit</a> in the amount of $50. This will secure your reservation date and is applied toward your final invoice.</p> 
                                
                                <p>We understand plans change; your deposit is refundable up until 5 days prior to your reservation date. If it is less than 5 days prior, the deposit is non-refundable.</p>
    
                                <p>Our meals start at $60 per person, which include five family-style courses sourced organically + locally.</p>
    
                                <p>We are looking forward to you + your guests to experience Placemat and what we are so passionate about -- feel-good food and wholesome hospitality.</p>
    
                                <p>See you soon,</p>
                                <p>The Placemat Team</p>
    
                                <a href="https://www.eatplacemat.com">eatplacemat.com</a>
                            </div>`
                        }
                        let placematEmail = {
                            recipient: 'reservations@eatplacemat.com',
                            // recipient: 'jbavier123@gmail.com',
                            sender: 'reservations@eatplacemat.com',
                            subject: 'New Reservation Received (UNCONFIRMED)',
                            html: `<div>    
                            <h3>New Reservation Inquiry:</h3>
                            <p>Date: ${formattedDate}</p>
                            <p>Zip Code: ${this.state.reservation.zipCode}</p>
                            <p>Party Size: ${this.state.reservation.partySize}</p>
                            <p>Name: ${this.state.reservation.name}</p>
                            <p>Email: ${this.state.reservation.email}</p>
                            <p>Phone: ${this.state.reservation.phone}</p>
                            <p>Cohost Name: ${this.state.reservation.cohostName || "N/A"}</p>
                            <p>Cohost Email: ${this.state.reservation.cohostEmail || "N/A"}</p>
                            <p>Referral: ${this.state.reservation.referral}</p>
                            <p>Gift Code: GIFT-${this.state.reservation.giftCode}</p>
                            <a href="https://reservations.eatplacemat.com/admin">View Reservation in Admin Console</a>
                            </div>`
                        }
                        if (this.state.reservation.cohostEmail !== '') { this.props.sendEmail(cohostEmail); }
                        this.props.sendEmail(hostEmail);
                        this.props.sendEmail(placematEmail);
                        
                        
                        var datetime = moment(this.state.reservation.reservationDate).utc();
                        console.log(datetime);
                        let timezone = new Date().getTimezoneOffset();
                        let utcString = timezone / 60;
                                            
                            if (utcString < 10) {
                                utcString = "0" + utcString.toString()
                            }
                            utcString = "UTC-" + utcString + ":00"
                        var event = {
                            "summary": "UNCONFIRMED: " + this.state.reservation.name,
                            "description": 'Unconfirmed Dinner.',
                            "start": {
                                "dateTime": datetime,
                                "timeZone": utcString,
                            },
                            "end": {
                                "dateTime": datetime,
                                "timeZone": utcString
                            }
                        }
                        this.props.addToCalendar(event, "ro3tsg0vjooqkj0om00dprqn7g@group.calendar.google.com")
                            .then(response => {
                                console.log(response);
                                let reservationWithGoogleEventId = {
                                    ...resp.reservation,
                                    googleEventId: response.id
                                }

                                this.props.updateReservation(reservationWithGoogleEventId)
                                    .then(respo => {
                                        this.setState({
                                            dbReservation: resp.reservation,
                                            loading: false
                                        }, () => {
                                            this.toggleSuccessModal()
                                        })
                                    })
                            })
                        
                        }
                })
        })
    }

    goHome() {
        console.log("Home")
        this.toggleSuccessModal()
        window.location.href = "https://www.eatplacemat.com";



    }

    renderDietaryRestrictions(dietaryRestrictions) {
        console.log(dietaryRestrictions);
        let dietaryRestrictionsArray = [];
        if (dietaryRestrictions[0].required) { dietaryRestrictionsArray.push('None') }
        if (dietaryRestrictions[1].required) { dietaryRestrictionsArray.push('Nut, ') }
        if (dietaryRestrictions[2].required) { dietaryRestrictionsArray.push('Vegan, ') }
        if (dietaryRestrictions[3].required) { dietaryRestrictionsArray.push('Keto, ') }
        if (dietaryRestrictions[4].required) { dietaryRestrictionsArray.push('Shellfish, ') }
        if (dietaryRestrictions[5].required) { dietaryRestrictionsArray.push('Peanut, ') }
        if (dietaryRestrictions[6].required) { dietaryRestrictionsArray.push('Vegetarian, ') }
        if (dietaryRestrictions[7].required) { dietaryRestrictionsArray.push('Gluten, ') }
        if (dietaryRestrictions[8].required) { dietaryRestrictionsArray.push('Paleo, ') }
        if (dietaryRestrictions[9].required) { dietaryRestrictionsArray.push('Soy, ') }
        if (dietaryRestrictions[10].required) { dietaryRestrictionsArray.push('Whole30, ') }
        if (dietaryRestrictions[11].required) { dietaryRestrictionsArray.push('Grain, ') }
        if (dietaryRestrictions[12].required) { dietaryRestrictionsArray.push('Dairy, ') }
        if (dietaryRestrictions[13].required) { dietaryRestrictionsArray.push(dietaryRestrictions[13].type)}
        dietaryRestrictionsArray[dietaryRestrictionsArray.length-1] = _.split(dietaryRestrictionsArray[dietaryRestrictionsArray.length-1], ',', 1);
        return dietaryRestrictionsArray.map((string, index) => { return <span key={index}>{string}</span> })
    }

    renderContent() {
        if (this.state.displayEditForm) {
            return <Edit 
                reservation={this.state.reservation} 
                toggleEditForm={this.toggleEditForm} 
                updateReservation={this.updateReservation}
            />
        } else {
            return (<>
            <Row around="xs">
                <p>Awesome, {this.state.reservation.name}!</p>
            </Row>
            <Confirm 
                reservation={this.state.reservation} 
                renderDietaryRestrictions={this.renderDietaryRestrictions} 
                toggleEditForm={this.toggleEditForm} 
                submitReservation={this.submitReservation} 
                loading={this.state.loading}
            />
            </>)
        }
    }
    render() {
        return (<>
        <Grid fluid>
            {/* Confirm Info */}
            {this.renderContent()}
            
            
        </Grid>
        <SuccessModal
            isOpen={this.state.showSuccessModal}
            onClose={() => this.goHome()}
            reservation={this.state.dbReservation}
        />
        {/* Loading */}
        <Row center="xs" style={{ visibility: this.state.loading ? 'visible' : 'hidden' }}>
            <div className="loading-icon" style={{ 
                position: 'fixed',
                top: `30%`,
                textAlign: 'center',
                zIndex: 10,
            }}>
                <ReactLoading type="bubbles" color="black" height={100} width={150} />
            </div>
        </Row>
        </>)
    }
}

function mapStateToProps(state) {
    return {
        state
    }
}

export default withRouter(connect(mapStateToProps, actions)(ConfirmReservation));