import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import InputField from '../../components/InputField';
import PrimaryButton from '../../components/PrimaryButton';

class Three extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delivery: this.props.delivery,
            buttonDisabled: true
        }

        this.handleButtonDisabled = this.handleButtonDisabled.bind(this);
        this.handleRestrictionChange = this.handleRestrictionChange.bind(this);
        this.handleOtherChange = this.handleOtherChange.bind(this);
    }

    componentDidMount() {
        this.setState({ 
            delivery: this.props.delivery
        }, () => this.handleButtonDisabled())
    }

    handleButtonDisabled() {
        if (!this.state.delivery.dietaryRestrictions) {
            this.setState({ buttonDisabled: true })
        } else {
            this.setState({ buttonDisabled: false })
        }
    }

    handleRestrictionChange(event, index) {
        let updatedRestrictions = this.state.delivery.dietaryRestrictions;
        updatedRestrictions[index].required = event.target.checked
        this.setState({ 
            delivery: { 
                ...this.state.delivery, 
                dietaryRestrictions: updatedRestrictions 
            }
        }, () => this.handleButtonDisabled())
    }

    handleOtherChange(event) {
        let updatedRestrictions = this.state.delivery.dietaryRestrictions
        if (event.target.value !== "") {
            updatedRestrictions[13].type = event.target.value
            updatedRestrictions[13].required = true
            this.setState({
                delivery: {
                    ...this.state.delivery,
                    dietaryRestrictions: updatedRestrictions
                }
            }, () => this.handleButtonDisabled())
        } else {
            updatedRestrictions[13].type = ""
            updatedRestrictions[13].required = false
            this.setState({
                delivery: {
                    ...this.state.delivery,
                    dietaryRestrictions: updatedRestrictions
                }
            }, () => this.handleButtonDisabled())
        }
    }

    render() {
        return (<Grid fluid>
            <Row>
            {this.state.delivery.dietaryRestrictions.map((restriction, index) => {
                if (restriction.type === 'nut' || restriction.type === 'vegan' || restriction.type === 'keto' || restriction.type === 'shellfish' || restriction.type === 'peanut' || restriction.type === 'vegetarian' || restriction.type === 'gluten' || restriction.type === 'paleo' || restriction.type === 'soy' || restriction.type === 'whole30' || restriction.type === 'grain' || restriction.type === 'dairy' || restriction.type === 'none') {
                    return <Col key={index} xs={12} md={4}><div className="dietary-preference-checkbox-wrapper">
                        <Row middle="xs">
                            <input className="dietary-preference-checkbox" type="checkbox" onChange={(e) => this.handleRestrictionChange(e, index)} checked={this.state.delivery.dietaryRestrictions[index].required} />
                            <p>{restriction.type}</p>
                        </Row>
                    </div></Col>
                } else {
                    return (
                        <Col xs={12} md={4} key={index}>
                            <div className="dietary-preference-checkbox-wrapper">
                                <Row middle="xs">
                                    <input className="dietary-preference-input" type="text" value={this.state.delivery.dietaryRestrictions[13].type} placeholder="Other" onChange={this.handleOtherChange.bind(this)} />
                                </Row>
                            </div>
                        </Col>
                    )
                }
            })}

            </Row>
            <Row center="xs">
                <Col xs={12} md={2}>
                    <PrimaryButton onClick={() => this.props.switchStep('two', this.state.delivery)} label="Back" />
                </Col>
                <Col xs={12} md={2}>
                    <PrimaryButton onClick={() => this.props.switchStep('deposit', this.state.delivery)} label="Next" disabled={this.state.buttonDisabled} />
                </Col>
            </Row>
        </Grid>)
    }
}

export default Three;