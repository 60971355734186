import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import moment from 'moment';

// Components
import PrimaryButton from '../../../components/PrimaryButton';

class ConfirmReservation extends Component {
    constructor(props) {
        super(props);
        this.state = {

        }
    }

    render() {
        const { name, email, phone, reservationDate, zipCode, partySize, cohostEmail, cohostName, dietaryRestrictions, referral, giftCode } = this.props.reservation;
        let formattedDate = moment(reservationDate).utc().format("MMMM D, YYYY");
        return (<>
            {/* Name */}
            {/* PARTY SIZE REMOVED FOR NOW 
                for a party of {partySize}. */}
            <Row>
                <Col xs={12} md={10} mdOffset={1}>
                    <p>Reservation for {name} on {formattedDate} for a party of {partySize}.</p>
                </Col>
            </Row>
            {/* Email */}
            <Row>
                <Col xs={12} md={10} mdOffset={1}>
                    <p>Email: {email}</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={10} mdOffset={1}>
                    <p>Phone: {phone}</p>
                </Col>
            </Row>
            {/* Zip */}
            <Row>
                <Col xs={12} md={10} mdOffset={1}>
                    <p>Zip Code: {zipCode}</p>
                </Col>
            </Row>
            {/* Cohost Email */}
            <Row>
                <Col xs={12} md={10} mdOffset={1}>
                    <p>{cohostName ? 'Cohost Name: ' + cohostName : 'No cohost.'}</p>
                </Col>
            </Row>
            {/* Dietary Restrictions */}
            <Row>
                <Col xs={12} md={10} mdOffset={1}>
                    <p>Dietary Restrictions: {this.props.renderDietaryRestrictions(dietaryRestrictions)}</p> 
                </Col>
            </Row>
            {/* Referral */}
            <Row>
                <Col xs={12} md={10} mdOffset={1}>
                    <p>{referral ? 'Referred to Placemat by ' + referral + '.' : ''}</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={10} mdOffset={1}>
                    <p>{giftCode ? 'Gift Code: GIFT-' + giftCode + '.' : ''}</p>
                </Col>
            </Row>
            {/* End Confirm Info */}
            <Row around="xs">
                <Col md={3} />
                <Col xs={12} md={3}>
                    <PrimaryButton onClick={() => this.props.toggleEditForm()} label="Edit" />
                </Col>
                <Col xs={12} md={3}>
                    <PrimaryButton onClick={() => this.props.submitReservation()} label="Next" disabled={this.props.loading} />
                </Col>
                <Col md={3} />
            </Row>
        </>)
    }
}

export default ConfirmReservation;