import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import PrimaryButton from '../../components/PrimaryButton';
import InputField from '../../components/InputField';
import SelectField from '../../components/SelectField';

// reservationSize
class ReservationTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reservationSize: this.props.reservation.reservationSize || '',
            partySize: this.props.reservation.partySize || ''
        }
        this.handleReservationSizeChange = this.handleReservationSizeChange.bind(this);
    }

    componentDidMount() {
        this.handleButtonEnable();
    }

    handleReservationSizeChange(event) { 
        if (event.value < 8) {
            this.setState({
                displayErrors: true,
                errorMessage: "Minimum reservation is 8!",
                reservationSize: event.value,
                isButtonDisabled: true
            })
        } else {
            let reservationSize;
            if (event.value === "8-14") {
                reservationSize = 8
            } else if (event.value === "15-30") {
                reservationSize = 15
            } else if (event.value === "31+") {
                reservationSize = 31
            }
            this.setState({ reservationSize: reservationSize, partySize: event.value }, () => this.handleButtonEnable()) 
        }
    }

    handleButtonEnable() {
        if (this.state.reservationSize !== '') {
            this.setState({ isButtonDisabled: false })
        } else {
            this.setState({ isButtonDisabled: true })
        }
    }

    handleStepChange(step) { 
        let stepTwoReservation = {
            // PART OF THIS STEP
            reservationSize: this.state.reservationSize,
            partySize: this.state.partySize,
            // NOT PART OF THIS STEP
            name: this.props.reservation.name,
            email: this.props.reservation.email,
            phone: this.props.reservation.phone,
            reservationDate: this.props.reservation.reservationDate,
            zipCode: this.props.reservation.zipCode,
            cohostName: this.props.reservation.cohostName,
            cohostEmail: this.props.reservation.cohostEmail,
            dietaryRestrictions: this.props.reservation.dietaryRestrictions,
            referral: this.props.reservation.referral,
            giftCode: this.props.reservation.giftCode
        }
        this.props.toggleStep(step, stepTwoReservation)
    }

    render() {
        var partySizes = [
            { label: "8-14", value: "8-14"},
            { label: "15-30", value: "15-30"},
            { label: "31+", value: "31+"}
        ]
        return (<Grid fluid>
            <Row center="xs">
                <Col xs={12} md={8}>
                    <p style={{ fontSize: `32px`, marginTop: 0, marginBottom: 0 }}>Step Two</p>
                </Col>
            </Row>
            <Row around="xs">
                <h3>Hi, {this.props.reservation.name}!</h3>
            </Row>
            <Row around="xs" center="xs">
                <Col xs={12} md={6}>
                    <p>Right now we have you down for {this.state.partySize} people.</p>
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={8}>
                    <p>An estimate is fine for now!</p>
                </Col>
            </Row>
            {/* FORM FIELDS */}
            <Row center="xs">
                <Col xs={12} md={5}>
                    <SelectField label={this.state.partySize} placeholder="Please select a party size" value={this.state.partySize} onChange={this.handleReservationSizeChange} type="number" showError={this.state.displayErrors} errorMessage={this.state.errorMessage} options={partySizes}/>
                </Col>
            </Row>
            {/* END FORM FIELDS */}

            <Row around="xs">
                <Col md={3} />
                <Col xs={12} md={3}>
                    <PrimaryButton onClick={() => this.handleStepChange('one')} label="Back" />
                </Col>
                <Col xs={12} md={3}>
                    <PrimaryButton 
                        onClick={() => this.handleStepChange('three')} 
                        label="Next" 
                        disabled={this.state.isButtonDisabled}
                    />
                </Col>
                <Col md={3} />
            </Row>
        </Grid>)
    }
}

export default ReservationTwo;