import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

import * as actions from '../../actions';

// Stylesheets
import './delivery.css';

// Images
import Logo from '../../images/logo.png';

// Components
import Instructions from './steps/instructions';
import One from './steps/one';
import Two from './steps/two';
import Three from './steps/three';
import Four from './steps/four';
import Deposit from './steps/deposit';
import Receipt from './steps/receipt';

class Delivery extends Component {
    constructor(props) {
        super(props)
        this.state = {
            step: 'one',
            delivery: {
                // Step One
                name: '',
                email: '',
                partySize: 0,
                // Step Two
                // Step Three
                dietaryRestrictions: [
                    { type: "none", required: false },
                    { type: "nut", required: false }, 
                    { type: "vegan", required: false }, 
                    { type: "keto", required: false }, 
                    { type: "shellfish", required: false },
                    { type: "peanut", required: false },
                    { type: "vegetarian", required: false },
                    { type: "gluten", required: false }, 
                    { type: "paleo", required: false }, 
                    { type: "soy", required: false }, 
                    { type: "whole30", required: false }, 
                    { type: "grain", required: false },
                    { type: "dairy", required: false },
                    { type: "other", required: false }
                ],
                // Step Four
                streetAddress: '',
                city: '',
                state: 'TN',
                zip: '',
                deliveryDate: moment(new Date()),
                deliveryTime: '',
                instructions: ''
            }
        }

        this.switchStep = this.switchStep.bind(this);
    }

    componentDidMount() {
    }

    switchStep(step, delivery) {
        this.setState({
            step: step,
            delivery: delivery
        } , () => { console.log(this.state.delivery) })
    }
    renderStep() {
        switch(this.state.step) {
            case('instructions'):
                return <Instructions delivery={this.state.delivery} switchStep={this.switchStep} />
            case('one'):
                return <One delivery={this.state.delivery} switchStep={this.switchStep} />
            case('two'):
                return <Two delivery={this.state.delivery} switchStep={this.switchStep} />
            case('three'):
                return <Three delivery={this.state.delivery} switchStep={this.switchStep} />
            case('four'):
                return <Four delivery={this.state.delivery} switchStep={this.switchStep} />
            case('deposit'):
                return <Deposit delivery={this.state.delivery} switchStep={this.switchStep} />
            case('receipt'):
                return <Receipt delivery={this.state.delivery} switchStep={this.switchStep} />
            default:
                return <Instructions delivery={this.state.delivery} switchStep={this.switchStep} /> 
        }
    }

    render() {
        return (<>
            <Grid fluid>
                <div className="delivery__container">
                <Row center="xs">
                    <Col xs={12} md={3}>
                        <img src={Logo} alt="logo" className="delivery__logo" />
                    </Col>
                </Row>
                {this.renderStep()}
                </div>
            </Grid>
        </>)
    }
}

function mapStateToProps(state) { return state }
export default withRouter(connect(mapStateToProps, actions)(Delivery))