import React from 'react';

import './PrimaryButtonLarge.css';

const PrimaryButton = ({ onClick, label, disabled }) => {
    if (!disabled) {
        return (
            <div className="primary-button-large-container">
                <button className="primary-button-large" onClick={() => onClick()}>
                    {label}
                </button>
            </div>
        )
    } else {
        return (
            <div className="primary-button-large-container">
                <button className="primary-button-large" disabled>
                    {label}
                </button>
            </div>
        )
    }
}

export default PrimaryButton