import React, { Component } from 'react';
import Modal from 'react-modal';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';

// Components
import PrimaryButton from '../../components/PrimaryButton';

// Styles
import "./reservation.css";

class SuccessModal extends Component {
    closeModal() {
        this.props.onClose();
    }

    pickMenu() {
        this.props.history.push({
            pathname: `hosts/reservation/confirm/${this.props.reservation.id}`
        })
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                overlayClassName="success-modal-overlay"
                className="success-modal-content"
                shouldCloseOnOverlayClick={false}
            >
                <Grid fluid>
                    <Row around="xs" center="xs">
                        <Col xs={12} md={8}>
                            <p className="success-modal-header-text">Thank you!</p>
                        </Col>
                    </Row>
                    <Row around="xs">
                        <Col xs={12} md={8}>
                            <p className="success-modal-body-text">Check your email and make your $50 deposit, which will confirm your reservation date. If you'd like to do this now hit "Pick Menu" below.</p> 
                            <p className="success-modal-body-text">Be sure to check your spam folder!</p>
                        </Col>
                    </Row>
                    <Row around="xs">
                        <Col xs={12} md={4}>
                            <PrimaryButton onClick={() => this.closeModal()} label="Close" />
                        </Col>
                        <Col xs={12} md={4}>
                            <PrimaryButton onClick={() => this.pickMenu()} label="Pick Menu" />
                        </Col>
                    </Row>
                </Grid>
            </Modal>
        )
    }
}

Modal.setAppElement('#root')

export default withRouter(SuccessModal);