import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import moment from 'moment';
import "./instructions.css";
// Components
import PrimaryButton from '../../components/PrimaryButton';
import PrimaryButtonLarge from '../../components/PrimaryButtonLarge';
import InfoImageOne from '../../../images/info-image-one.jpg';
import InfoImageTwo from '../../../images/info-image-two.jpg';
import InfoImageThree from '../../../images/info-image-three.jpg';
import InfoImageFour from '../../../images/info-image-four.jpg';
import InfoImageFive from '../../../images/info-image-five.png';
import Logo from '../../../images/logo.png';

// reservationSize
class ReservationTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
        }

        this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    }

    componentDidMount() {
        this.updateWindowDimensions();
        window.addEventListener('resize', this.updateWindowDimensions);
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateWindowDimensions);
    }

    updateWindowDimensions() {
        this.setState({ width: window.innerWidth });
    }

    handleStepChange(step) { 
        let stepLandingReservation = {
            // NOT PART OF THIS STEP
            reservationDate: this.props.reservation.reservationDate,
            name: this.props.reservation.name,
            email: this.props.reservation.email,
            phone: this.props.reservation.phone,
            zipCode: this.props.reservation.zipCode,
            reservationSize: this.props.reservation.reservationSize,
            partySize: this.props.reservation.partySize,
            cohostName: this.props.reservation.cohostName,
            cohostEmail: this.props.reservation.cohostEmail,
            dietaryRestrictions: this.props.reservation.dietaryRestrictions,
            referral: this.props.reservation.referral
        }

        this.props.toggleStep(step, stepLandingReservation)
    }
    renderDesktop() {
        return (<Grid fluid className="graphic-container">

          
            <Row center="xs" style={{ marginBottom: `1rem` }}>
                <Col xs={11} sm={8} md={8} lg={9} xl={9}>
                    <p className="landing-before-you-book-text">complete your reservation in just a few quick steps</p>
                </Col>
            </Row>

            <Row center="xs" style={{ marginBottom: `0rem` }}>
                <Col xs={11} sm={8} md={7} lg={8} xl={8}>
                    <p className="body-text cost-text" style={{ fontWeight: `900`, fontStyle: `italic`, textDecoration: `underline` }}>our pricing</p>
                </Col>
            </Row>
            <Row center="xs" style={{ marginBottom: `1rem` }}>
                <Col xs={11} sm={8} md={7} lg={8} xl={8}>
                    <p className="body-text" style={{ fontSize: `22px`, fontWeight: `900`, marginTop: 0, color: `rgb(34, 56, 119)` }}>
                        <span style={{ textDecoration: `underline` }}>parties of 8 or more:</span><br /> 
                        $60 per person <br /><br />
                        <span style={{ textDecoration: `underline` }}>parties less than 8:</span><br />
                        $300 for parties of 2-3 <br />
                        $400 for parties of 4 <br />
                        $450 for parties of 5-7 <br />
                    </p>
                </Col>
            </Row>


            <Row around="xs" style={{ marginBottom: `1rem` }}>
                <Col xs={12} md={5}>
                    <PrimaryButtonLarge 
                        onClick={() => this.handleStepChange('one')} 
                        label="Click Here To Reserve" 
                        disabled={this.state.isButtonDisabled}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={12} sm={6} md={6}>
                    <Row>
                        <div className="info-image-one-background">
                        <img src={InfoImageOne} className="info-image-one" alt="info-one" />
                        </div>
                    </Row>
                    <Row>
                        <p className="body-text row-two-text" style={{ fontWeight: `700` }}>
                        2. then, choose your <span style={{ color: `rgb(34, 56, 119)` }}>family-style</span> courses from our set menus.
                        </p>
                        <p className="body-text row-two-small-text">(we’ve got the <span style={{ color: `rgb(34, 56, 119)` }} >salad</span> and <span style={{ color: `rgb(34, 56, 119)` }} >dessert)</span></p>
                    </Row>
                    <Row>
                        <div className="info-image-three-background">
                            <img src={InfoImageThree} className="info-image-three" alt="info-three" />
                        </div>
                    </Row>
                    <Row center="xs">
                        <p className="body-text row-four-text" style={{ textAlign: 'left', fontWeight: `700` }}>
                            4. we will arrive <span style={{ color: `rgb(34, 56, 119)` }}>90 minutes</span> prior to your reservation time.<br />
                            ready to cook in <span style={{ color: `rgb(34, 56, 119)` }}>your kitchen </span>
                            with all the <span style={{ color: `rgb(34, 56, 119)` }}>ingredients</span> and <span style={{ color: `rgb(34, 56, 119)` }}>supplies.</span>
                            
                        </p>
                    </Row>
                    <Row>
                        <div className="info-image-five-background">
                            <img src={InfoImageFive} className="info-image-five" alt="info-five" />
                        </div>
                    </Row>
                </Col>
                <Col xs={12} sm={6} md={6}>
                    <Row className="row-one-instructions">
                        <p className="body-text" style={{ width: `100%` }}>
                            <span className="row-one-big-text" style={{ fontWeight: `700` }}>1. first, tell us about<br />
                            <span className="row-one-big-text"  style={{ paddingLeft: `1rem` }} >your <span style={{ color: `rgb(34, 56, 119)` }}>gathering.</span></span></span><br />
                            <span className="row-one-small-text" style={{ fontWeight: `400` }}><span style={{ paddingLeft: `4rem` }}>(date, party size,</span><br /><span style={{ paddingLeft: `5rem` }}> dietary restrictions...) </span></span>
                        </p>
                    </Row>
                    <Row>
                        <div className="info-image-two-background">
                            <img src={InfoImageTwo} className="info-image-two" alt="info-two" />
                        </div>
                    </Row>
                    <Row className="row-three-instructions">
                        <p className='body-text'>
                            <span className="row-three-big-text" style={{ fontWeight: `700`, lineHeight: `.95` }}>
                            3. we provide a full service in-home dining experience for <span style={{ color: `rgb(34, 56, 119)` }}>you</span> and <span style={{ color: `rgb(34, 56, 119` }}>your guests.</span><br />
                            from start to finish, we are in and out within <span style={{ color: `rgb(34, 56, 119)` }}>3 hours.</span><br />
                            leave the prep, cooking, serving, and cleaning to us!
                            </span>
                            <span className="row-three-small-text">
                                (don't worry, we bring our own tools too!)
                            </span>
                        </p>
                    </Row>
                    <Row>
                        <div className="info-image-four-background">
                            <img src={InfoImageFour} className="info-image-four" alt="info-four" />
                        </div>
                    </Row>
                    <Row>
                        <p className="body-text row-five-text" style={{ fontWeight: `700`, marginLeft: `0rem`, textAlign: 'left' }}>
                            5. sit back, <span style={{ color: `rgb(34, 56, 119)` }}>relax,</span><br />
                            <span className="row-five-text-row-two" style={{ textAlign: 'left' }}>and enjoy the Placemat experience.</span><br />
                        </p>
                    </Row>
                </Col>
            </Row>
            <Row center="xs">
                <Col sm={12}>
                    <p className="body-text row-six-text" style={{ fontWeight: `700` }}>
                        we look forward to bringing our feel-good food to <span style={{ color: `rgb(34, 56, 119)` }}>you</span> and <span style={{ color: `rgb(34, 56, 119)` }}>your guests</span>!
                    </p>
                </Col>
            </Row>
            <Row around="xs" style={{ marginBottom: `1rem` }}>
                <Col xs={12} md={5}>
                    <PrimaryButtonLarge
                        onClick={() => this.handleStepChange('one')} 
                        label="Click Here To Reserve" 
                        disabled={this.state.isButtonDisabled}
                    />
                </Col>
            </Row>
        </Grid>)
    }

    renderMobile() {
        return (<Grid fluid>
            <Row center="xs" style={{ marginBottom: `1rem` }}>
                <Col xs={12}>
                    <p className="landing-wait-text">
                        WAIT.
                    </p>
                </Col>
            </Row>
            <Row center="xs" style={{ width: `125%`, marginLeft: `-12.5%`, marginBottom: `0rem` }}>
                <Col xs={12}>
                    <p className="landing-before-you-book-text">before you book, here's how it works</p>
                </Col>
            </Row>
            <Row center="xs" style={{ marginBottom: `0rem` }}>
                <Col xs={11} sm={8} md={7} lg={8} xl={8}>
                    <p className="body-text cost-text" style={{ fontWeight: `900`, fontStyle: `italic`, textDecoration: `underline` }}>our pricing</p>
                </Col>
            </Row>
            <Row center="xs" style={{ marginBottom: `1rem` }}>
                <Col xs={11} sm={8} md={7} lg={8} xl={8}>
                    <p className="body-text" style={{ fontSize: `22px`, fontWeight: `900`, marginTop: 0, color: `rgb(34, 56, 119)` }}>
                        <span style={{ textDecoration: `underline` }}>parties of 8 or more:</span><br /> 
                        $60 per person <br /><br />
                        <span style={{ textDecoration: `underline` }}>parties less than 8:</span><br />
                        $300 for parties of 2-3 <br />
                        $400 for parties of 4 <br />
                        $450 for parties of 5-7 <br />
                    </p>
                </Col>
            </Row>

            <Row around="xs" style={{ marginBottom: `1rem` }}>
                <Col xs={12} md={3}>
                    <PrimaryButtonLarge
                        onClick={() => this.handleStepChange('one')} 
                        label="Click Here To Reserve" 
                        disabled={this.state.isButtonDisabled}
                    />
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                <p className="body-text">
                    <span style={{ fontWeight: `700`, fontSize: `28px` }}>1. first, tell us <br />
                    <span style={{ paddingLeft: `0rem` }} >about your <span style={{ color: `rgb(34, 56, 119)` }}>gathering.</span></span></span><br />
                </p>
                <p className="body-text" style={{ textAlign: `center` }}>
                    <span style={{ fontWeight: `400`, fontSize: `17px` }}>(date, party size,<br /> dietary restrictions...)</span>
                </p>
                </Col>
            </Row>

            <Row style={{ marginBottom: `1rem` }}>
                <Col xs={12}>
                    <div className="info-image-one-background">
                    <img src={InfoImageOne} className="info-image-one" alt="info-one" />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <p className="body-text" style={{ fontSize: `30px`, fontWeight: `700`, textAlign: `right`, marginBottom: `1rem` }}>
                        2. then, choose your <span style={{ color: `rgb(34, 56, 119)` }}>family-style</span> <br />courses from our set menus.
                    </p>
                    <p className="body-text row-two-small-text">(we’ve got the <span style={{ color: `rgb(34, 56, 119)` }} >salad</span> and <span style={{ color: `rgb(34, 56, 119)` }} >dessert)</span></p>
                </Col>
            </Row>

            <Row style={{ marginBottom: `4rem` }}>
                <Col xs={12}>
                    <div className="info-image-two-background">
                        <img src={InfoImageTwo} className="info-image-two" alt="info-two" />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <p className='body-text' style={{ textAlign: 'center'}}>
                    <span className="row-three-big-text" style={{ fontWeight: `700`, lineHeight: `.95` }}>
                        3. we provide a full service in-home dining experience for <span style={{ color: `rgb(34, 56, 119)` }}>you</span> and <span style={{ color: `rgb(34, 56, 119` }}>your guests.</span><br />
                        from start to finish, we are in and out within <span style={{ color: `rgb(34, 56, 119)` }}>3 hours.</span><br />
                        leave the prep, cooking, serving, and cleaning to us!
                        </span>
                        <span className="row-three-small-text">
                            (don't worry, we bring our own tools too!)
                        </span>
                    </p>
                </Col>
            </Row>

            <Row style={{ marginBottom: `1rem` }}>
                <Col xs={12}>
                    <div className="info-image-three-background">
                        <img src={InfoImageThree} className="info-image-three" alt="info-three" />
                    </div>
                </Col>
            </Row>
            
            <Row style={{ marginBottom: `0rem` }}>
                <Col xs={12}>
                    <p className="body-text" style={{ textAlign: `center`, fontSize: `24px`, fontWeight: `700` }}>
                        4. we will arrive <span style={{ color: `rgb(34, 56, 119)` }}>90 minutes</span> prior to your reservation time.<br />
                        ready to cook in <span style={{ color: `rgb(34, 56, 119)` }}>your kitchen </span>
                        with all the <span style={{ color: `rgb(34, 56, 119)` }}>ingredients</span> and <span style={{ color: `rgb(34, 56, 119)` }}>supplies.</span>
                    </p>
                </Col>
            </Row>

            <Row style={{ marginBottom: `1rem` }}>
                <Col xs={12}>
                    <div className="info-image-four-background">
                        <img src={InfoImageFour} className="info-image-four" alt="info-four" />
                    </div>
                </Col>
            </Row>

            <Row style={{ marginBottom: `.75rem` }}>
                <Col xs={12}>
                    <p className="body-text" style={{ fontSize: `24px`, fontWeight: `700` }}>
                    5. sit back, <span style={{ color: `rgb(34, 56, 119)` }}>relax,</span><br />
                    <span className="row-five-text-row-two">and enjoy the Placemat experience.</span><br />
                    </p>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <div className="info-image-five-background">
                        <img src={InfoImageFive} className="info-image-five" alt="info-five" />
                    </div>
                </Col>
            </Row>

            <Row>
                <Col xs={12}>
                    <p className="body-text" style={{ textAlign: `center`, fontSize: `24px`, fontWeight: `700` }}>
                    <p className="body-text row-six-text" style={{ fontWeight: `700` }}>
                        we look forward to bringing our feel-good food to <span style={{ color: `rgb(34, 56, 119)` }}>you</span> and <span style={{ color: `rgb(34, 56, 119)` }}>your guests</span>!
                    </p>
                    </p>
                </Col>
            </Row>

            <Row around="xs" style={{ marginBottom: `1rem` }}>
                <Col xs={12} md={3}>
                    <PrimaryButtonLarge 
                        onClick={() => this.handleStepChange('one')} 
                        label="Click Here To Reserve" 
                        disabled={this.state.isButtonDisabled}
                    />
                </Col>
            </Row>
        </Grid>)
    }

    render() {
        return (<>
            {this.state.width < 500 ?
                this.renderMobile()
                : this.renderDesktop()
            }
        </>)
        
    }
}

export default ReservationTwo;