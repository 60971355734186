import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';

import awsmobile from '../../aws-exports';
import * as actions from '../../actions';

// Images
import Icon from '../../images/icon.png';
import Logo from '../../images/logo.png'; 

// Stylesheets
import './landing.css';

// Components 
import Login from './authentication/Login';
import ConfirmPassword from './authentication/ConfirmPassword';

Amplify.configure(awsmobile)

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            user: null,
            authenticationForm: 'login',
            loading: true
        }

        this.tryLogin = this.tryLogin.bind(this);
        this.confirmPassword = this.confirmPassword.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            return Auth.currentAuthenticatedUser()
                .then(user => {
                    if (user.signInUserSession.idToken.payload['cognito:groups'][0] !== 'admins') {
                        alert('Incorrect account type.');
                        return Auth.signOut()
                            .then(() => {
                                return this.setState({ loading: false }, () => this.props.history.push('/admin'))
                            })
                    } else {
                        return this.props.hydrateUser(user.attributes.sub, 'admin')
                            .then(resp => {
                                return this.setState({ loading: false }, () => this.props.history.push('/admin/dashboard'));
                            })
                    }
                })
            .catch(error => {
                return this.props.history.push('/admin')
            })
        })
    }

    tryLogin(creds) {
        return this.props.tryLogin(creds)
            .then(resp => {
                if (resp.code === 200) {
                    switch (resp.message) {
                        case ("NEW_PASSWORD_REQUIRED"):
                            return this.setState({ 
                                authenticationForm: 'confirm-password',
                                email: creds.email,
                                user: resp.user
                            })
                        case ("SUCCESS"):
                            if (resp.isAdmin) {
                                return this.props.history.push('/admin/dashboard')
                            } else {
                                return alert("Sorry, you are not an admin.")
                            }
                        default:
                            return;
                    }
                } else {
                    console.log("Something went wrong.", resp)
                }
            })
    }

    confirmPassword(newPassword) {
        let creds = {
            user: this.state.user,
            email: this.state.email,
            newPassword: newPassword
        }
        return this.props.tryConfirmPassword(creds)
            .then(resp => {
                console.log(resp)
                if (resp.code === 200) {
                    this.props.history.push('/')
                }
            })
            
    }

    renderAuthenticationForm() {
        switch (this.state.authenticationForm) {
            case ('login'):
                return <Login tryLogin={this.tryLogin} />
            case ('confirm-password'):
                return <ConfirmPassword confirmPassword={this.confirmPassword} />
            default:
                return <Login />
        }
    }

    render() {
        return (
        <div className="landing-body">
            <div className="landing-card">
            <Grid fluid>
                <Row center="xs" className="landing-card-header">
                    <Col xs={12} md={1}>
                        <img src={Icon} alt="icon" className="landing-card-icon" />
                    </Col>
                    <Col md={1} />
                    <Col xs={12} md={8}>
                        <img src={Logo} alt="logo" className="landing-card-logo" />
                    </Col>
                    <Col md={2} />
                </Row>
                <Row around="xs">
                    {this.renderAuthenticationForm()}
                </Row>
            </Grid>
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return {
        state
    }
}

export default withRouter(connect(mapStateToProps, actions)(Landing));