import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import PrimaryButton from '../../components/PrimaryButton';

import brunch from './menus/BrunchMenu';
import fallDinner from './menus/FallDinnerMenu';
import springDinner from './menus/SpringDinnerMenu';
import party from './menus/PartyMenu';

var _ = require('lodash');


class MenuStep extends Component { 
    constructor(props) {
        super(props);
        this.state = {
            menuItems: []
        }
    }
    
    componentDidMount() {
        switch (this.props.mealType) {
            case ('brunch'):
                this.setState({ 
                    menuItems: brunch, 
                    setting: false 
                })
                return;
            case ('fall + winter dinner'):
                this.setState({ 
                    menuItems: fallDinner, 
                    setting: false
                 })
                return;
            case ('spring + summer dinner'):
                this.setState({ 
                    menuItems: springDinner, 
                    setting: false
                })
                return;
            case ('party'):
                this.setState({ 
                    menuItems: party,
                    setting: false
                 })
                return;
            case ('favorites'):
                this.setState({ 
                    menuItems: null, 
                    setting: false
                })
                return;
            default:
                return;
        }
    }

    renderContent() {
        if (!this.state.setting) {
            if (this.props.mealType === 'party') {
                return (<>
                <Row center="xs">
                    <h3>You party, we got the food service and clean up. <br />Perfect for special announcements, office parties, social gatherings.</h3>
                </Row>
                <Row center="xs">
                    <p>Pick five dishes for your party!</p>
                </Row>
                <Row center="xs">
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToReservation} label="Go Back" />
                    </Col>
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToLocation} label="Next Step" disabled={ this.props.partyDishes.length === 5 ? false : true } />
                    </Col>
                </Row>
                <Row>
                    {this.state.menuItems.map((dish, index) => {
                        return (
                            <Col 
                                key={index} xs={12} md={4} 
                                className="reservation-confirmation-menu-item"
                                onClick={() => this.props.handlePartyDishChange(dish)}
                            >
                            <div 
                                className={ _.includes(this.props.partyDishes, dish) ?
                                    "reservation-confirmation-page-selected-item"
                                    : "reservation-confirmation-page-unselected-item"
                                }>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <div className="reservation-confirmation-menu-picture-container">
                                            <img src={dish.imageUrl} className="reservation-confirmation-menu-picture" alt={`${dish.name}_image`} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <div className="reservation-confirmation-menu-item-image-placeholder" />
                                        </Col>
                                    </Row>
                                    <Row center="xs" middle="xs" className="reservation-confirmation-menu-item-name-wrapper">
                                        <Col xs={12} md={12}>
                                            <p>{dish.name}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        )
                    })
                    }
                </Row>

                <Row center="xs">
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToReservation} label="Go Back" />
                    </Col>
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToLocation} label="Next Step" disabled={ this.props.partyDishes.length === 5 ? false : true } />
                    </Col>
                </Row>
                </>)
            } else if (this.props.mealType === 'favorites') {
                return (<>
                    <Row center="xs">
                        <Col xs={12} md={12}>
                            <h3>We’ll bring our best. It’s as simple as it sounds! <br />Let us take care of the menu by preparing 6 of our most popular dishes.<br />From starter to dessert!</h3>
                        </Col>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={12}>
                            <div className="reservation-confirmation-favorites-image-wrapper">
                                <img src="https://images.pexels.com/photos/3378890/pexels-photo-3378890.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=650&w=940" className="reservation-confirmation-favorites-image" alt="favorites_pic" />
                            </div>
                        </Col>
                    </Row>
                    <Row center="xs">
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToReservation} label="Go Back" />
                    </Col>
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToLocation} label="Next Step" />
                    </Col>
                </Row>
                </>)
            } else if (this.props.mealType === 'brunch') {
            return (<>
                <Row center="xs">
                    <h3>Start the morning or weekend off right. We'll bring the coffee.</h3>
                </Row>
                {/* Entrees */}
                <Row center="xs">
                    <h3>Entrees - Pick 2</h3>
                </Row>
                <Row center="xs">
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToReservation} label="Go Back" />
                    </Col>
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToLocation} label="Next Step" disabled={ this.props.focusedBrunchEntreeOne && this.props.focusedBrunchEntreeTwo && this.props.focusedSideOne && this.props.focusedSideTwo ? false : true } />
                    </Col>
                </Row>
                <Row>
                        {_.filter(this.state.menuItems, { 'course': 'entrees' }).map((entree, index) => {
                            return (
                            <Col key={index} xs={12} md={4}
                                className="reservation-confirmation-menu-item"
                                onClick={() => this.props.handleBrunchEntreeChange(entree)}
                            >
                                <div
                                className={ this.props.focusedBrunchEntreeOne === entree.name || this.props.focusedBrunchEntreeTwo === entree.name ? 
                                    "reservation-confirmation-page-selected-item"
                                    : "reservation-confirmation-page-unselected-item"
                                }>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <div className="reservation-confirmation-menu-picture-container">
                                            <img src={entree.imageUrl} className="reservation-confirmation-menu-picture" alt={`${entree.name}_image`} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row center="xs" middle="xs" className="reservation-confirmation-menu-item-name-wrapper">
                                        <Col xs={12} md={12}>
                                            <p>{entree.name}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            )
                        })}
                </Row>
    
                {/* Sides */}
                <Row center="xs">
                    <h3>Sides - Pick 2</h3>
                </Row>
                <Row center="xs">
                    <p>(Click the same side twice to double up!)</p>
                </Row>
                <Row>
                        {_.filter(this.state.menuItems, { 'course': 'sides' }).map((side, index) => {
                            return (
                                <Col 
                                    key={index} xs={12} md={4}
                                    className="reservation-confirmation-menu-item"
                                    onClick={() => this.props.handleSideChange(side)}
                                >
                                    <div 
                                    className={ this.props.focusedSideOne === side.name || this.props.focusedSideTwo === side.name ? 
                                        "reservation-confirmation-page-selected-item"
                                        : "reservation-confirmation-page-unselected-item"
                                    }>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <div className="reservation-confirmation-menu-picture-container">
                                                <img src={side.imageUrl} className="reservation-confirmation-menu-picture" alt={`${side.name}_image`} />
                                                </div>
                                            </Col>
                                        </Row>
                                        { this.props.focusedSideOne === side.name && this.props.focusedSideOne === this.props.focusedSideTwo ?
                                            <div className="reservation-confirmation-menu-item-double-side">DOUBLE SIDE</div>
                                            : ''
                                        }
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <div className="reservation-confirmation-menu-item-image-placeholder" />
                                            </Col>
                                        </Row>
                                        <Row center="xs" middle="xs" className="reservation-confirmation-menu-item-name-wrapper">
                                            <Col xs={12} md={12}>
                                                <p>{side.name}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            )
                        })}
                </Row>
                
                <Row center="xs">
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToReservation} label="Go Back" />
                    </Col>
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToLocation} label="Next Step" disabled={ this.props.focusedBrunchEntreeOne && this.props.focusedBrunchEntreeTwo && this.props.focusedSideOne && this.props.focusedSideTwo ? false : true } />
                    </Col>
                </Row>
            </>)
            } else {
            return (<>
                {this.props.mealType === 'fall + winter dinner' || this.props.mealType === 'spring + summer dinner' ?
                    <Row center="xs">
                        <h3>We've got the salad <br />and dessert! <br />you pick the rest!</h3>
                    </Row>
                    : ''
                }
                <Row center="xs">
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToReservation} label="Go Back" />
                    </Col>
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToLocation} label="Next Step" disabled={ this.props.focusedEntree && this.props.focusedStarter && this.props.focusedSideOne && this.props.focusedSideTwo ? false : true } />
                    </Col>
                </Row>
                <Row center="xs">
                    <h3>Starters - Pick 1</h3>
                </Row>
                {/* Starters */}
                <Row>
                        {_.filter(this.state.menuItems, { 'course': 'starters' }).map((starter, index) => {
                            return (
                                <Col 
                                    key={index} xs={12} md={4} 
                                    className="reservation-confirmation-menu-item"
                                    onClick={() => this.props.handleStarterChange(starter)}
                                >
                                <div className={ this.props.focusedStarter === starter.name ? 
                                        "reservation-confirmation-page-selected-item"
                                        : "reservation-confirmation-page-unselected-item"
                                }>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <div className="reservation-confirmation-menu-picture-container">
                                                <img src={starter.imageUrl} className="reservation-confirmation-menu-picture" alt={`${starter.name}_image`} />
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <div className="reservation-confirmation-menu-item-image-placeholder" />
                                            </Col>
                                        </Row>
                                        <Row center="xs" middle="xs" className="reservation-confirmation-menu-item-name-wrapper">
                                            <Col xs={12} md={12}>
                                                <p>{starter.name}</p>
                                            </Col>
                                        </Row>
                                </div>
                                </Col>
                            )
                        })}
                </Row>
            
                {/* Entrees */}
                <Row center="xs">
                    <h3>Entrees - Pick 1</h3>
                </Row>
                <Row>
                        {_.filter(this.state.menuItems, { 'course': 'entrees' }).map((entree, index) => {
                            return (
                            <Col key={index} xs={12} md={4}
                                className="reservation-confirmation-menu-item"
                                onClick={() => this.props.handleEntreeChange(entree)}
                            >
                                <div
                                className={ this.props.focusedEntree === entree.name ? 
                                    "reservation-confirmation-page-selected-item"
                                    : "reservation-confirmation-page-unselected-item"
                                }>
                                    <Row>
                                        <Col xs={12} md={12}>
                                            <div className="reservation-confirmation-menu-picture-container">
                                            <img src={entree.imageUrl} className="reservation-confirmation-menu-picture" alt={`${entree.name}_image`} />
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row center="xs" middle="xs" className="reservation-confirmation-menu-item-name-wrapper">
                                        <Col xs={12} md={12}>
                                            <p>{entree.name}</p>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                            )
                        })}
                </Row>
    
                {/* Sides */}
                <Row center="xs">
                    <h3>Sides - Pick 2</h3>
                </Row>
                <Row center="xs">
                    <p>(Click the same side twice to double up!)</p>
                </Row>
                <Row>
                        {_.filter(this.state.menuItems, { 'course': 'sides' }).map((side, index) => {
                            return (
                                <Col 
                                    key={index} xs={12} md={4}
                                    className="reservation-confirmation-menu-item"
                                    onClick={() => this.props.handleSideChange(side)}
                                >
                                    <div 
                                    className={ this.props.focusedSideOne === side.name || this.props.focusedSideTwo === side.name ? 
                                        "reservation-confirmation-page-selected-item"
                                        : "reservation-confirmation-page-unselected-item"
                                    }>
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <div className="reservation-confirmation-menu-picture-container">
                                                <img src={side.imageUrl} className="reservation-confirmation-menu-picture" alt={`${side.name}_image`} />
                                                </div>
                                            </Col>
                                        </Row>
                                        { this.props.focusedSideOne === side.name && this.props.focusedSideOne === this.props.focusedSideTwo ?
                                            <div className="reservation-confirmation-menu-item-double-side">DOUBLE SIDE</div>
                                            : ''
                                        }
                                        <Row>
                                            <Col xs={12} md={12}>
                                                <div className="reservation-confirmation-menu-item-image-placeholder" />
                                            </Col>
                                        </Row>
                                        <Row center="xs" middle="xs" className="reservation-confirmation-menu-item-name-wrapper">
                                            <Col xs={12} md={12}>
                                                <p>{side.name}</p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Col>
                            )
                        })}
                </Row>
                
                <Row center="xs">
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToReservation} label="Go Back" />
                    </Col>
                    <Col xs={12} md={3}>
                        <PrimaryButton onClick={this.props.navigateToLocation} label="Next Step" disabled={ this.props.focusedEntree && this.props.focusedStarter && this.props.focusedSideOne && this.props.focusedSideTwo ? false : true } />
                    </Col>
                </Row>
            </>)
            }
        }
    }
    render() {
        return this.renderContent();
    }
}

export default MenuStep;