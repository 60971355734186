import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

// Main Page Components
// import Landing from '../app/landing/Landing';

// Host Components
import HostLanding from '../app/hosts/Landing';
import HostReservation from '../app/hosts/reservation-form/ReservationPage';
import HostReservationConfirmation from '../app/hosts/reservation-confirmation/ReservationConfirmationPage';
import HostDashboard from '../app/hosts/dashboard/Dashboard';

// Chef Components
import ChefLanding from '../app/chefs/Landing';
import ChefLogin from '../app/chefs/authentication/Login';
import ChefApplication from '../app/chefs/application-form/ApplicationPage';
import ChefConfirm from '../app/chefs/confirm/index';
// import ChefConfirm from '../app/chefs/confirm/Confirmation';
import ChefDashboard from '../app/chefs/dashboard/Dashboard';

// Admin Components
import AdminLanding from '../app/admin/Landing';
import AdminCalendar from '../app/admin/calendar/index';
import AdminDashboard from '../app/admin/dashboard/Dashboard';
import AdminChefApplications from '../app/admin/chef-applications/index';
import AdminReservations from '../app/admin/reservations/index';
import AdminInviteChefs from '../app/admin/invite-chefs/index';

// Delivery
import Delivery from '../app/delivery/index';
import DeliveryReceipt from '../app/delivery/steps/receipt';

class App extends Component {
  render() {
    return (
      <div>
        <BrowserRouter>
          <>
          {/* Public Paths */}
          {/* <Route exact path="/" render={() => <Landing />} /> */}

          {/* Host Paths */}
          {/* <Route exact path="/" render={() => <HostLanding />} /> */}
          <Route exact path="/" render={() => <HostReservation />} />
          <Route exact path="/landing" render={() => <HostLanding />} />
          {/* <Route exact path="/hosts/reservation" render={() => <HostReservation />} /> */}
          <Route path ="/hosts/reservation/confirm/:id" render={(props) => <HostReservationConfirmation {...props} />} />
          <Route exact path="/hosts/dashboard" render={() => <HostDashboard />} />
          {/* Chef Paths */}
          <Route exact path="/chefs" render={() => <ChefLanding />} />
          <Route exact path="/chefs/login" render={() => <ChefLogin />} />
          <Route exact path="/chefs/application" render={() => <ChefApplication />} />
          <Route path="/chefs/confirm-account" render={() => <ChefConfirm />} />
          {/* <Route path="/chefs/confirm/:id" render={(props) => <ChefConfirm {...props} />} /> */}
          <Route path="/chefs/dashboard" render={(props) => <ChefDashboard {...props} />} />
          {/* Admin Paths */}
          <Route exact path="/admin" render={() => <AdminLanding />} />
          <Route exact path="/admin/calendar" render={() => <AdminCalendar />} />
          <Route exact path="/admin/dashboard" render={() => <AdminDashboard />} />
          <Route exact path="/admin/chef-applications" render={() => <AdminChefApplications />} />
          <Route exact path="/admin/reservations" render={() => <AdminReservations />} />
          <Route exact path="/admin/invite-chefs" render={() => <AdminInviteChefs />} />

          {/* Delivery Paths */}
          <Route exact path="/delivery" render={() => <Delivery />} />
          <Route path="/delivery/receipt/:id" render={(props) => <DeliveryReceipt />} />
          </>
        </BrowserRouter>
      </div>
    )
  }
}

export default App;
