import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import InputField from '../../components/InputField';
import SelectField from '../../components/SelectField';
import PrimaryButton from '../../components/PrimaryButton';

import getNextWeekday from './getNextWeekday';

class One extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delivery: this.props.delivery,
            buttonDisabled: true
        }

        this.handleButtonDisabled = this.handleButtonDisabled.bind(this);
        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePartySizeChange = this.handlePartySizeChange.bind(this);
    }

    componentDidMount() {
        this.setState({ 
            delivery: this.props.delivery
        }, () => this.handleButtonDisabled())
    }

    handleButtonDisabled() {
        if (!this.state.delivery.name || !this.state.delivery.email) {
            this.setState({ buttonDisabled: true })
        } else {
            this.setState({ buttonDisabled: false })
        }
    }

    handleNameChange(event) {
        this.setState({
            delivery: {
                ...this.state.delivery,
                name: event.target.value
            }
        }, () => this.handleButtonDisabled())
    }

    handleEmailChange(event) {
        this.setState({
            delivery: {
                ...this.state.delivery,
                email: event.target.value
            }
        }, () => this.handleButtonDisabled())
    }

    handlePartySizeChange(event) {
        this.setState({
            delivery: {
                ...this.state.delivery,
                partySize: event.value
            }
        })
    }

    render() {
        let partySizeOptions = [
            { label: 4, value: 4 },
            { label: 5, value: 5 },
            { label: 6, value: 6 },
            { label: 7, value: 7 },
            { label: 8, value: 8 }
        ]
        return (<Grid fluid>
            <Row center="xs" style={{ marginTop: `2.5rem` }}>
                <Col xs={12} md={4}>
                    <h3>First let's get some details</h3>
                </Col>
            </Row>
            <Row center="xs" style={{ marginTop: `2.5rem` }}>
                <Col xs={12} md={4}>
                    <InputField value={this.state.delivery.name} onChange={this.handleNameChange} placeholder="Your Name" />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={4}>
                    <InputField value={this.state.delivery.email} onChange={this.handleEmailChange} placeholder="Email" />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={4}>
                    <SelectField value={this.state.delivery.partySize} onChange={this.handlePartySizeChange} options={partySizeOptions} label={ this.state.delivery.partySize ? this.state.delivery.partySize : "Party Size" } isSearchable={true} />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={2}>
                    <PrimaryButton onClick={() => this.props.switchStep('instructions', this.state.delivery)} label="Back" />
                </Col>
                <Col xs={12} md={2}>
                    <PrimaryButton onClick={() => this.props.switchStep('two', this.state.delivery)} label="Next" disabled={this.state.buttonDisabled} />
                </Col>
            </Row>
        </Grid>)
    }
}

export default One;