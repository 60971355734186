import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import * as actions from '../../../actions';

// Components
// import InputField from '../../components/InputField';
// import PrimaryButton from '../../components/PrimaryButton';
import DeliveryPaymentForm from '../../components/DeliveryPaymentForm'

class Deposit extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delivery: this.props.delivery,
            fetching: false,
            displayErrors: false,
            errorMessage: ""
        }

        this.submitDelivery = this.submitDelivery.bind(this);
    }

    submitDelivery(id) {
        let delivery = {
            id: id,
            ...this.state.delivery
        }
        console.log(delivery)
        return this.setState({ fetching: true }, () => {
            return this.props.createDelivery(delivery)
                .then(resp => {
                    if (resp.code === 200) {
                        console.log("Success", resp)
                        return this.setState({ fetching: false }, () => {
                            // let adminEmail = {
                            //     recipient: 'reservations@eatplacemat.com',
                            //     recipient: 'jbavier123@gmail.com',
                            //     sender: 'info@eatplacemat.com',
                            //     subject: 'New Pick Up',
                            //     html: `<div>    
                            //         <h3>New Pick Up:</h3>
                            //         <p>Pick Up Day: ${delivery.pickUpDay}</p>
                            //         <p>Menu: ${delivery.menu}</p>
                            //         <p>Zip Code: ${delivery.zip}</p>
                            //         <p>Name: ${delivery.name}</p>
                            //         <p>Email: ${delivery.email}</p>
                            //         <p>Phone: ${delivery.phone}</p>
                            //         <p>Address: 
                            //         <p>${delivery.streetAddress}</p>
                            //         <p>${delivery.city}, ${delivery.state}</p>

                            //         <p>Dietary Restrictions ${delivery.dietaryRestrictions.map((restriction, index) => {
                            //             if (restriction.required) return restriction.type
                            //         }).join(" ")}</p>
                            //     </div>`
                            // }

                            // let chefEmail = {
                            //     recipient: 'reservations@eatplacemat.com',
                            //     recipient: 'jbavier123@gmail.com',
                            //     sender: 'info@eatplacemat.com',
                            //     subject: 'New Pick Up',
                            //     html: `<div>    
                            //         <h3>New Pick Up:</h3>
                            //         <p>Pick Up Day: ${delivery.pickUpDay}</p>
                            //         <p>Menu: ${delivery.menu}</p>
                            //         <p>Zip Code: ${delivery.zip}</p>
                            //         <p>Name: ${delivery.name}</p>
                            //         <p>Email: ${delivery.email}</p>
                            //         <p>Phone: ${delivery.phone}</p>
                            //         <p>Address: 
                            //         <p>${delivery.streetAddress}</p>
                            //         <p>${delivery.city}, ${delivery.state}</p>
                            
                            //         <p>Please reply YES to this email if you are interesting in preparing this order.</p>
                            //     </div>`
                            // }

                            let userEmail = {
                                // recipient: 'reservations@eatplacemat.com',
                                recipient: `${delivery.email}`,
                                sender: 'info@eatplacemat.com',
                                subject: 'Your Placemat Family Meal is Confirmed!',
                                html: `<div>    
                                    <p>Thank you for ordering with Placemat.</p>
                                    <p>Delivery Day: ${delivery.deliveryDate}</p> 
                                    <p>Delivery Time: ${delivery.deliveryTime}</p>
                                    <p>Zip Code: ${delivery.zip}</p>
                                    <p>Name: ${delivery.name}</p>
                                    <p>Email: ${delivery.email}</p>
                                    <p>Address: 
                                    <p>${delivery.streetAddress}</p>
                                    <p>${delivery.city}, ${delivery.state}</p>
                                    <p>Instructions:</p>
                                    <p>${delivery.instructions}</p>
                                </div>`
                            }
                            
                            // this.props.sendEmail(adminEmail)
                            // this.props.sendEmail(chefEmail)
                            this.props.sendEmail(userEmail)
                            let date = resp.body.data.delivery.deliveryDate.split('T')[0]
                            var event = {
                                "summary": "DELIVERY for " + delivery.name,
                                "description": `<div><p>Family Meal for ${delivery.name}.</p><p>Details:</p><ul><li>Delivery Day: ${delivery.deliveryDate}</li><li>Delivery Time: ${delivery.deliveryTime}</li><li>Address: ${delivery.streetAddress}, ${delivery.city}, ${delivery.state} ${delivery.zip}</li><li>Email: ${delivery.email}</li><li>Dietary Restrictions: <ul>${delivery.dietaryRestrictions.map((restriction, index) => { if (restriction.required) return `<li>${restriction.type}</li>` }).join("")}</li></ul><li>Special Instructions: ${delivery.instructions}</li></ul></div>`,
                                "start": {
                                    "date": date,
                                },
                                "end": {
                                    "date": date
                                }
                            }
                            var calendarId = "hgffkb2bm0lr9k986emsifjiro@group.calendar.google.com";
                            this.props.addToCalendar(event, calendarId)
                            // this.props.switchStep('receipt', resp.body.data.delivery)
                            this.props.history.push(`/delivery/receipt/${resp.body.data.delivery.id}`)
                        })
                    } else {
                        console.log("Error!", resp)
                        return this.setState({ fetching: false, displayErrors: true, errorMessage: "Something went wrong on our end! Please try again later." })
                    }
                })
        })
    }

    render() {
        return (<Grid fluid>
            <Row center="xs">
                <Col xs={12} md={6}>
                    <DeliveryPaymentForm
                        // paymentForm={ window.SqPaymentForm }
                        delivery={this.state.delivery}
                        streetAddress={this.state.delivery.streetAddress}
                        city={this.state.delivery.city}
                        locationZip={this.state.delivery.zip}
                        switchStep={this.props.switchStep}
                        submitDelivery={this.submitDelivery}
                    />
                </Col>
            </Row>
        </Grid>)
    }
}

function mapStateToProps(state) { return state }
export default withRouter(connect(mapStateToProps, actions)(Deposit));