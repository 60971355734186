import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import ReactLoading from 'react-loading';

import * as actions from '../../../actions';

import InputField from '../../components/InputField'
import PrimaryButton from '../../components/PrimaryButton';

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            showEmailError: false,
            emailErrorMessage: '',
            password: '',
            showPasswordError: false,
            passwordErrorMessage: '',
            isButtonDisabled: true,
            fetching: false
        }

        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handlePasswordChange = this.handlePasswordChange.bind(this);
        this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    }

    handleEmailChange(event) {
        this.setState({ email: event.target.value }, () => this.handleButtonEnable())
    }

    handlePasswordChange(event) {
        this.setState({ password: event.target.value }, () => this.handleButtonEnable())
    }

    handleButtonEnable() {
        if (this.state.email !== '' && this.state.password !== '') {
            this.setState({ isButtonDisabled: false })
        } else {
            this.setState({ isButtonDisabled: true })
        }
    }

    handleLoginSubmit() {
        return this.setState({ fetching: true }, () => {
            return this.props.tryLogin({ email: this.state.email, password: this.state.password })
                .then(resp => {
                    if (resp.code === 200) {
                        return this.setState({ fetching: false }, () => {
                            return this.props.history.push('/admin/dashboard');
                        })
                    } else {
                        alert("Incorrect credentials!")
                        this.setState({ fetching: false })
                    }
                })
        })
    }

    render() {
        return (<Grid fluid>
        <Row around="xs">
            <Col xs={12} md={3}>
                <InputField
                    value={this.state.email}
                    placeholder="Email"
                    onChange={this.handleEmailChange}
                    showError={this.state.showEmailError}
                    errorMessage={this.state.emailErrorMessage}
                />
            </Col>
        </Row>
        <Row around="xs">
            <Col xs={12} md={3}>
                <InputField
                    value={this.state.password}
                    placeholder="Password"
                    type="password"
                    onChange={this.handlePasswordChange}
                    showError={this.state.showPasswordError}
                    errorMessage={this.state.passwordErrorMessage}
                />
            </Col>
        </Row>
        <Row around="xs">
            <Col xs={12} md={3}>
                <PrimaryButton
                    onClick={this.handleLoginSubmit}
                    label="Login"
                    disabled={this.state.isButtonDisabled}
                />
            </Col>
        </Row>

        {/* Loading */}
        <Row center="xs" style={{ visibility: this.state.fetching ? 'visible' : 'hidden' }}>
            <div className="loading-icon" style={{ 
                position: 'fixed',
                top: `30%`,
                textAlign: 'center',
                zIndex: 10,
            }}>
                <ReactLoading type="bubbles" color="black" height={100} width={150} />
            </div>
        </Row>
        </Grid>)
    }
}

function mapStateToProps(state) { return { state } }
export default withRouter(connect(mapStateToProps, actions)(Login));