import React from 'react';
import { Grid, Row } from 'react-flexbox-grid';

import './TextArea.css';

const TextArea = ({ placeholder, value, onChange }) => {
    return (
        <Grid className="wrapper">
            <Row>
                <textarea 
                    className="textarea-field-wrapper" 
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}   
                />
            </Row>
        </Grid>
    )
}

export default TextArea;