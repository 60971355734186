import React, { Component } from 'react';
import { Row, Col } from 'react-flexbox-grid';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import moment from 'moment';

import { DayPickerSingleDateController } from 'react-dates';

// Components
import PrimaryButton from '../../../components/PrimaryButton';
import InputField from '../../../components/InputField';

var _ = require('lodash');

class EditReservation extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reservationCopy: this.props.reservation,
            focused: true,
            isButtonDisabled: false,
            loading: true
        }

        this.handleNameChange = this.handleNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);

        this.handleZipChange = this.handleZipChange.bind(this);
        this.handleCohostNameChange = this.handleCohostNameChange.bind(this);
        this.handleCohostChange = this.handleCohostChange.bind(this);
        this.handleRestrictionChange = this.handleRestrictionChange.bind(this);
        this.handleOtherChange = this.handleOtherChange.bind(this);
        this.handleReferralChange = this.handleReferralChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
        this.handleGiftCodeChange = this.handleGiftCodeChange.bind(this);
    }

    componentDidMount() {
        // this.setState({
        //     reservationCopy: this.props.reservation
        // }, () => {
        //     this.setState({ loading: false })
        // })
    }
   

    handleNameChange(event) { this.setState({ reservationCopy: { ...this.state.reservationCopy, name: event.target.value }}, () => {
        this.handleButtonEnabled()
    })}
    handleEmailChange(event) { this.setState({ reservationCopy: { ...this.state.reservationCopy, email: event.target.value }}, () => {
        this.handleButtonEnabled()
    })}
    handlePhoneChange(event) { this.setState({ reservationCopy: { ...this.state.reservationCopy, phone: event.target.value }}, () => {

    })}

    handleZipChange(event) { this.setState({ reservationCopy: { ...this.state.reservationCopy, zipCode: event.target.value }}, () => {
        this.handleButtonEnabled()
    })}
    handleCohostNameChange(event) { this.setState({ reservationCopy: { ...this.state.reservationCopy, cohostName: event.target.value }}, () => {
        this.handleButtonEnabled()
    })}
    handleCohostChange(event) { this.setState({ reservationCopy: { ...this.state.reservationCopy, cohostEmail: event.target.value }}, () => {
        this.handleButtonEnabled()
    })}
    handleRestrictionChange(event, index) { 
        let updatedRestrictions = this.state.reservationCopy.dietaryRestrictions
        updatedRestrictions[index].required = event.target.checked
        this.setState({ reservationCopy: { 
            ...this.state.reservationCopy,
            dietaryRestrictions: updatedRestrictions 
        }
        }, () => this.handleButtonEnabled()); 
    }
    handleOtherChange(event) { 
        let updatedRestrictions = this.state.reservationCopy.dietaryRestrictions
        if (event.target.value !== "") {
            updatedRestrictions[13].type = event.target.value
            updatedRestrictions[13].required = true
            this.setState({
                reservationCopy: {
                    ...this.state.reservationCopy,
                    dietaryRestrictions: updatedRestrictions
                }
            }, () => this.handleButtonEnabled())
        } else {
            updatedRestrictions[13].type = "Other"
            updatedRestrictions[13].required = false
            this.setState({
                reservationCopy: {
                    ...this.state.reservationCopy,
                    dietaryRestrictions: updatedRestrictions
                }
            }, () => this.handleButtonEnabled())
        }
     }
    handleReferralChange(event) { 
        this.setState({ reservationCopy: { ...this.state.reservationCopy, referral: event.target.value }}, () => {
            this.handleButtonEnabled()
        })}
    
    handleGiftCodeChange(event) { 
        this.setState({ reservationCopy: { ...this.state.reservationCopy, giftCode: event.target.value.split('-')[1] }}, () => {
            this.handleButtonEnabled()
        })}

    handleButtonEnabled() {
        if (!this.state.reservationCopy.dietaryRestrictions[0].required && !this.state.reservationCopy.dietaryRestrictions[1].required && !this.state.reservationCopy.dietaryRestrictions[2].required && !this.state.reservationCopy.dietaryRestrictions[3].required && !this.state.reservationCopy.dietaryRestrictions[4].required && !this.state.reservationCopy.dietaryRestrictions[5].required && !this.state.reservationCopy.dietaryRestrictions[6].required && !this.state.reservationCopy.dietaryRestrictions[7].required && !this.state.reservationCopy.dietaryRestrictions[8].required && !this.state.reservationCopy.dietaryRestrictions[9].required && !this.state.reservationCopy.dietaryRestrictions[10].required && !this.state.reservationCopy.dietaryRestrictions[11].required && !this.state.reservationCopy.dietaryRestrictions[12].required && !this.state.reservationCopy.dietaryRestrictions[13].required) {
            this.setState({ isButtonDisabled: true })
        } else if (this.state.reservationCopy.name === "" || this.state.reservationCopy.email === "" || this.state.reservationCopy.zipCode === "" || this.state.reservationCopy.referral === "" || this.state.reservationCopy.phone === "") {
            this.setState({ isButtonDisabled: true })
        } else {
            this.setState({ isButtonDisabled: false })
        }
    }
    renderDietaryRestrctions() {
        return this.state.reservationCopy.dietaryRestrictions.map((restriction, index) => {
            if (restriction.type === 'nut' || restriction.type === 'vegan' || restriction.type === 'keto' || restriction.type === 'shellfish' || restriction.type === 'peanut' || restriction.type === 'vegetarian' || restriction.type === 'gluten' || restriction.type === 'paleo' || restriction.type === 'soy' || restriction.type === 'whole30' || restriction.type === 'grain' || restriction.type === 'dairy' || restriction.type === 'none') {
                return (
                    <Col xs={12} md={4} key={index}>
                        <div className="dietary-preference-checkbox-wrapper">
                            <Row middle="xs">
                                <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.reservationCopy.dietaryRestrictions[index].required} onChange={(e) => this.handleRestrictionChange(e, index)} />
                                <p>{restriction.type.charAt(0).toUpperCase() + restriction.type.slice(1)}</p>
                            </Row>
                        </div>
                    </Col>
                )
            } else {
                return (
                    <Col xs={12} md={4} key={index}>
                        <div className="dietary-preference-checkbox-wrapper">
                            <Row middle="xs">
                                <input className="dietary-preference-input" type="text" value={this.state.reservationCopy.dietaryRestrictions[13].type} placeholder="Other" onChange={this.handleOtherChange.bind(this)} />
                            
                            </Row>
                        </div>
                    </Col>
                )
            }
        })
    }

    render() {
        // console.log(this.state.reservationCopy.reservationDate)
        var dateString = moment(this.state.reservationCopy.reservationDate).utc().format('YYYY-MM-DD')
        return (<>
            <Row center="xs">
                <Col xs={12} md={6}>
                    <p>Don't forget to hit save when you are finished making changes.</p>
                </Col>
            </Row>
            {/* Name */}
            <Row center="xs">
                <Col xs={12} md={4}>      
                    <Row>
                        <Col xs={12} md={12}>
                            <InputField placeholder="Name" value={this.state.reservationCopy.name} onChange={this.handleNameChange} type="text" />
                        </Col>
                    </Row>

                    {/* Email */}
                    <Row>
                        <Col xs={12} md={12}>
                            <InputField placeholder="Email" value={this.state.reservationCopy.email} onChange={this.handleEmailChange} type="text" />
                        </Col>
                    </Row>

                     <Row>
                        <Col xs={12} md={12}>
                            <InputField placeholder="Phone" value={this.state.reservationCopy.phone} onChange={this.handlePhoneChange} type="text" />
                        </Col>
                    </Row>

                    {/* Reservation Date */}
                    <Row>
                        
                    </Row>

                    {/* Zip Code */}
                    <Row>
                        <Col xs={12} md={12}>
                            <InputField placeholder="Zip" value={this.state.reservationCopy.zipCode} onChange={this.handleZipChange} type="text" />
                        </Col>
                    </Row>

                    {/* Cohost */}
                    <Row>
                        <Col xs={12} md={12}>
                            <InputField placeholder="Cohost Name" value={this.state.reservationCopy.cohostName} onChange={this.handleCohostNameChange} type="text" />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={12}>
                            <InputField placeholder="Cohost Email" value={this.state.reservationCopy.cohostEmail} onChange={this.handleCohostChange} type="text" />
                        </Col>
                    </Row>

                    {/* Referral */}
                    <Row>
                        <Col xs={12} md={12}>
                            <InputField placeholder="Referral" value={this.state.reservationCopy.referral} onChange={this.handleReferralChange} type="text" />
                        </Col>
                    </Row>

                    <Row>
                        <Col xs={12} md={12}>
                            <InputField placeholder="Gift Code" value={`GIFT-${this.state.reservationCopy.giftCode}`} onChange={this.handleGiftCodeChange} type="text" />
                        </Col>
                    </Row>
                    
                </Col>


                <Col xs={12} md={4}>
                    <DayPickerSingleDateController
                        date={moment(this.state.reservationCopy.reservationDate, 'MM/DD/YYYY')}
                        onDateChange={reservationDate => this.setState({ reservationCopy: { ...this.state.reservationCopy, reservationDate: reservationDate }})}
                        focused={this.state.focused}
                        onFocusChange={({ focused }) => this.setState({ focused })}
                        hideKeyboardShortcutsPanel
                        isOutsideRange={(day) => day.isBefore(moment().add(10, 'days'))}
                        transitionDuration={0}
                        isDayBlocked={(momentDate) => {
                            const blockedDates = [moment("2021-07-01"), moment("2021-07-02"), moment("2021-07-03"), moment("2021-07-04"), moment("2021-07-05"),
                            moment("2021-07-06"), moment("2021-07-07"), moment("2021-07-08"), moment("2021-07-09"), moment("2021-07-10"), moment("2021-07-11"),
                            moment("2021-07-12"), moment("2021-07-13"), moment("2021-07-14"), moment("2021-07-15"), moment("2021-07-16"), moment("2021-07-17")]
                            return blockedDates.some(date => momentDate.isSame(date, 'day'))
                        }}
                        // onOutsideClick={actionHandler}
                        // onPrevMonthClick={actionHandler}
                        // onNextMonthClick={actionHandler}
                        // transitionDuration={0}
                    />
                </Col>
            </Row>

            {/* Dietary Restrictions */}
            <Row center="xs" style={{ marginTop: `2.5rem`}}>
                <Col xs={12} md={7}>
                    <Row>
                        {this.renderDietaryRestrctions()}
                    </Row>
                </Col>
            </Row>

            {/* End Confirm Info */}
            <Row around="xs">
                <Col md={3} />
                <Col xs={12} md={3}>
                    <PrimaryButton onClick={() => this.props.toggleEditForm()} label="Cancel" />
                </Col>
                <Col xs={12} md={3}>
                    <PrimaryButton onClick={() => this.props.updateReservation(this.state.reservationCopy)} label="Save" disabled={this.state.isButtonDisabled} />
                </Col>
                <Col md={3} />
            </Row>
        </>)
    }
}

export default EditReservation;