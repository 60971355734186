const springDinner = [
    {
        course: 'starters',
        name: 'Spiced Pulled Pork + Chimichurri + Local Sourdough',
        imageUrl: 'https://images.pexels.com/photos/3471267/pexels-photo-3471267.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'starters',
        name: 'Market Vegetable Toasts',
        imageUrl: 'https://images.pexels.com/photos/3471474/pexels-photo-3471474.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'starters',
        name: 'Ginger Steak Salad Wrap Board + Pink Radish Sauce',
        imageUrl: 'https://images.pexels.com/photos/3471303/pexels-photo-3471303.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'starters',
        name: 'Cashew Queso + Seasonal Vegetables + Cassava Flour Chips',
        imageUrl: 'https://images.pexels.com/photos/3471322/pexels-photo-3471322.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'starters',
        name: 'Whipped Ricotta + Chorizo + Seasonal Vegetables + Local Sourdough',
        imageUrl: 'https://images.pexels.com/photos/1829160/pexels-photo-1829160.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'starters',
        name: 'Baked Cauliflower Hummus + Lamb + Seasonal Vegetables',
        imageUrl: 'https://images.pexels.com/photos/3471366/pexels-photo-3471366.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'starters',
        name: 'Green Sauce + Almond Flour Crust Pizza',
        imageUrl: 'https://images.pexels.com/photos/3471388/pexels-photo-3471388.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'entrees',
        name: 'Porter Road Tri-Tip Chimichurri',
        imageUrl: 'https://images.pexels.com/photos/3193296/pexels-photo-3193296.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'entrees',
        name: 'Kale Sauce Linguine + Pecorino',
        imageUrl: 'https://images.pexels.com/photos/3193291/pexels-photo-3193291.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'entrees',
        name: 'Whole Roasted Salmon + Lemon + Dill',
        imageUrl: 'https://images.pexels.com/photos/3471399/pexels-photo-3471399.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'entrees',
        name: 'Grass-fed Meatballs + GF Noodles',
        imageUrl: 'https://images.pexels.com/photos/3471238/pexels-photo-3471238.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'entrees',
        name: 'Lentil Meatballs + GF Noodles',
        imageUrl: 'https://images.pexels.com/photos/3378703/pexels-photo-3378703.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'Roasted Carrots + Carrot-Top Pesto',
        imageUrl: 'https://images.pexels.com/photos/1828191/pexels-photo-1828191.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'GF Cornbread Waffles + Honey Adobo Jam',
        imageUrl: 'https://images.pexels.com/photos/3471413/pexels-photo-3471413.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'Maple and Mustard Brussel Sprouts',
        imageUrl: 'https://images.pexels.com/photos/3471449/pexels-photo-3471449.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'Charred Cauliflower + Garbanzo + Lemon + Dijon',
        imageUrl: 'https://images.pexels.com/photos/3471232/pexels-photo-3471232.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'Roasted Beets + Cashew Pest',
        imageUrl: 'https://images.pexels.com/photos/3471418/pexels-photo-3471418.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'Yam + Garlic Mash + Coconut Milk',
        imageUrl: 'https://images.pexels.com/photos/3471239/pexels-photo-3471239.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    },
    {
        course: 'sides',
        name: 'Cast Iron Potatoes + Parsley Oil',
        imageUrl: 'https://images.pexels.com/photos/1829109/pexels-photo-1829109.jpeg?auto=compress&cs=tinysrgb&dpr=2&h=750&w=1260'
    }
]

export default springDinner;






























