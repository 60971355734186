import React from 'react';
import Select from 'react-select';

const selectStyles = {
    control: (styles, { isFocused, isSelected }) => ({ 
        ...styles,
        '&:hover': { 
            outline: 'none',
            border: '1px solid rgba(0, 0, 0, .1)',
            cursor: 'pointer'
        },
        width: '100%',
        paddingLeft: '0px',
        paddingRight: '0px',
        borderRadius: '5px',
        border: '1px solid rgba(0, 0, 0, .1)',
        height: '10.5px',
        // padding: '7.5px',
        marginBottom: '22px',
        boxShadow: isFocused ? 'none' : 'none',
        borderColor: isFocused ? 'rgba(0, 0, 0, .1)' : 'rgba(0, 0, 0, .1)',
        outline: 'none !important',
        fontSize: '16px',
        fontWeight: '300'
    }),
    placeholder: styles => ({ ...styles,
        color: 'rgba(0, 0, 0, 0.3)'
    }),
    option: (styles, { isSelected }) => ({ ...styles,
        backgroundColor: isSelected ? '#EF4C49' : 'white',
        color: isSelected ? 'white' : 'black',
        '&:hover': { 
            backgroundColor: 'rgba(0,0,0,0.2)',
            color: 'black',
            cursor: 'pointer'
        }
    })
}

const SelectField = ({ value, onChange, options, placeholder, label, isSearchable }) => {
    return (<>
        <Select
            defaultValue={label === '' ? { value: '', label: placeholder } : { value: value, label: label }}
            styles={selectStyles}
            options={options} 
            onChange={onChange}
            isSearchable={isSearchable}
            placeholder={placeholder}
        />
    </>)
}

export default SelectField;