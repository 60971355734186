import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import Amplify, { Auth } from 'aws-amplify';
import awsmobile from '../../../aws-exports'

import * as actions from '../../../actions';

// Styles
import './dashboard.css';

// Images
import Logo from '../../../images/logo.png'; 

// Components
import Reservation from './Reservation'

var _ = require('lodash');


Amplify.configure(awsmobile);

class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            confirmedReservations: null,
            unconfirmedReservations: null,
            fetching: true
        }
    }
    componentDidMount() {
        Auth.currentAuthenticatedUser()
            .then(user => {
                console.log(user);
                return this.props.hydrateUser(user.attributes.sub, 'chef')
                    .then(() => {
                        this.props.getReservations()
                            .then(resp => {
                                let confirmedReservations = _.filter(resp.reservations, { 'isConfirmed' : true });
                                let unconfirmedReservations = _.filter(resp.reservations, { 'isConfirmed': false });
                                this.setState({
                                    confirmedReservations,
                                    unconfirmedReservations
                                }, () => this.toggleFetching())
                            })
                    })
                
            })
            .catch(error => {
                console.log(error);
                alert('Please Login.');
                return this.props.history.push('/chefs/login')
            })
    }

    toggleFetching() {
        this.setState({ fetching: !this.state.fetching })
    }

    renderContent() {
        if (!this.state.fetching) {
            const { name } = this.props.user;
            return (<>
            <Row center="xs">
                <Col xs={12} md={8}>
                    <h2 style={{ marginTop: 0 }}>Hi, {name}!</h2>
                </Col>
            </Row>
            </>)
        } else {
            return (<>
            <Row center="xs">
                <Col xs={12} md={8}>
                    <h3>Loading...</h3>
                </Col>
            </Row>
            </>)
        }
    }

    renderReservations() {
        if (!this.state.fetching) {
            if (this.state.confirmedReservations.length !== 0) {
                return this.state.confirmedReservations.map((reservation, index) => {
                    return <Reservation key={index} reservation={reservation} signupChef={this.signupChef} removeChef={this.removeChef} />
                }) 
            } else {
                return <p>No Pending Reservations</p>
            }
        } else {
            return;
        }
    }

    render() {      
        return (
            <div className="dashboard-body">
                <div className="dashboard-card">
                    <Grid fluid>
                        <Row center="xs" className="dashboard-card-header">
                            <Col md={2} />
                            <Col xs={12} md={8}>
                                <img src={Logo} alt="logo" className="dashboard-card-logo" />
                            </Col>
                            <Col md={2} />
                        </Row>
                        {this.renderContent()}
                        <Row center="xs">
                            <Col xs={12} md={4}>
                                <h3 style={{ marginTop: 0 }}>Open Reservations</h3>
                            </Col>
                        </Row>
                        <Row center="xs">
                            <Col xs={12} md={12}>
                                {this.renderReservations()}
                            </Col>
                        </Row>
                    </Grid>
                </div>
            </div>
        )
    }
}

function mapStateToProps (state) {
    return {
        user: state.auth,
        reservations: state.reservations
    }
}

export default withRouter(connect(mapStateToProps, actions)(Dashboard))