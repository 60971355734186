import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import PrimaryButton from '../../components/PrimaryButton';

class Instructions extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delivery: this.props.delivery
        }
    }

    componenDidMount() {
    }
    render() {
        return (<Grid fluid>
            <Row center="xs">
                <Col xs={12}>
                    <h3 className="delivery__instructions__header" style={{ fontWeight: `400` }}>the <span style={{ fontStyle: 'italic', fontWeight: `900` }}>Family</span> meal</h3>
                </Col>
            </Row>
            <Row>
                <Col xs={12} md={5} mdOffset={1}>
                    <div className="delivery__instructions__one">
                        <p style={{ textDecoration: 'underline' }}>
                            We will continue to provide the community with wholesome, immunity boosting foods without sacrificing your safety or health. Each month we will be featuring a meal built around our local and national partners. 
                            <br/>
                            <br />
                            One meal feeds families of 4-8 with leftovers and even dessert! <br/>
	                        It’s easy -- you order...we deliver,  <span style={{ color: `rgb(232, 206, 124)` }}>you reheat and enjoy!</span>
                        </p>
                    </div>
                </Col>
                <Col xs={12} md={5}>
                    <div className="delivery__instructions__two">
                        <p>
                            August: Porter Road Burgers or Impossible Burgers + Siete Chips & Guac + Potato Salad + Secret Sauces and Cookies
                        </p>
                    </div>
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={5}>
                    <div className="delivery__instructions__three">
                        <p>
                        One meal starts at $150 and includes... Snack, sides, salads, entree and cookies
                        <br />
                        <br /> 
                        Orders can be made for delivery Monday-Friday. ‘Day of’ orders should be placed no later than 11PM on the previous day.
                        </p>
                    </div>
                </Col>
            </Row>
            {/* <Row center="xs" >
                <Col xs={12} md={3}>
                    <div className="delivery__instructions__four">
                        <p style={{ fontWeight: `900`, marginBottom: 0 }}>
                            it's $200
                        </p>
                    </div>
                </Col>
            </Row> */}
            {/* <Row center="xs">
                <Col xs={12} md={7}>
                    <div className="delivery__instructions__five">
                        <p style={{ fontStyle: `italic` }}>
                            Entree, salad, side, cookies.  All allergies and dietary needs.
                        </p>
                    </div>
                </Col>
            </Row> */}
            <Row center="xs">
                <Col xs={12} md={3}>
                    <PrimaryButton onClick={() => this.props.switchStep('one', this.state.delivery)} label="Next" disabled={false} />
                </Col>
            </Row>
        </Grid>)
    }
}

export default Instructions;