import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-flexbox-grid'; 

import * as actions from '../../../actions';

// Components
import PrimaryButton from '../../components/PrimaryButton';

class FilledSlot extends Component {
    constructor(props) {
        super(props);
        this.state = {
            chef: null,
            fetching: true,
            isCurrentUser: null
        }
    }

    componentDidMount() {
        this.props.getExternalChef(this.props.chef.chef)
            .then(resp => {
                let isCurrentUser;
                if (resp.chef.id === this.props.state.auth.id) {
                    isCurrentUser = true;
                } else {
                    isCurrentUser = false;
                }
                this.setState({ 
                    chef: resp.chef,
                    isCurrentUser: isCurrentUser 
                }, () => this.toggleFetching())
            })
    }

    toggleFetching() { 
        this.setState({ fetching: !this.state.fetching })
    }

    renderContent() {
        if (!this.state.fetching) {
            const { name } = this.state.chef;
            return (<Row middle="xs">
                <Col xs={12} md={8}>
                    { this.state.isCurrentUser ? 
                        <p className="slot-text">You</p>
                        : <p className="slot-text not-current">Chef: {name}</p>
                    }
                </Col>
                <Col xs={12} md={4}>
                    { this.state.isCurrentUser ? <PrimaryButton onClick={() => this.props.removeChef(this.state.chef)} label="Delete" /> : null }
                </Col>
            </Row>)
        } else {
            return (<Row>
                <p>Loading...</p>
            </Row>)
        }
    }

    render() {
        return (
            <div className="slot-container">
                {this.renderContent()}
            </div> 
        )
    }
}

function mapStateToProps(state) {
    return {
        state
    }
}

export default connect(mapStateToProps, actions)(FilledSlot);