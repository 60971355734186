import React from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import SquarePaymentForm, {
    CreditCardNumberInput,
    CreditCardExpirationDateInput,
    CreditCardPostalCodeInput,
    CreditCardCVVInput,
    CreditCardSubmitButton
  } from 'react-square-payment-form'
import { v4 as uuidv4 } from 'uuid';
import uniqid from 'uniqid';


// import 'react-square-payment-form/lib/default.css'

import * as actions from '../../actions';

import PrimaryButton from '../components/PrimaryButton';
import InputField from '../components/InputField';
import './SquarePaymentForm.css';
import '../hosts/reservation-confirmation/reservation-confirmation.css'

const nanoid = require('nanoid');

class DeliveryPaymentForm extends React.Component {

    constructor(props) {
      super(props)
      this.state = {
        errorMessages: [],
        buttonEnabled: true,
        discountCode: ''
      }
      this.createVerificationDetails = this.createVerificationDetails.bind(this);
      this.handleDiscountCodeChange = this.handleDiscountCodeChange.bind(this);
    }

    componentDidMount() {
        this.setState({
            delivery: this.props.delivery,
            locationZip: this.props.zip,
            city: this.props.city,
            streetAddress: this.props.streetAddress
        })
    }

    handleDiscountCodeChange(event) {
        this.setState({ discountCode: event.target.value }, () => {
            if (this.state.discountCode === "Placemat2020") {
                this.setState({
                    discountApplied: true,
                    discountMessage: "Valid Discount Code"
                })
            } else {
                this.setState({
                    discountApplied: false
                })
            }
        })
    }

    toggleButton() {
        if (this.state.discountCode === "Placemat2020") {
            this.setState({ loading: true }, () => {
                let uniqueId = nanoid();
                this.props.submitDelivery(uniqueId)
                this.setState({ loading: false })
            })
        }
        this.setState({ buttonEnabled: false })
    }
  
    cardNonceResponseReceived = (errors, nonce, cardData, buyerVerificationToken) => {
        this.setState({ loading: true }, () => {
            if (errors) {
                console.log(errors);
                this.setState({ errorMessages: errors.map(error => error.message), loading: false, buttonEnabled: true })
                return
            }   
            this.setState({ 
                errorMessages: [],
                nonce: nonce,
                buyerVerificationToken: buyerVerificationToken,
                loading: false 
            }, () => {
                let uniqueId = nanoid();
                console.log(uniqueId)
                let charge = {
                    "idempotency_key": `${uniqueId}`,
                    "token": buyerVerificationToken,
                    "amount_money": {
                      "amount": 5000,
                      "currency": "USD"
                    },
                    "card_nonce": nonce,
                    "reference_id": `Deposit on Delivery ${uniqueId}. Placed by ${this.props.delivery.name}`,
                    "note": `Deposit received from ${this.props.delivery.name}.`,
                    "customer_id": `${uniqueId}`,
                    "delay_capture": false
                }
                return this.props.makeCharge(charge)
                .then(resp => {
                    console.log(resp);
                    if (resp.status === 200) {
                        // Update reservation
                        this.setState({ loading: false }, () => {
                                this.props.submitDelivery(uniqueId)
                            })
                        } else {
                            this.setState({ loading: false, buttonEnabled: true }, () => {
                                alert("Something went wrong on our end, please try again later.")
                            })
                        }
                    })
            })
        })
    }
  
    createVerificationDetails() {
      return {
        amount: '5000.00',
        currencyCode: "USD",
        intent: "CHARGE",
        billingContact: {
            familyName: this.state.delivery.name,
            givenName: this.state.delivery.name,
            email: this.state.delivery.email,
            country: "US",
            city: this.state.city,
            addressLines: [this.state.streetAddress],
            postalCode: this.state.locationZip,
            phone: "020 7946 0532"
          }
      }
    }
  
    render() {
      return (<Grid fluid>
      <div className="disable-overlay" style={{ visibility: !this.state.loading ? 'hidden' : 'visible' }}><h1 className="disable-overlay-loading">Loading!!</h1></div>
        <div>
            <SquarePaymentForm
            sandbox={true}
            applicationId={'sandbox-sq0idb-sXnyomW4NX02mfH2MOj0cw'}
            locationId={'DTSY587NP21PR'}
            // applicationId={'sq0idp-MEOEU9aFEJ7gSwTaz6mVTw'}
            // locationId={'6RDQPNAT0YSDC'}
            cardNonceResponseReceived={this.cardNonceResponseReceived}
            createVerificationDetails={this.createVerificationDetails}
            inputStyles={[ { 
                fontSize: '16px', 
                fontFamily: 'Helvetica Neue', 
                padding: '0px', 
                color: '#373F4A', 
                backgroundColor: 'transparent',
                lineHeight: '24px', 
                placeholderColor: '#CCC', 
                _webkitFontSmoothing: 'antialiased', 
                _mozOsxFontSmoothing: 'grayscale' 
            }, ]}
            >
                <fieldset className="sq-fieldset">
                <Row className="credit-card-wrapper">
                    <Col xs={12} md={12}>
                        <div className="credit-card-input-field">
                            <CreditCardNumberInput label=""/>
                        </div>
                    </Col>
                    <Col xs={12} md={4}>
                    <div className="credit-card-input-field">
                        <CreditCardExpirationDateInput label="" className="credit-card-input-field" />
                    </div>
                    </Col>
                    <Col xs={12} md={4}>
                    <div className="credit-card-input-field">
                        <CreditCardPostalCodeInput label="" className="credit-card-input-field" />
                    </div>
                    </Col>
                    <Col xs={12} md={4}>
                    <div className="credit-card-input-field">
                        <CreditCardCVVInput label="" className="credit-card-input-field" />
                    </div>
                    </Col>
                </Row>
                </fieldset>

                <Row center="xs">
                    <Col xs={12} md={6}>
                        <InputField placeholder="Discount Code" value={this.state.discountCode} onChange={this.handleDiscountCodeChange} type="text" />
                    </Col>
                </Row>
                { this.state.discountApplied ? 
                    <Row center="xs">
                        <Col xs={12} md={6}>
                            <p>{this.state.discountMessage}</p>
                        </Col>
                    </Row>
                : '' }


                <Row center="xs" style={{ width: `125%`, marginLeft: `-12.5%` }}>
                    <Col xs={12} md={12}>
                        <p>Your $50 deposit is to hold + confirm reservation date, which is applied to the final bill. Placemat does not store credit card information. This is a single authorization charge made by Placemat, LLC. If your reservation is cancelled more than 5 days prior to reservation date, Placemat will refund your deposit.</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                      <PrimaryButton onClick={() => this.props.switchStep('three', this.props.delivery)} label="Back" />
                    </Col>
                    <Col xs={12} md={6}>
                            <div className="square-payment-form-card-submit" onClick={() => this.toggleButton()}>
                                { this.state.buttonEnabled ?
                                    <CreditCardSubmitButton >
                                       <p>Next</p>
                                    </CreditCardSubmitButton>
                                    : <p>Processing...</p>
                                }
                            </div>
                    </Col>
                </Row>
            </SquarePaymentForm>
  
            <div className="sq-error-message">
                {this.state.errorMessages.map(errorMessage =>
                    <li key={`sq-error-${errorMessage}`}>{errorMessage}</li>
                )}
            </div>
  
        </div>
        </Grid>
      )
    }
    
  }

  function mapStateToProps(state) { return state }
  export default connect(mapStateToProps, actions)(DeliveryPaymentForm);