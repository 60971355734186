// Dietary Restrictions
import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import PrimaryButton from '../../components/PrimaryButton';
import InputField from '../../components/InputField';

// Cohost Email
class ReservationFive extends Component {
    constructor(props) {
        super(props);
        this.state = {
            referral: this.props.reservation.referral || '',
            giftCode: this.props.reservation.giftCode || '',
            isButtonDisabled: true
        }

        this.handleReferralChange = this.handleReferralChange.bind(this);
        this.handleGiftCodeChange = this.handleGiftCodeChange.bind(this);
        this.handleButtonEnabled = this.handleButtonEnabled.bind(this);
    }

    handleStepChange(step) { 
        let stepFiveReservation = {
            // PART OF THIS STEP
            referral: this.state.referral,
            giftCode: this.state.giftCode,
            // NOT PART OF THIS STEP
            name: this.props.reservation.name,
            email: this.props.reservation.email,
            phone: this.props.reservation.phone,
            reservationDate: this.props.reservation.reservationDate,
            zipCode: this.props.reservation.zipCode,
            reservationSize: this.props.reservation.reservationSize,
            partySize: this.props.reservation.partySize,
            cohostName: this.props.reservation.cohostName,
            cohostEmail: this.props.reservation.cohostEmail,
            dietaryRestrictions: this.props.reservation.dietaryRestrictions
        }

        this.props.toggleStep(step, stepFiveReservation)
    }

    handleButtonEnabled() { 
        if (this.state.referral !== "") { this.setState({ isButtonDisabled: false }) }
        else { this.setState({ isButtonDisabled: true })}
    }
    handleReferralChange(event) { this.setState({ referral: event.target.value }, () => this.handleButtonEnabled())}
    handleGiftCodeChange(event) { 
        this.setState({ giftCode: event.target.value.split('-')[1] })
    }
    render() {
        return (<Grid fluid>
            <Row center="xs">
                <Col xs={12} md={8}>
                    <p style={{ fontSize: `32px`, marginTop: 0, marginBottom: 0 }}>Step Four</p>
                </Col>
            </Row>
            {/* FORM FIELDS */}
            <Row center="xs">
                <Col xs={12} md={8}>
                    <p>How did you hear about Placemat?</p>
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={5}>
                    <InputField placeholder="Referral" value={this.state.referral} onChange={this.handleReferralChange} type="text" />
                </Col>
            </Row>

            <Row center="xs">
                <Col xs={12} md={8}>
                    <p>Have a Gift Code?</p>
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={8}>
                    <InputField placeholder="Code" value={`GIFT-${this.state.giftCode}`} onChange={this.handleGiftCodeChange} type="text" />
                </Col>
            </Row>
            {/* END FORM FIELDS */}

            <Row around="xs">
                <Col md={3} />
                <Col xs={12} md={3}>
                    <PrimaryButton onClick={() => this.handleStepChange('four')} label="Back" />
                </Col>
                <Col xs={12} md={3}>
                    <PrimaryButton 
                        onClick={() => this.handleStepChange('confirm')} 
                        label="Next" 
                        disabled={this.state.isButtonDisabled}
                    />
                </Col>
                <Col md={3} />
            </Row>
        </Grid>)
    }
}

export default ReservationFive;