import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import PrimaryButton from '../../components/PrimaryButton';
import InputField from '../../components/InputField';

// ======================== //
// ===== NAME & EMAIL ===== //
// ======================== //
class ApplicationOne extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstName: this.props.application.firstName || '',
            lastName: this.props.application.lastName || '',
            email: this.props.application.email || '',
            showEmailError: false,
            ErrorMessage: '',
            isButtonDisabled: true
        }

        this.handleFirstNameChange = this.handleFirstNameChange.bind(this);        
        this.handleLastNameChange = this.handleLastNameChange.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);        
    }

    componentDidMount() {
        this.handleButtonEnable();
    }

    handleFirstNameChange(event) {
        this.setState({ firstName: event.target.value }, () => this.handleButtonEnable())
    }

    handleLastNameChange(event) {
        this.setState({ lastName: event.target.value }, () => this.handleButtonEnable())
    }

    handleEmailChange(event) {
        if (this.state.showEmailError) {
            this.setState({ })
        }
        this.setState({  email: event.target.value }, () => this.handleButtonEnable())
    }

    handleButtonEnable() {
        if (this.state.firstName !== '' && this.state.lastName !== '' && this.state.email !== '' ) {
            this.setState({ isButtonDisabled: false })
        } else {
            this.setState({ isButtonDisabled: true })
        }
    }

    handleStepChange() { 
        let stepOneApplication = {
            // PART OF THIS STEP
            firstName: this.state.firstName,
            lastName: this.state.lastName,
            email: this.state.email,
            // NOT PART OF THIS STEP
            city: this.props.application.city,
            state: this.props.application.state,
            zip: this.props.application.zip,
            travelAvailability: this.props.application.travelAvailability,
            additionalInfo: this.props.application.additionalInfo
        }

        var re = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
        if (!re.test(String(stepOneApplication.email).toLowerCase())) {
            this.setState({ 
                showEmailError: true,
                emailErrorMessage: 'Please enter a valid email.'  
            })
        } else {
            this.props.toggleStep('two', stepOneApplication)
        }
    }

    render() {
        return (
            <Grid fluid>
                <Row around="xs">
                    <h3>Step One</h3>
                </Row>
                <Row around="xs">
                    <p>*All fields are required!</p>
                </Row>
                <Row>
                    <Col xs={12} md={3} mdOffset={3}>
                        <InputField
                            placeholder="First Name"
                            value={this.state.firstName}
                            onChange={this.handleFirstNameChange}
                        />
                    </Col>
                    <Col xs={12} md={3}>
                        <InputField
                            placeholder="Last Name"
                            value={this.state.lastName}
                            onChange={this.handleLastNameChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} mdOffset={3}>
                        <InputField
                            placeholder="Email"
                            value={this.state.email}
                            onChange={this.handleEmailChange}
                            showError={this.state.showEmailError}
                            errorMessage={this.state.emailErrorMessage}
                        />
                    </Col>
                </Row>
                <Row around="xs">
                    <Col xs={12} md={6}>
                        <PrimaryButton 
                            onClick={() => this.handleStepChange()} 
                            label="Next" 
                            disabled={this.state.isButtonDisabled}
                        />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default ApplicationOne;