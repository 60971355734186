import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import InputField from '../../components/InputField';
import PrimaryButton from '../../components/PrimaryButton';

class ConfirmPassword extends Component {
    constructor(props) {
        super(props);
        this.state = {
            newPassword: '',
            showNewPasswordError: false,
            newPasswordError: '',
            isButtonDisabled: true
        }

        this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this);
        this.handleConfirmPasswordSubmit = this.handleConfirmPasswordSubmit.bind(this);
    }

    handleNewPasswordChange(event) {
        this.setState({ newPassword: event.target.value }, () => this.handleButtonEnable())
    }

    handleButtonEnable() {
        if (this.state.newPassword !== '') {
            this.setState({ isButtonDisabled: false })
        } else {
            this.setState({ isButtonDisabled: true })
        }
    }

    handleConfirmPasswordSubmit() {
        return this.props.confirmPassword(this.state.newPassword)
    }

    render() {
        return (<Grid fluid>
            <Row around="xs">
                <Col xs={12} md={3}>
                    <InputField
                        value={this.state.newPassword}
                        onChange={this.handleNewPasswordChange}
                        placeholder="New Password"
                        type="password"
                        showError={this.state.showNewPasswordError}
                        errorMessage={this.state.newPasswordError}
                    />
                </Col>
            </Row>
            <Row around="xs">
                <Col xs={12} md={3}>
                    <PrimaryButton 
                        onClick={this.handleConfirmPasswordSubmit}
                        label="Confirm Password"
                        disabled={this.state.isButtonDisabled}
                    />
                </Col>
            </Row>
        </Grid>)
    }
}

export default ConfirmPassword;