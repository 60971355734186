import React from 'react';
import { Row, Col } from 'react-flexbox-grid'; 

// Components
import PrimaryButton from '../../components/PrimaryButton';

const EmptySlot = ({ signupChef, isCurrentChefRegistered }) => {
    if (isCurrentChefRegistered) {
        return (
            <div className="slot-container empty">
                <Row middle="xs">
                    <Col xs={12} md={12}>
                        <p className="slot-text">Waiting for more chefs</p>
                    </Col>
                </Row>
            </div>
        )
    } else {
        return (
            <div className="slot-container">
                <Row right="xs" middle="xs">
                    <Col xs={12} md={12}>
                        <PrimaryButton label="Take Slot" onClick={signupChef} />
                    </Col>
                </Row>
            </div> 
        )
    }
}

export default EmptySlot;