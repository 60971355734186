// Referral
// Gift Code
import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import PrimaryButton from '../../components/PrimaryButton';

// Dietary Restrictions
class ReservationFour extends Component {
    constructor(props) {
        super(props);
        this.state = {
            dietaryRestrictions: this.props.reservation.dietaryRestrictions || [],
            // not working
            none: this.props.reservation.dietaryRestrictions[0].required || false,
            nut: this.props.reservation.dietaryRestrictions[1].required || false,
            vegan: this.props.reservation.dietaryRestrictions[2].required || false,
            keto: this.props.reservation.dietaryRestrictions[3].required || false,
            shellfish: this.props.reservation.dietaryRestrictions[4].required || false,
            peanut: this.props.reservation.dietaryRestrictions[5].required || false,
            vegetarian: this.props.reservation.dietaryRestrictions[6].required || false,
            gluten: this.props.reservation.dietaryRestrictions[7].required || false,
            paleo: this.props.reservation.dietaryRestrictions[8].required || false,
            soy: this.props.reservation.dietaryRestrictions[9].required || false,
            whole30: this.props.reservation.dietaryRestrictions[10].required || false,
            grain: this.props.reservation.dietaryRestrictions[11].required || false,
            dairy: this.props.reservation.dietaryRestrictions[12].required || false,
            other: this.props.reservation.dietaryRestrictions[13].type || "",
            isButtonDisabled: true
        }

    }

    handleNutChange(event) { this.setState({ nut: event.target.checked }, () => { this.handleButtonEnabled() }); }
    handleVeganChange(event) { this.setState({ vegan: event.target.checked }, () => { this.handleButtonEnabled() }) }
    handleKetoChange(event) { this.setState({ keto: event.target.checked }, () => { this.handleButtonEnabled() }); }
    handleShellfishChange(event) { this.setState({ shellfish: event.target.checked }, () => { this.handleButtonEnabled() }); }
    handlePeanutChange(event) { this.setState({ peanut: event.target.checked }, () => { this.handleButtonEnabled() }); }
    handleVegetarianChange(event) { this.setState({ vegetarian: event.target.checked }, () => { this.handleButtonEnabled() }); }
    handleGlutenChange(event) { this.setState({ gluten: event.target.checked }, () => { this.handleButtonEnabled() }); }
    handlePaleoChange(event) { this.setState({ paleo: event.target.checked }, () => { this.handleButtonEnabled() }); }
    handleSoyChange(event) { this.setState({ soy: event.target.checked }, () => { this.handleButtonEnabled() }); }
    handleWhole30Change(event) { this.setState({ whole30: event.target.checked }, () => { this.handleButtonEnabled() }); }
    handleGrainChange(event) { this.setState({ grain: event.target.checked }, () => { this.handleButtonEnabled() }); }
    handleDairyChange(event) { this.setState({ dairy: event.target.checked }, () => { this.handleButtonEnabled() }); }
    handleNoneChange(event) { this.setState({ none: event.target.checked }, () => { this.handleButtonEnabled() }); }
    handleOtherChange(event) { 
        if (event.target.value === "") {
            this.setState({ other: "", otherChecked: false }, () => this.handleButtonEnabled())
        } else {
            this.setState({ other: event.target.value, otherChecked: true }, () => this.handleButtonEnabled())
        }
    }

    handleButtonEnabled() { 
        if (!this.state.nut && !this.state.vegan && !this.state.keto && !this.state.shellfish && !this.state.peanut && !this.state.vegetarian && !this.state.gluten && !this.state.paleo && !this.state.soy && !this.state.whole30 && !this.state.grain && !this.state.dairy && !this.state.none && !this.state.otherChecked) {
            this.setState({ isButtonDisabled: true })
        } else {
            this.setState({ isButtonDisabled: false })
        }
    }

    handleStepChange(step) { 
        let populateDietaryRestrictions = [];
        populateDietaryRestrictions.push( 
            { "type": "none", "required": this.state.none },
           { "type": "nut", "required": this.state.nut }, 
           { "type": "vegan", "required": this.state.vegan },
           { "type": "keto", "required": this.state.keto },
           { "type": "shellfish", "required": this.state.shellfish },
           { "type": "peanut", "required": this.state.peanut },
           { "type": "vegetarian", "required": this.state.vegetarian },
           { "type": "gluten", "required": this.state.gluten },
           { "type": "paleo", "required": this.state.paleo },
           { "type": "soy", "required": this.state.soy },
           { "type": "whole30", "required": this.state.whole30 },
           { "type": "grain", "required": this.state.grain },
           { "type": "dairy", "required": this.state.dairy },
           { "type": this.state.other || "other", "required": this.state.otherChecked }
        )

        this.setState({ dietaryRestrictions: populateDietaryRestrictions }, () => {
            let stepFourReservation = {
                // PART OF THIS STEP
                dietaryRestrictions: this.state.dietaryRestrictions,
                // NOT PART OF THIS STEP
                name: this.props.reservation.name,
                email: this.props.reservation.email,
                phone: this.props.reservation.phone,
                reservationDate: this.props.reservation.reservationDate,
                partySize: this.props.reservation.partySize,
                zipCode: this.props.reservation.zipCode,
                reservationSize: this.props.reservation.reservationSize,
                cohostName: this.props.reservation.cohostName,
                cohostEmail: this.props.reservation.cohostEmail,
                referral: this.props.reservation.referral,
                giftCode: this.props.reservation.giftCode
            }
    
            this.props.toggleStep(step, stepFourReservation)
        })
    }

    render() {
        return (<Grid fluid>
            <Row center="xs">
                <Col xs={12} md={8}>
                    <p style={{ fontSize: `32px`, marginTop: 0, marginBottom: `1rem` }}>Step Three</p>
                </Col>
            </Row>
            <Row around="xs" center="xs" style={{ marginBottom: `1rem` }}>
                <Col xs={12} md={8}>
                    <p className="dietary-preferences-text-reg">
                    <span style={{ fontWeight: `bold` }}>Accommodating food allergies + dietary needs is our priority.</span><br />
                    Reservations made with restrictions will thoughtfully be considered allowing everyone at the table to eat.<br />  
                    <span style={{ fontStyle: 'italic' }}>Everyone eats with Placemat.</span>
                    </p>
                </Col>
            </Row>
            {/* FORM FIELDS */}
                <Row center="xs">
                    <Col xs={12} md={8}>
                        <div className="dietary-preferences-card">
                        <Row>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.none} value={this.state.none} onChange={this.handleNoneChange.bind(this)} />
                                        <p>None</p>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.nut} value={this.state.nut} onChange={this.handleNutChange.bind(this)} />
                                        <p>Nut</p>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.peanut} value={this.state.peanut} onChange={this.handlePeanutChange.bind(this)} />
                                        <p>Peanut</p>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.soy} value={this.state.soy} onChange={this.handleSoyChange.bind(this)} />
                                        <p>Soy</p>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.vegan} value={this.state.vegan} onChange={this.handleVeganChange.bind(this)} />
                                        <p>Vegan</p>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.vegetarian} value={this.state.vegetarian} onChange={this.handleVegetarianChange.bind(this)} />
                                        <p>Vegetarian</p>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.whole30} value={this.state.whole30} onChange={this.handleWhole30Change.bind(this)} />
                                        <p>Whole30</p>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.keto} value={this.state.keto} onChange={this.handleKetoChange.bind(this)} />
                                        <p>Keto</p>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.gluten} value={this.state.gluten} onChange={this.handleGlutenChange.bind(this)} />
                                        <p>Gluten</p>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.grain} value={this.state.grain} onChange={this.handleGrainChange.bind(this)} />
                                        <p>Grain</p>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.shellfish} value={this.state.shellfish} onChange={this.handleShellfishChange.bind(this)} />
                                        <p>Shellfish</p>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.paleo} value={this.state.paleo} onChange={this.handlePaleoChange.bind(this)} />
                                        <p>Paleo</p>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" checked={this.state.dairy} value={this.state.dairy} onChange={this.handleDairyChange.bind(this)} />
                                        <p>Dairy</p>
                                    </Row>
                                </div>
                            </Col>
                            <Col xs={12} md={4}>
                                <div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-input" type="text" value={this.state.other} placeholder="Other" onChange={this.handleOtherChange.bind(this)} />
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        </div>
                    </Col>
                </Row>
            {/* END FORM FIELDS */}

            <Row around="xs">
                <Col md={3} />
                <Col xs={12} md={3}>
                    <PrimaryButton onClick={() => this.handleStepChange('three')} label="Back" />
                </Col>
                <Col xs={12} md={3}>
                    <PrimaryButton 
                        onClick={() => this.handleStepChange('five')} 
                        label="Next" 
                        disabled={this.state.isButtonDisabled}
                    />
                </Col>
                <Col md={3} />
            </Row>
        </Grid>)
    }
}

export default ReservationFour;