import apisauce from 'apisauce';
import Amplify, { Auth } from 'aws-amplify';
import awsmobile from '../aws-exports';

import {
    HYDRATE_USER,
    GET_RESERVATIONS
} from './types'

Amplify.configure(awsmobile);

const _ = require('ramda');

// =============== //
// ===== API ===== //
// =============== // 
const create = () => {
    const api = apisauce.create({
        baseURL: 'https://gjocqucvnh.execute-api.us-east-1.amazonaws.com/prod/',
        // baseURL: 'http://localhost:4001/',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json' 
        },
        timeout: 10000
    })

    // ========================================== //
    // ===== ADMIN AUTHENTICATION FUNCTIONS ===== //
    // ========================================== //
    const createAdminUser = async (adminUser) => {
        const resp = await api.post('admins/create', {
            ...adminUser
        })
        return resp.data.admin;
    }

    const getAdminUser = async (id) => {
        const resp = await api.get('admins/get/' + id)
        return resp.data.admin;
    }

    // ========================================= //
    // ===== CHEF AUTHENTICATION FUNCTIONS ===== //
    // ========================================= //
    const createChefUser = async (chefUser) => {
        const resp = await api.post('chefs/create', {
            ...chefUser
        })
        return resp.data.chef;
    }

    const getChefUser = async (id) => {
        const resp = await api.get('chefs/get/' + id)
        return resp.data.chef;
    }

    const getChefs = async () => {
        const resp = await api.get('chefs/get')
        return resp.data.chefs;
    }

    const updateChefUser = async (chef) => {
        console.log(chef);
        const resp = await api.post('chefs/update/' + chef.id, {
            ...chef
        })
        return resp.data.chef;
    }

    // ====================================== //
    // ===== CHEF APPLICATION FUNCTIONS ===== //
    // ====================================== //
    const createChefApplication = async (application) => {
        const resp = await api.post('chef-applications/create', {
            ...application
        })
        return resp.data.application;
    }

    const getChefApplication = async (id) => {
        const resp = await api.get('chef-applications/get/' + id)
        return resp.data.application;
    }
    
    const getChefApplications = async () => {
        const resp = await api.get('chef-applications/get')
        return resp.data.applications;
    }

    const acceptChefApplication = async (application) => {
        console.log("Pass along to server", application);
    }
    
    const deleteChefApplication = async (id) => {
        const resp = await api.post('chef-applications/delete', {
            id
        })
        return resp.data;
    }

    // ================================= //
    // ===== RESERVATION FUNCTIONS ===== //
    // ================================= //
    const createReservation = async (reservation) => {
        const resp = await api.post('reservations/createReservation', {
            ...reservation
        })
        return resp.data.reservation;
    }

    const getReservation = async (id) => {
        const resp = await api.get('reservations/getReservation/' + id)
        // console.log(resp)
        return resp.data.reservation;
    }

    const getReservations = async () => {
        console.log("Here");
        const resp = await api.get('reservations/getReservations')
        return resp.data.reservations;
    }

    const updateReservation = async (reservation) => {
        const resp = await api.post('reservations/update/' + reservation.id, {
            ...reservation
        })
        return resp.data.reservation;
    }

    // ============================= //
    // ===== PAYMENT FUNCTIONS ===== //
    // ============================= //
    const makeCharge = async (charge) => {
        const resp = await api.post('square/makeCharge', {
            ...charge
        })
        return resp;
    }

    // =========================== //
    // ===== GOOGLE CALENDAR ===== //
    // =========================== //
    const addToCalendar = async (event, id) => {
        const resp = await api.post('google/createEvent', {
            ...event,
            id
        })
        return resp;
    }

    const updateCalendarEvent = async (event, eventId, calId) => {
        const resp = await api.post('google/updateEvent', {
            eventId: eventId,
            ...event
        })
        return resp;
    }

    const getAllCalendarEvents = async () => {
        const resp = await api.get('google/getAllEvents')
        return resp;
    }

    // ====================== //
    // ===== DELIVERIES ===== //
    // ====================== //
    const createDelivery = async (delivery) => {
        const resp = await api.post('deliveries/createDelivery', { 
            ...delivery
        })
        return resp;
    }

    const getDelivery = async (id) => {
        const resp = await api.get('deliveries/getDelivery/' + id)
        return resp;
    }

   

    return {
        // Admin Authentication Functions
        createAdminUser,
        getAdminUser,
        // Chef Authentication Functions
        createChefUser,
        getChefUser,
        getChefs,
        updateChefUser,
        // Chef Application Functions
        createChefApplication,
        getChefApplication,
        getChefApplications,
        acceptChefApplication,
        deleteChefApplication,
        // Reservation Functions
        createReservation,
        getReservation,
        getReservations,
        updateReservation,
        // Payment Functions
        makeCharge,
        // Google Calendar Functions
        addToCalendar,
        updateCalendarEvent,
        getAllCalendarEvents,
        // Delivery Functions
        createDelivery,
        getDelivery,
    }
}

const api = create();
// =================== //
// ===== END API ===== //
// =================== //

// ========================= //
// ===== SENDGRID API ===== //
// ========================= //
const createSendgrid = () => {
    const sendgridApi = apisauce.create({
        // baseURL: 'http://localhost:4001/',
        baseURL: 'https://gjocqucvnh.execute-api.us-east-1.amazonaws.com/prod/',
        headers: { 
            'Accept': 'application/json',
            'Content-Type': 'application/json' 
        },
        timeout: 10000
    })

    const sendEmail = async (email) => {
        // console.log(email);
        const resp = await sendgridApi.post('emailing/sendEmail', {
            email
        })
        return resp; 
    }

    return {
        sendEmail
    }
}

const sendgridApi = createSendgrid();
// ============================= //
// ===== END SENDGRID API ===== //
// ============================= //

// =================================== //
// ===== AUTHENTICATION FUNCTION ===== //
// =================================== //
export const tryLogin = (creds) => dispatch => {
    return Auth.signIn(creds.email, creds.password)
        .then(user => {
            if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
                return {
                    code: 200,
                    message: "NEW_PASSWORD_REQUIRED",
                    user
                }
            } else {
                if (user.signInUserSession.accessToken.payload['cognito:groups']) {
                    switch (user.signInUserSession.accessToken.payload['cognito:groups'][0]) {
                        case ('admins'): 
                            return api.getAdminUser(user.attributes.sub)
                                .then(admin => {
                                    dispatch({ 
                                        type: HYDRATE_USER,
                                        payload: admin
                                    })
                                    return {
                                        code: 200,
                                        message: "SUCCESS",
                                        isAdmin: true,
                                        admin
                                    }
                                })
                        case ('chefs'):
                            return api.getChefUser(user.attributes.sub)
                                .then(chef => {
                                    dispatch({
                                        type: HYDRATE_USER,
                                        payload: chef
                                    })
                                    return {
                                        code: 200,
                                        message: "SUCCESS",
                                        isChef: true,
                                        chef
                                    }
                                })
                                .catch(err => {
                                    console.log(err);
                                })
                        default:
                            return {
                                code: 200,
                                message: "SUCCESS",
                                isAdmin: false,
                                isChef: false,
                                user
                            }
                    }
                } else {
                    return {
                        code: 200,
                        message: "SUCCESS",
                        isAdmin: false,
                        isChef: false,
                        user
                    }
                }     
            }
        })
        .catch(err => {
            console.log(err);
            return {
                code: 400,
                message: "ERROR",
                ...err
            }
        })
}

export const hydrateUser = (id, type) => dispatch => {
    switch (type) {
        case ('chef'):
            return api.getChefUser(id)
                .then(chef => {
                    dispatch({
                        type: HYDRATE_USER,
                        payload: chef
                    })
                    return {
                        code: 200,
                        message: "SUCCESS",
                        chef: chef
                    }
                })
        case ('admin'):
            return api.getAdminUser(id)
                .then(admin => {
                    dispatch({
                        type: HYDRATE_USER,
                        payload: admin
                    })
                    return {
                        code: 200,
                        message: "SUCCESS",
                        admin: admin
                    }
                })
        default:
            return;
    }
}

// ========================================== //
// ===== ADMIN AUTHENTICATION FUNCTIONS ===== //
// ========================================== //
export const tryConfirmPassword = (creds) => dispatch => {
    const { user, email, newPassword } = creds;
    console.log(creds);
    return Auth.completeNewPassword(user, newPassword)
        .then(user => {
            let newAdmin = {
                cognitoId: user.signInUserSession.idToken.payload.sub,
                email: email
            }

            return api.createAdminUser(newAdmin)
                .then(adminUser => {
                    dispatch({ 
                        type: HYDRATE_USER,
                        payload: adminUser
                    })

                    return {
                        code: 200,
                        message: "Success",
                        adminUser
                    }
                })

        })
}

// ========================================= //
// ===== CHEF AUTHENTICATION FUNCTIONS ===== //
// ========================================= //
export const createChef = (chef) => dispatch => {
    return api.createChefUser(chef)
        .then(resp => {
            return {
                code: 200,
                message: resp
            }
        })
}

export const confirmChef = (username, code, password) => dispatch => {
    return Auth.confirmSignUp(username, code)
        .then(data => {
            return {
                code: 200,
                message: data
            }
        })
        .catch(err => {
            console.log(err);
            return {
                code: 400,
                message: err
            }
        });
}

export const getChef = (id) => dispatch => {
    return api.getChefUser(id)
        .then(resp => {
            dispatch({
                type: HYDRATE_USER,
                payload: resp
            })

            return {
                code: 200,
                message: resp
            }
        })
}

export const getChefs = () => dispatch => {
    return api.getChefs()
        .then(resp => {
            return {
                code: 200,
                message: "Succes",
                body: resp
            }
        })
}

export const getExternalChef = (id) => dispatch => {
    return api.getChefUser(id)
        .then(resp => {
            return {
                code: 200,
                chef: resp
            }
        })
}

export const updateChef = (chef) => dispatch => {
    return api.updateChefUser(chef)
        .then(resp => {
            console.log(resp);
        })
}

// ====================================== //
// ===== CHEF APPLICATION FUNCTIONS ===== //
// ====================================== //
export const createChefApplication = (application) => dispatch => {
    return api.createChefApplication(application)
        .then(resp => {
            return {
                code: 200,
                application: resp
            }
        })
}

export const getChefApplication = (id) => dispatch => {
    return api.getChefApplication(id)
        .then(resp => {
            return {
                code: 200,
                application: resp
            }
        })
}

export const getChefApplications = () => dispatch => {
    return api.getChefApplications()
        .then(applications => {
            return {
                code: 200,
                applications: applications
            }
        })
}

export const acceptChefApplication = (application) => dispatch => {
    const { email, firstName, lastName, city, state, zip, travelAvailability, additionalInfo } = application;
    let username = application.email;
    let password = application.id.split('-')[0] + 'Aa1!';
    return Auth.signUp({
        username, password,
        attributes: { email: username }
    })
        .then(resp => {
            let newChef = {
                cognitoId: resp.userSub,
                email: email,
                firstName: firstName,
                lastName: lastName,
                city: city,
                state: state,
                zip: zip,
                travelAvailability: travelAvailability,
                additionalInfo: additionalInfo
            }
            return api.createChefUser(newChef)
                .then(chef => {
                    return api.deleteChefApplication(application.id)
                        .then(() => {
                            return {
                                code: 200,
                                message: "Success",
                                body: chef,
                                password: password
                            }
                        })
                })
        })
}

export const deleteChefApplication = (id) => dispatch => {
    return api.deleteChefApplication(id)
        .then(resp => {
            console.log(resp);
            return api.getReservations()
                .then(reservations => {
                    dispatch({
                        type: GET_RESERVATIONS,
                        payload: reservations
                    })
                    return resp;
                })
        })
}

export const signupForReservation = (chef, reservation) => dispatch => {
    // GET RESERVATION FROM DATABASE
    return api.getReservation(reservation.id)
        .then(reservation => {
            // CHECK IF RESERVATION HAS ANY CONFIRMED CHEFS
            // IF NOT, INITIALIZE EMPTY ARRAY ON RESERVATION OBJECT
            if (!reservation['confirmedChefs']) { 
                reservation['confirmedChefs'] = [] 
            }
            // ADD CHEF TO RESERVATIONS
            // INCLUDE: ID AND SIGNUP TIMESTAMP
            reservation['confirmedChefs'].push({
                signupDate: new Date(),
                chef: chef.id
            })
            // UPDATE RESERVATION ON DATABASE
            return api.updateReservation(reservation)
                .then(resReservation => {
                    // GET ALL RESERVATIONS FROM DATABASE
                    // *** THIS IS INEFFICIENT ***
                    return api.getReservations()
                        .then(reservations => {
                            // UPDATE RESERVATIONS LIST IN REDUX STORE
                            dispatch({
                                type: GET_RESERVATIONS,
                                payload: reservations
                            })
                            // CHECK IF CHEF HAS AN ACCEPTED RESERVATIONS
                            // IF NOT, INITIALIZE EMPTY ARRAY ON CHEF OBJECT
                            if (!chef['acceptedReservations']) { chef['acceptedReservations'] = [] }
                            // ADD RESERVATION TO CHEF
                            chef['acceptedReservations'].push(reservation.id);
                            // UPDATE CHEF ON DATABASE
                            return api.updateChefUser(chef)
                                .then(resChef => {
                                    // UPDATE CHEF IN REDUX STORE
                                    dispatch({
                                        type: HYDRATE_USER,
                                        payload: resChef
                                    })
                                    // RETURN SUCCESS MESSAGE
                                    return {
                                        code: 200,
                                        chef: resChef,
                                        reservation: resReservation
                                    }
                                })
                        })
                })
        })
}

export const removeFromReservation = (chef, reservation) => dispatch => {
    return api.getReservation(reservation.id)
        .then(reservation => {
            let filteredChefs = _.filter((confirmedChef => { return confirmedChef.chef !== chef.id }), reservation.confirmedChefs)
            let filteredReservations = _.filter((acceptedReservations => { return acceptedReservations !== reservation.id}), chef.acceptedReservations);
            reservation['confirmedChefs'] = filteredChefs;
            chef['acceptedReservations'] = filteredReservations;
            return api.updateReservation(reservation)
                .then(resReservation => {
                    return api.getReservations()
                        .then(reservations => {
                            dispatch({
                                type: GET_RESERVATIONS,
                                payload: reservations
                            })

                            return api.updateChefUser(chef)
                                .then(resChef => {
                                    dispatch({
                                        type: HYDRATE_USER,
                                        payload: resChef
                                    })

                                    return {
                                        code: 200,
                                        chef: resChef,
                                        reservation: resReservation
                                    }
                                })
                            
                        })
                })
        })
}

// ================================= //
// ===== RESERVATION FUNCTIONS ===== //
// ================================= //
export const createReservation = (reservation) => dispatch => {
    return api.createReservation(reservation)
        .then(resp => {
            return {
                code: 200,
                reservation: resp
            }
        })
}

export const getReservation = (id) => dispatch => {
    return api.getReservation(id)
        .then(resp => {
            return {
                code: 200,
                reservation: resp
            }
        })
}

export const getReservations = () => dispatch => {
    return api.getReservations()
        .then(reservations => {
            dispatch({
                type: GET_RESERVATIONS,
                payload: reservations
            })

            return {
                code: 200,
                reservations: reservations
            }
        })
}

export const updateReservation = (reservation) => dispatch => {
    return api.updateReservation(reservation)
        .then(resp => {
            return {
                code: 200,
                reservation: resp
            }
        })
}
export const confirmReservationMenu = (reservation, menu, location, time, timezone, mealType) => dispatch => {
    console.log(menu);
    reservation['menu'] = menu;
    reservation['reservationTime'] = time;
    reservation['timezone'] = timezone;
    reservation['streetAddress'] = location.streetAddress;
    reservation['city'] = location.city;
    reservation['state'] = location.state;
    reservation['unit'] = location.unit;
    reservation['isConfirmed'] = true;
    reservation['mealType'] = mealType;
    console.log(reservation);
    return api.updateReservation(reservation)
        .then(reservation => {
            return {
                code: 200,
                message: "Success!",
                body: reservation
            }
        })
    .catch(err => {
        return {
            code: 400,
            message: "Error",
            body: err || null
        }
    })
}

// ============================== //
// ===== SENDGRID FUNCTIONS ===== //
// ============================== //
export const sendEmail = (email) => dispatch => {
    console.log(email);
    return sendgridApi.sendEmail(email)
        .then(resp => {
            console.log(resp.data);
            return {
                status: 200,
                message: "Email sent"
            }
        })
}

// ================================= //
// ===== TRANSACTIONS - SQUARE ===== //
// ================================= //
export const makeCharge = (charge) => dispatch => {
    console.log(charge);
    return api.makeCharge(charge)
        .then(resp => {
            if (resp.status === 200) {
                return {
                    status: 200,
                    message: "Successfully charged card."
                }
            } else {
                return {
                    status: 400,
                    message: "Error"
                }
            }
        })
}

// =========================== //
// ===== GOOGLE CALENDAR ===== //
// =========================== //
export const addToCalendar = (event, id) => dispatch => {
    return api.addToCalendar(event, id)
        .then(resp => {
            console.log(resp);
            return resp.data.response.data;
        })
}

export const updateCalendarEvent = (event, eventId, calId) => dispatch => {
    return api.updateCalendarEvent(event, eventId, calId)
        .then(resp => {
            return resp;
        })
}

export const getAllCalendarEvents = () => dispatch => {
    return api.getAllCalendarEvents()
        .then(resp => {
            console.log(resp);
        })
}

// ====================== //
// ===== DELIVERIES ===== //
// ====================== //
export const createDelivery = (delivery) => dispatch => {
    if (delivery.dietaryRestrictions[13].type === "" || delivery.dietaryRestrictions[13].type === "other") {
        delivery.dietaryRestrictions[13].type = "N/A"
    }
    if (delivery.instructions === "") {
        delivery.instructions = "N/A"
    }
    return api.createDelivery(delivery)
        .then(resp => {
            return { 
                code: 200,
                message: "Success",
                body: resp 
            }
        })
    .catch(err => {
        return {
            code: 400,
            message: "Error",
            body: err
        }
    })
}

export const getDelivery = (id) => dispatch => {
    return api.getDelivery(id)
        .then(resp => {
            return {
                code: 200,
                message: "Success",
                body: resp
            }
        })
    .catch(err => {
        return {
            code: 400,
            message: "Error",
            body: err
        }
    })
}

