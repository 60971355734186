import React from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

import './application.css';

import PrimaryButton from '../../components/PrimaryButton';

const Application = ({ application, acceptApplication, denyApplication, contactApplicant }) => {
    const { firstName, lastName, city, state, zip, travelAvailability, additionalInfo } = application;
    return (<div className="application-container">
    <Grid fluid>
        <Row>
            <Col xs={12} md={3}>
                Name: 
            </Col>
            <Col xs={12} md={9}>
                {firstName} {lastName}
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={3}>
                Location: 
            </Col>
            <Col xs={12} md={9}>
                {city}, {state} {zip}
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={3}>
                Travel Availability: 
            </Col>
            <Col xs={12} md={9}>
                {travelAvailability}
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={3}>
                Additional Info: 
            </Col>
            <Col xs={12} md={9}>
                {additionalInfo}
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6}>
                <PrimaryButton 
                    label="Accept Application"
                    onClick={() => acceptApplication(application)} 
                />
            </Col>
            <Col xs={12} md={6}>
                <PrimaryButton
                    label="Deny Application"
                    onClick={() => denyApplication(application)}
                />
            </Col>
        </Row>
        <Row>
            <Col xs={12} md={6}>
                <PrimaryButton
                    label="Contact Applicant"
                    onClick={() => contactApplicant(application)}
                />
            </Col>
        </Row>
    </Grid>
    </div>)
}

export default Application;