import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Grid, Row, Col } from 'react-flexbox-grid';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
// import 'rc-time-picker/assets/index.css';
import Select from 'react-select';
import PlacesAutocomplete, {
    geocodeByAddress,
    getLatLng,
} from 'react-places-autocomplete';

import ReactLoading from 'react-loading';
import TimePicker from 'rc-time-picker';


import * as actions from '../../../actions';

import "./reservation-confirmation.css";

// Images
import Icon from '../../../images/icon.png';
import Logo from '../../../images/logo.png';

import PrimaryButton from '../../components/PrimaryButton';
import InputField from '../../components/InputField';
import SelectField from '../../components/SelectField';
import { SingleDatePicker } from 'react-dates';
import { DayPickerSingleDateController } from 'react-dates';
import MenuStep from './MenuStep';
import ConfirmationPage from './ConfirmationPage';

import SuccessModal from './SuccessModal';

// import PaymentForm from '../../components/PaymentForm';
import PaymentForm from '../../components/SquarePaymentForm';
import Stripe from '../../components/Stripe';

import states from '../../../utils/states-list';

const showSecond = false;
const str = showSecond ? 'HH:mm:ss' : 'HH:mm a';


var _ = require('lodash');

class ReservationConfirmationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            reservation: null,
            mealType: '',
            isButtonDisabled: true,
            menu: null,
            starter: null,
            focusedStarter: '',
            entree: null,
            focusedEntree: '',
            sides: null,
            focusedSideOne: '',
            focusedSideTwo: '',
            brunchEntrees: null,
            focusedBrunchEntreeOne: '',
            focusedBrunchEntreeTwo: '',
            partyDishes: [],
            step: 'reservation',
            // step: 'payment',
            showEditForm: false,
            focused: false,
            paymentComplete: false,
            showSuccessModal: false,
            fetching: false,
            streetAddress: '',
            unit: '',
            city: '',
            state: '',
            locationZip: '',
            isSaveEditButtonDisabled: false,
            exactReservationSize: 0,
            time: moment(),
            discountCode: '',
            giftCode: ''
        }

        this.navigateToMenu = this.navigateToMenu.bind(this);
        this.navigateToReservation = this.navigateToReservation.bind(this);
        this.navigateToLocation = this.navigateToLocation.bind(this);
        this.navigateToConfirm = this.navigateToConfirm.bind(this);
        this.navigateToPayment = this.navigateToPayment.bind(this);
        this.showEditForm = this.showEditForm.bind(this);
        this.hideEditForm = this.hideEditForm.bind(this);
        
        this.handleNameChange = this.handleNameChange.bind(this); //
        this.handleZipCodeChange = this.handleZipCodeChange.bind(this); //
        this.handleReservationDateChange = this.handleReservationDateChange.bind(this); //
        this.handleOtherChange = this.handleOtherChange.bind(this);
        this.handleReservationSizeChange = this.handleReservationSizeChange.bind(this); //
        this.handleCohostNameChange = this.handleCohostNameChange.bind(this);
        this.handleCohostEmailChange = this.handleCohostEmailChange.bind(this);
        this.handleGiftCodeChange = this.handleGiftCodeChange.bind(this);

        this.handleStarterChange = this.handleStarterChange.bind(this);
        this.handleEntreeChange = this.handleEntreeChange.bind(this);
        this.handleBrunchEntreeChange = this.handleBrunchEntreeChange.bind(this);
        this.handleSideChange = this.handleSideChange.bind(this);
        this.handlePartyDishChange = this.handlePartyDishChange.bind(this);

        this.handleMealTypeChange = this.handleMealTypeChange.bind(this);

        this.handleStreetAddressChange = this.handleStreetAddressChange.bind(this);
        this.handleUnitChange = this.handleUnitChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleLocationZipChange = this.handleLocationZipChange.bind(this);

        this.handleTimeChange = this.handleTimeChange.bind(this);

        this.handleExactReservationSizeChange = this.handleExactReservationSizeChange.bind(this);

        this.handleDiscountCodeChange = this.handleDiscountCodeChange.bind(this);

        this.confirmPayment = this.confirmPayment.bind(this);
        this.submitMenu = this.submitMenu.bind(this);

        this.toggleSuccessModal = this.toggleSuccessModal.bind(this);

        this.handleSaveEditButtonDisabled = this.handleSaveEditButtonDisabled.bind(this);

        this.toggleStep = this.toggleStep.bind(this);
    }

    

    handleNameChange(event) { this.setState({ name: event.target.value }, () => this.handleSaveEditButtonDisabled())} //
    handleZipCodeChange(event) { this.setState({ zipCode: event.target.value }, () => this.handleSaveEditButtonDisabled())} //
    handleReservationDateChange(event) { this.setState({ reservationDate : event.target.value }, () => this.handleSaveEditButtonDisabled()) } //
    handleReservationSizeChange(event) { 
        let reservationSize;
        if (event.value === "8-14") {
            reservationSize = 8;
        } else if (event.value === "15-30") {
            reservationSize = 15;
        } else if (event.value === "31+") {
            reservationSize = 31;
        } else if (event.value === "2-7") {
            reservationSize = 2;
        }
        this.setState({ reservationSize: reservationSize, partySize: event.value }, () => this.handleSaveEditButtonDisabled())
    }
    handleCohostNameChange(event) { this.setState({ cohostName: event.target.value }, () => this.handleSaveEditButtonDisabled())}
    handleCohostEmailChange(event) { this.setState({ cohostEmail: event.target.value }, () => this.handleSaveEditButtonDisabled())}
    handleRestrictionChange(event, index) { //
        let updatedRestrictions = this.state.dietaryRestrictions
        updatedRestrictions[index].required = event.target.checked
        this.setState({ dietaryRestrictions: updatedRestrictions }, () => this.handleSaveEditButtonDisabled()); 
    }
    handleOtherChange(event) {  //
        let updatedRestrictions = this.state.dietaryRestrictions
        if (event.target.value !== "") {
            updatedRestrictions[13].type = event.target.value
            updatedRestrictions[13].required = true
            this.setState({
                dietaryRestrictions: updatedRestrictions
            }, () => this.handleSaveEditButtonDisabled())
        } else {
            updatedRestrictions[13].type = ""
            updatedRestrictions[13].required = false
            this.setState({
                dietaryRestrictions: updatedRestrictions
            }, () => this.handleSaveEditButtonDisabled())
        }
     }

     handleGiftCodeChange(event) { this.setState({ giftCode: event.target.value.split('-')[1] })}


    handleSaveEditButtonDisabled() {
        if (!this.state.dietaryRestrictions[0].required && !this.state.dietaryRestrictions[1].required && !this.state.dietaryRestrictions[2].required && !this.state.dietaryRestrictions[3].required && !this.state.dietaryRestrictions[4].required && !this.state.dietaryRestrictions[5].required && !this.state.dietaryRestrictions[6].required && !this.state.dietaryRestrictions[7].required && !this.state.dietaryRestrictions[8].required && !this.state.dietaryRestrictions[9].required && !this.state.dietaryRestrictions[10].required && !this.state.dietaryRestrictions[11].required && !this.state.dietaryRestrictions[12].required && !this.state.dietaryRestrictions[13].required) {
            this.setState({ isSaveEditButtonDisabled: true })
        } else if (this.state.name === "" || this.state.reservationSize === "" || this.state.zipCode === "" || !this.state.reservationDate) {
            this.setState({ isSaveEditButtonDisabled: true })
        } else {
            this.setState({ isSaveEditButtonDisabled: false })
        }
    }

    handleStarterChange(starter) { 
        if (this.state.starter === starter) {
            this.setState({ starter: null, focusedStarter: '' })
        } else {
            this.setState({ starter: starter, focusedStarter: starter.name }) 
        }
    }
    handleEntreeChange(entree) { 
        if (this.state.entree === entree) {
            this.setState({ entree: null, focusedEntree: '' })
        } else {
            this.setState({ entree: entree, focusedEntree: entree.name  }) 
        }
    }

    handleBrunchEntreeChange(entree) {
        let brunchEntrees = this.state.brunchEntrees;
        if (brunchEntrees === null) {
            brunchEntrees = [
                { entreeOne: entree },
                { entreeTwo: null }
            ]
            this.setState({
                brunchEntrees: brunchEntrees,
                focusedBrunchEntreeOne: entree.name
            })
        } else if (brunchEntrees[0].entreeOne !== null && brunchEntrees[1].entreeTwo === null) {
            brunchEntrees = [
                { entreeOne: brunchEntrees[0].entreeOne },
                { entreeTwo: entree }
            ]
            this.setState({ 
                brunchEntrees: brunchEntrees, 
                focusedBrunchEntreeTwo: entree.name 
            })
        } else if (brunchEntrees[0].entreeOne !== null && brunchEntrees[1].entreeTwo !== null) {
            if (brunchEntrees[0].entreeOne === entree && brunchEntrees[1].entreeTwo === entree) {
                this.setState({
                    brunchEntrees: null,
                    focusedBrunchEntreeOne: '',
                    focusedBrunchEntreeTwo: ''
                })
            } else {
                brunchEntrees = [
                    { entreeOne: brunchEntrees[1].entreeTwo },
                    { entreeTwo: entree }
                ]
                
                this.setState({ 
                    brunchEntrees: brunchEntrees, 
                    focusedBrunchEntreeOne: brunchEntrees[0].entreeOne.name, 
                    focusedBrunchEntreeTwo: entree.name 
                })
            }
        } else if (brunchEntrees[0].entreeOne && brunchEntrees[1].entreeTwo) {
            console.log("Doubled")
            console.log(entree, brunchEntrees[0].entreeOne);
        }
    }

    handleSideChange(side) { 
        let sides = this.state.sides;
        if (sides === null) {
            
            sides = [
                { sideOne: side },
                { sideTwo: null }
            ]
            this.setState({ 
                sides: sides, 
                focusedSideOne: side.name 
            })
        } else if (sides[0].sideOne !== null && sides[1].sideTwo === null) {
            sides = [
                { sideOne: sides[0].sideOne },
                { sideTwo: side }
            ]
            this.setState({ 
                sides: sides, 
                focusedSideTwo: side.name 
            })
        } else if (sides[0].sideOne !== null && sides[1].sideTwo !== null) {
            if (sides[0].sideOne === side && sides[1].sideTwo === side) {
                this.setState({
                    sides: null,
                    focusedSideOne: '',
                    focusedSideTwo: ''
                })
            } else {
                sides = [
                    { sideOne: sides[1].sideTwo },
                    { sideTwo: side }
                ]
                
                this.setState({ 
                    sides: sides, 
                    focusedSideOne: sides[0].sideOne.name, 
                    focusedSideTwo: side.name 
                })
            }
        } else if (sides[0].sideOne && sides[1].sideTwo) {
            console.log("Doubled")
            console.log(side, sides[0].sideOne);
        }
    }
    handlePartyDishChange(dish) {
        let dishes = this.state.partyDishes;
        if (_.includes(dishes, dish)) {
            _.remove(dishes, dish);
        } else {
            if (dishes.length === 5) {
                alert("Max amount of dishes is 5! Please deselect a dish to add another.")
            } else {
                dishes.push(dish)
            }
        }
        this.setState({
            partyDishes: dishes
        })
       
    }

    handleMealTypeChange(event) {
        this.setState({ 
            mealType: event.value,
            isButtonDisabled: false 
        }, () => {
            if (event.value === 'party' || event.value === 'favorites') {
                this.setState({
                    mealTimes: [
                        { label: '9:00 am', value: '9:00 am' }, { label: '9:30 am', value: '9:30 am' }, { label: '10:00 am', value: '10:00 am' },
                        { label: '10:30 am', value: '10:30 am' }, { label: '11:00 am', value: '11:00 am' }, { label: '11:30 am', value: '11:30 am' },  { label: '12:00 pm', value: '12:00 pm' }, { label: '12:30 pm', value: '12:30 pm' }, { label: '1:00 pm', value: '1:00 pm' }, 
                        { label: '1:30 pm', value: '1:30 pm' }, { label: '2:00 pm', value: '2:00 pm' }, { label: '2:30 pm', value: '2:30 pm' },
                        { label: '3:00 pm', value: '3:00 pm' }, { label: '3:30 pm', value: '3:30 pm' }, { label: '4:00 pm', value: '4:00 pm' },
                        { label: '4:30 pm', value: '4:30 pm' }, { label: '5:00 pm', value: '5:00 pm' }, { label: '5:30 pm', value: '5:30 pm' },
                        { label: '6:00 pm', value: '6:00 pm' }, { label: '6:30 pm', value: '6:30 pm' }, { label: '7:00 pm', value: '7:00 pm' },
                        { label: '7:30 pm', value: '7:30 pm' },
                    ]
                })
            } else if (event.value === 'brunch') {
                this.setState({ 
                    mealTimes: [
                        { label: '9:00 am', value: '9:00 am' }, { label: '9:30 am', value: '9:30 am' }, { label: '10:00 am', value: '10:00 am' },
                        { label: '10:30 am', value: '10:30 am' }, { label: '11:00 am', value: '11:00 am' }, { label: '11:30 am', value: '11:30 am' },
                        { label: '12:00 pm', value: '12:00 pm' }
                    ]  
                })
            } else if (event.value === 'fall + winter dinner' || event.value === 'spring + summer dinner') {
                this.setState({
                    mealTimes: [
                        { label: '5:00 pm', value: '5:00 pm' }, { label: '5:30 pm', value: '5:30 pm' }, { label: '6:00 pm', value: '6:00 pm' },
                        { label: '6:30 pm', value: '6:30 pm' }, { label: '7:00 pm', value: '7:00 pm' }, { label: '7:30 pm', value: '7:30 pm' }
                    ]
                })
            }
        }) 
    }

    handlePlacesChange = address => {
        console.log(address)
        // this.setState({ streetAddress: address });
    };
     
    handlePlacesSelect = address => {
        console.log(address)
        this.setState({ streetAddress: address})
        geocodeByAddress(address)
            .then(results => getLatLng(results[0]))
            .then(latLng => {
                let lat = latLng.lat;
                let lng = latLng.lng;
                console.log(lat, lng);
                fetch(`http://api.timezonedb.com/v2.1/get-time-zone?key=HXSU5Q6S9SJ1&format=json&by=position&lat=${lat}&lng=${lng}`)
                .then(response => {
                    return response.json();
                })
                .then(data => {
                    console.log(data);
                    let userOffset = new Date().getTimezoneOffset() * -1;
                    let dinnerOffset = data.gmtOffset / 60;
                    let difference = (dinnerOffset - userOffset) / 60;
                    let timezone;
                    switch (dinnerOffset) {
                        case (-480):
                            timezone = 'US/Pacific'
                            break;
                        case (-420):
                            timezone = 'US/Mountain'
                            break;
                        case (-360):
                            timezone = 'US/Central'
                            break;
                        case (-300):
                            timezone = 'US/Eastern'
                            break;  
                        default:
                            timezone = 'UNKNOWN'
                            break;  
                    }
                    console.log(timezone);
                    console.log("user: " + userOffset + " dinner: " + dinnerOffset + " difference: " + difference + " hours")
                    this.setState({
                        // timezone: data.zoneName,
                        timezone: timezone,
                        abbrevTimezone: data.abbreviation,
                        timeDiff: difference,
                        searchPlacesActive: false,
                        streetAddress: address
                    })
                })
          })
          .catch(error => console.error('Error', error));
    };

    handleStreetAddressChange(event) {
        this.setState({ 
            streetAddress: event.target.value
        })
    }

    handleUnitChange(event) {
        this.setState({
            unit: event.target.value
        })
    }

    handleCityChange(event) {
        this.setState({
            city: event.target.value
        })
    }

    handleStateChange(event) {
        console.log(event.value)
        this.setState({
            state: event.value
        })
    }

    handleLocationZipChange(event) {
        this.setState({
            locationZip: event.target.value
        })
    }

    handleTimeChange(event) {
        let time = moment(event.value, "H:mm a");
        let timelabel = event.value
        // var googleTime = moment(event.value, "H:mm a")
        // googleTime.hours()
        // console.log(this.state.timeDiff)
        // this.state.timeDiff >= 0 
            // ? googleTime.subtract(this.state.timeDiff, 'h').hours()
            // : googleTime.add(this.state.timeDiff, 'h').hours()
        
        this.setState({
            time: time,
            timelabel: timelabel,
            timeChanged: true,
            // googleTime: googleTime
        }, () => {
            console.log(this.state);
        })
    }

    handleExactReservationSizeChange(event) {
        this.setState({ 
            reservation: {
                ...this.state.reservation,
                reservationSize: event.target.value
            }
        }, () => console.log(this.state.reservation))
    }

    handleDiscountCodeChange(event) {
        this.setState({
            discountCode: event.target.value
        })
    }

    // Old
    // handleTimeChange(value) {
    //     let time = value;
    //     this.setState({
    //         time: time,
    //         timeChanged: true
    //     })
    // }

    toggleSuccessModal() {
        this.setState({ showSuccessModal: !this.state.showSuccessModal })
    }

    goHome() {
        this.toggleSuccessModal()
        window.location.href = 'https://www.eatplacemat.com'; 
    }

    componentDidMount() {
        let id = this.props.match.params.id
        return this.props.getReservation(id)
            .then(resp => {
                if (resp.reservation) {
                    console.log(resp);
                    this.setState({ 
                        loading: false,
                        fetching: false,
                        reservation: resp.reservation,
                        name: resp.reservation.name,
                        zipCode: resp.reservation.zipCode,
                        reservationDate: moment(resp.reservation.reservationDate),
                        reservationSize: resp.reservation.reservationSize,
                        partySize: resp.reservation.partySize,
                        cohostName: resp.reservation.cohostName,
                        cohostEmail: resp.reservation.cohostEmail,
                        dietaryRestrictions: resp.reservation.dietaryRestrictions,
                        giftCode: resp.reservation.giftCode || ""
                    }, console.log(this.state))
                } else {
                    this.setState({
                        loading: false
                    })
                }
            })
    }

    toggleStep(step) {
        this.setState({ 
            step: step 
        })
    }

    showEditForm() {
        this.setState({ showEditForm: true })
    }

    hideEditForm() {
        let updatedReservation = {
            ...this.state.reservation,
            name: this.state.name,
            zipCode: this.state.zipCode,
            reservationDate: this.state.reservationDate,
            reservationSize: this.state.reservationSize,
            partySize: this.state.partySize,
            cohostName: this.state.cohostName,
            cohostEmail: this.state.cohostEmail,
            dietaryRestrictions: this.state.dietaryRestrictions
        }
        this.setState({ 
            reservation: updatedReservation,
            showEditForm: false 
        })
    }

    buildEmailMenu(menu, mealType) {
        switch (mealType) {
            case("party"): 
                return `<p>
                    ${menu.dishes.map((dish, index) => {
                        return `${dish.name}<br/>`
                    })}
                </p>`
            case("brunch"):
                return `<p>
                    Entrees: <br/>
                    ${menu.entree[0].entreeOne.name}<br/> 
                    ${menu.entree[1].entreeTwo.name}<br/><br/>
                    Sides: <br/>
                    ${menu.sides[0].sideOne.name}<br/> 
                    ${menu.sides[1].sideTwo.name}<br/>
                </p>`;
            case("favorites"):
                return `<p>
                    Favorites
                </p>`;
            default: 
                return `<p>
                    Entree: <br/>
                    ${menu.entree.name}<br/><br/>
                    Sides: <br/>
                    ${menu.sides[0].sideOne.name}<br/>
                    ${menu.sides[1].sideTwo.name}<br/><br/>
                    Starter: <br/>
                    ${menu.starter.name}
                </p>`;
        }
    }

    

    submitMenu() {
        this.setState({ fetching: true }, () => {
            var menu = {}
            if (this.state.mealType === "party") {
                menu = {
                    dishes: this.state.partyDishes
                }
            } else if (this.state.mealType === "brunch") {
                menu = {
                    entree: this.state.brunchEntrees,
                    sides: this.state.sides
                }
            } else if (this.state.mealType === "favorites") {
                menu = {
                    favorites: 'favorites'
                }   
            } else {
                menu = {
                    starter: this.state.starter,
                    entree: this.state.entree,
                    sides: this.state.sides
                }
            }

            let location = {
                streetAddress: this.state.streetAddress,
                unit: this.state.unit,
                city: this.state.city,
                state: this.state.state,
                locationZip: this.state.locationZip
            }

            let time = this.state.time
            let timezone = this.state.timezone
            // let googleTime = this.state.googleTime
            // let timezone = 'America/Los_Angeles'
    
            return this.props.confirmReservationMenu(this.state.reservation, menu, location, time, timezone, this.state.mealType)
                .then(resp => {
                    console.log(resp);
                    if (resp.code === 200) {
                        let calendarTime = resp.body.reservationTime;
                        let formattedDate = moment(this.state.reservation.reservationDate).utc().format("MMMM D, YYYY");
                        // let formattedDate = moment(this.state.reservation.reservationTime)
                        let formattedTime = moment(this.state.reservation.reservationTime).format('h:mma')
                        let confirmationUrl = `https://www.reservations.eatplacemat.com/hosts/reservation/confirm/`
                        let hostEmail = {
                            recipient: this.state.reservation.email,
                            sender: 'reservations@eatplacemat.com',
                            subject: `Your Placemat Reservation is Confirmed: ${this.state.mealType} for ${this.state.reservation.reservationSize} on ${formattedDate}!`,
                            html: `<div>
                            <p>Hello!</p>
                            <p>Your reservation for ${this.state.reservation.reservationSize} with Placemat is now confirmed! We have received your deposit + menu selections for ${formattedDate}!</p>
                            <p>On ${formattedDate}, your Placemat team will arrive 90 minutes prior to your selected time. The time that you selected is when we plan to serve you and your guests!</p> 
                            <p>Please do not hesitate with any questions between now + your event.</p>  
                            <p>We are looking forward to having you experience Placemat!</p> 
                            <p>You can view your confirmation and share it with your guests <a href="https://reservations.eatplacemat.com/hosts/reservation/confirm/${this.state.reservation.id}">here</a>.</p>
                            <p>See you soon!</p>
                            <p>The Placemat Team</p>
                            </div>`
                        }
                        // Dietary Restrictions,  Menu
                        let adminEmail = {
                            recipient: 'reservations@eatplacemat.com',
                            sender: 'reservations@eatplacemat.com',
                            subject: 'Confirmed Reservation',
                            html: `<div>
                                <p>${this.state.reservation.name} has just confirmed their reservation. Please review details below:</p>
                                <p>Host Email: ${this.state.reservation.email}</p>
                                <p>Address: ${this.state.reservation.streetAddress}</p>
                                <p>City/State: ${this.state.reservation.city}, ${this.state.reservation.state} ${this.state.reservation.zipCode}</p>
                                <p>Meal Type: ${this.state.reservation.mealType}</p>
                                <p>Reservation Size: ${this.state.reservation.reservationSize}</p>
                                <p>Gift Code: GIFT-${this.state.giftCode}</p>
                                <p>Date: ${formattedDate}</p>
                                <p>Time: ${formattedTime}</p>
                                <p><a href="https://reservations.eatplacemat.com/hosts/reservation/confirm/${this.state.reservation.id}">Confirmation Page</a></p>
                            </div>`
                        }
                        let justinEmail = {
                            recipient: 'jbavier123@gmail.com',
                            sender: 'reservations@eatplacemat.com',
                            subject: 'Confirmed Reservation',
                            html: `<div>
                                <p>${this.state.reservation.name} has just confirmed their reservation. Please review details below:</p>
                                <p>Host Email: ${this.state.reservation.email}</p>
                                <p>Address: ${this.state.reservation.streetAddress}</p>
                                <p>City/State: ${this.state.reservation.city}, ${this.state.reservation.state} ${this.state.reservation.zipCode}</p>
                                <p>Meal Type: ${this.state.reservation.mealType}</p>
                                <p>Reservation Size: ${this.state.reservation.reservationSize}</p>
                                <p>Date: ${formattedDate}</p>
                                <p>Time: ${formattedTime}</p>
                                <p>Gift Code: GIFT-${this.state.giftCode}</p>
                                <p>Dietary Restrictions ${this.state.dietaryRestrictions.map((restriction, index) => {
                                    return restriction.required ?
                                        restriction.type
                                        : null
                                })}</p>
                                <p>Menu ${this.buildEmailMenu(menu, this.state.reservation.mealType)}</p>
                                <p><a href="https://reservations.eatplacemat.com/hosts/reservation/confirm/${this.state.reservation.id}">Confirmation Page</a></p>
                            </div>`
                        }
                        if (this.state.reservation.cohostEmail !== "N/A") {
                            let cohostEmail = {
                                recipient: this.state.reservation.cohostEmail,
                                sender: 'reservations@eatplacemat.com',
                                subject: `Your Placemat Reservation is Confirmed: ${this.state.mealType} for ${this.state.reservation.partySize} on ${formattedDate}!`,
                                html: `<div>
                                <p>Hello!</p>
                                <p>Your reservation for ${this.state.reservation.partySize} with Placemat is now confirmed! We have received your deposit + menu selections for ${formattedDate}!</p>
                                <p>On ${formattedDate}, your Placemat team will arrive 90 minutes prior to your selected time. The time that you selected is when we plan to serve you and your guests!</p> 
                                <p>Please do not hesitate with any questions between now + your event.</p>  
                                <p>We are looking forward to having you experience Placemat!</p> 
                                <p>You can view your confirmation and share it with your guests <a href="https://reservations.eatplacemat.com/hosts/reservation/confirm/${this.state.reservation.id}">here</a>.</p>
                                <p>See you soon!</p>
                                <p>The Placemat Team</p>
                                </div>`
                            }
                            this.props.sendEmail(cohostEmail)
                        }
                        this.props.getChefs()
                            .then(resp => {
                                return resp.body.map((chef) => {
                                    // let devUrl = `http://localhost:3000/chefs`
                                    let prodUrl = `https://reservations.eatplacemat.com/chefs`
                                    let chefEmail = {
                                        recipient: chef.email,
                                        sender: 'reservations@eatplacemat.com',
                                        subject: `New Reservation Available!`,
                                        html: `<div>
                                            <h3>There's a new meal up for grabs!</h3>
                                            <p>Address: ${this.state.reservation.streetAddress}</p>
                                            <p>City/State: ${this.state.reservation.city}, ${this.state.reservation.state} ${this.state.reservation.zipCode}</p>
                                            <p>Meal Type: ${this.state.reservation.mealType}</p>
                                            <p>Reservation Size: ${this.state.reservation.reservationSize}</p>
                                            <p>Date: ${formattedDate}</p>
                                            <p>Time: ${formattedTime}</p>
                                            <p>Reply "YES" to this email if you are interest in cooking.</p>
                                        </div>`
                                    }
                                    return this.props.sendEmail(chefEmail);
                                })
                            })
                            this.props.sendEmail(justinEmail)
                            this.props.sendEmail(adminEmail)
                            return this.props.sendEmail(hostEmail)
                                .then(response => {
                                    this.setState({ fetching: false }, () => {
                                        // this.toggleSuccessModal();
                                        this.toggleStep('receipt');
                                        if (this.state.reservation.googleEventId) { 
                                           
                                            // var datetime = resp.body.reservationDate.split('T')[0] + 'T' + resp.body.googleTime.split('T')[1]
                                            var date = resp.body.reservationDate.split('T')[0]
                                            // datetime = datetime.substring(0, datetime.length - 5) + "Z";
                                            // console.log(datetime, this.state.timezone);
                                            var event = {
                                                "summary": `CONFIRMED: ` + this.state.reservation.name + ` at ${formattedTime}`,
                                                "location": this.state.reservation.streetAddress + ' ' + this.state.reservation.city + ', ' + this.state.reservation.state + ' ' + this.state.reservation.zipCode,
                                                "description": `<div>
                                                <p>Confirmed ${this.state.reservation.mealType} at ${formattedTime}</p> 
                                                <br />
                                                ${this.buildEmailMenu(menu, this.state.reservation.mealType)}
                                                </div>`,
                                                "start": {
                                                    "date": date,
                                                },
                                                "end": {
                                                    "date": date,
                                                }
                                            }
                                            console.log(event);
                                            var eventId = this.state.reservation.googleEventId
                                            this.props.updateCalendarEvent(event, eventId, "ro3tsg0vjooqkj0om00dprqn7g@group.calendar.google.com");
                                            return;
                                        }
                                        return;
                                    })
                                })
                    } else {
                        alert("Error!")
                    }
                })
        })
    }

    confirmPayment(success) {
        if (success) {
            this.submitMenu();
        } else {
            console.log("Error");
        }
    }

    getTimezone(lat, lng) {
        return fetch(`http://api.timezonedb.com/v2.1/get-time-zone?key=HXSU5Q6S9SJ1&format=json&by=position&lat=${lat}&lng=${lng}`)
            .then(response => {
                return response.json()
            })
            .then(data => {
                return data;
            })
    }

    navigateToMenu() {
        this.toggleStep('menu');
    }

    navigateToReservation() {
        this.toggleStep('reservation')
    }

    navigateToLocation() {
        this.toggleStep('location')
        // this.toggleStep('receipt')
    }

    navigateToConfirm() {
        this.toggleStep('confirm')
    }

    navigateToPayment() {
        this.toggleStep('payment')
    }

    renderDietaryRestrictions(dietaryRestrictions) {
        let dietaryRestrictionsArray = [];
        if (dietaryRestrictions[0].required) { dietaryRestrictionsArray.push('None, ') }
        if (dietaryRestrictions[1].required) { dietaryRestrictionsArray.push('Nut, ') }
        if (dietaryRestrictions[2].required) { dietaryRestrictionsArray.push('Vegan, ') }
        if (dietaryRestrictions[3].required) { dietaryRestrictionsArray.push('Keto, ') }
        if (dietaryRestrictions[4].required) { dietaryRestrictionsArray.push('Shellfish, ') }
        if (dietaryRestrictions[5].required) { dietaryRestrictionsArray.push('Peanut, ') }
        if (dietaryRestrictions[6].required) { dietaryRestrictionsArray.push('Vegetarian, ') }
        if (dietaryRestrictions[7].required) { dietaryRestrictionsArray.push('Gluten, ') }
        if (dietaryRestrictions[8].required) { dietaryRestrictionsArray.push('Paleo, ') }
        if (dietaryRestrictions[9].required) { dietaryRestrictionsArray.push('Soy, ') }
        if (dietaryRestrictions[10].required) { dietaryRestrictionsArray.push('Whole30, ') }
        if (dietaryRestrictions[11].required) { dietaryRestrictionsArray.push('Grain, ') }
        if (dietaryRestrictions[12].required) { dietaryRestrictionsArray.push('Dairy, ') }
        if (dietaryRestrictions[13].required) { dietaryRestrictionsArray.push(dietaryRestrictions[13].type)}
        dietaryRestrictionsArray[dietaryRestrictionsArray.length-1] = _.split(dietaryRestrictionsArray[dietaryRestrictionsArray.length-1], ',', 1);
        return dietaryRestrictionsArray.map((string, index) => { return <span key={index}>{string}</span> })
    }

    renderReservationInfo() {
        if (!this.state.showEditForm) {
            const { name, zipCode, reservationDate, partySize, reservationSize, cohostEmail, cohostName, dietaryRestrictions } = this.state.reservation;
            let formattedDate = moment(reservationDate).utc().format("MMMM D, YYYY");
            return (<>
                <Row center="xs">
                    <p>Please confirm the following information is correct.</p>
                </Row>
                <Row center="xs">
                    <Col xs={12} md={8}>
                        <h3>{name}'s Reservation</h3>
                    </Col>
                </Row>
                <hr />
                <Row>
                    <Col xs={12} md={6}>
                        <p>Zip: {zipCode}</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <p>Reservation Date: {formattedDate}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <p>Cohost's Name: {cohostName}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <p>Cohost's Email: {cohostEmail}</p>
                    </Col>
                    <Col xs={12} md={6}>
                        <p>Reservation Size: {partySize ? partySize : reservationSize}</p>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        <p>Dietary Restrictions: {this.renderDietaryRestrictions(dietaryRestrictions)}</p>
                    </Col>
                </Row>
                <hr />
            </>)
        } else {
            return (<>
                    <Row center="xs">
                        <p>When you have finished making your changes please hit "Finished Editing"</p>
                    </Row>
                    <Row center="xs">
                        <Col xs={12} md={8}>
                            <h3>Edit Your Reservation</h3>
                        </Col>
                    </Row>
                    <hr />  
                    {/* Name */}
                    <Row>
                        <Col xs={12} md={8} mdOffset={2}>
                            <p>Your Name</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={8} mdOffset={2}>
                            <InputField placeholder="Name" value={this.state.name} onChange={this.handleNameChange} type="text" />
                        </Col>


                    </Row>

                    {/* Zip Code */}
                    <Row>
                        <Col xs={12} md={4} mdOffset={2}>
                            <p>Zip</p>
                        </Col>
                        <Col xs={12} md={4}>
                            <p>Reservation Date</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4} mdOffset={2}>
                            <InputField placeholder="Zip Code" value={this.state.zipCode} onChange={this.handleZipCodeChange} type="text" />
                        </Col>
                        <Col xs={12} md={4}>
                            <DayPickerSingleDateController 
                                date={this.state.reservationDate}
                                // numberOfMonths={1}
                                onDateChange={reservationDate => this.setState({ reservationDate: reservationDate })}
                                focused={this.state.focused}
                                onFocusChange={({ focused }) => this.setState({ focused })}
                                hideKeyboardShortcutsPanel
                                isOutsideRange={(day) => day.isBefore(moment().add(10, 'days'))}
                                transitionDuration={0}
                                isDayBlocked={(momentDate) => {
                                    const blockedDates = [moment("2021-07-01"), moment("2021-07-02"), moment("2021-07-03"), moment("2021-07-04"), moment("2021-07-05"),
                                    moment("2021-07-06"), moment("2021-07-07"), moment("2021-07-08"), moment("2021-07-09"), moment("2021-07-10"), moment("2021-07-11"),
                                    moment("2021-07-12"), moment("2021-07-13"), moment("2021-07-14"), moment("2021-07-15"), moment("2021-07-16"), moment("2021-07-17")]
                                    return blockedDates.some(date => momentDate.isSame(date, 'day'))
                                }}
                            />
                            {/* , '07-02-2021', '07-03-2021', '07-04-2021', '07-05-2021',
                                    '07-06-2021', '07-07-2021', '07-08-2021', '07-09-2021', '07-10-2021', '07-11-2021', '07-12-2021',
                                    '07-13-2021', '07-14-2021', '07-15-2021', '07-16-2021', '07-17-2021' */}
                           
                        </Col>
                    </Row>
                    

                    {/* Reservation Size */}
                    <Row>
                        <Col xs={12} md={4} mdOffset={2}>
                            <p>Cohost's Name</p>
                        </Col>
                        <Col xs={12} md={4}>
                            <p>Cohost's Email</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4} mdOffset={2}>
                            <InputField placeholder="Cohost's Name" value={this.state.cohostName} onChange={this.handleCohostNameChange} type="text" />
                        </Col>
                        <Col xs={12} md={4}>
                            <InputField placeholder="Cohost's Email" value={this.state.cohostEmail} onChange={this.handleCohostEmailChange} type="text" />
                        </Col>
                    </Row>
                    <Row>     
                        <Col xs={12} md={4} mdOffset={2}>
                            <p>Reservation Size</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12} md={4} mdOffset={2}>
                        <SelectField label={this.state.partySize} placeholder="Please select a party size" value={this.state.partySize} onChange={this.handleReservationSizeChange} type="number" showError={this.state.displayErrors} errorMessage={this.state.errorMessage} options={[
                            { label: "2-7", value: "2-7"},
                            { label: "8-14", value: "8-14"},
                            { label: "15-30", value: "15-30"},
                            { label: "31+", value: "31+"}
                        ]}/>
                        </Col>
                    </Row>

                    {/* Dietary Restrictions */}
                    <Row>
                        <Col xs={12} md={4}>
                            <p>Dietary Restrictions</p>
                        </Col>
                    </Row>
                    <Row>
                        {this.state.dietaryRestrictions.map((restriction, index) => {
                            if (restriction.type === 'nut' || restriction.type === 'vegan' || restriction.type === 'keto' || restriction.type === 'shellfish' || restriction.type === 'peanut' || restriction.type === 'vegetarian' || restriction.type === 'gluten' || restriction.type === 'paleo' || restriction.type === 'soy' || restriction.type === 'whole30' || restriction.type === 'grain' || restriction.type === 'dairy' || restriction.type === 'none') {
                                return <Col key={index} xs={12} md={4}><div className="dietary-preference-checkbox-wrapper">
                                    <Row middle="xs">
                                        <input className="dietary-preference-checkbox" type="checkbox" onChange={(e) => this.handleRestrictionChange(e, index)} checked={this.state.dietaryRestrictions[index].required} />
                                        <p>{restriction.type}</p>
                                    </Row>
                                </div></Col>
                            } else {
                                return (
                                    <Col xs={12} md={4} key={index}>
                                        <div className="dietary-preference-checkbox-wrapper">
                                            <Row middle="xs">
                                                <input className="dietary-preference-input" type="text" value={this.state.dietaryRestrictions[13].type} placeholder="Other" onChange={this.handleOtherChange.bind(this)} />
                                            </Row>
                                        </div>
                                    </Col>
                                )
                            }
                        })}
                    </Row>
                    <hr />
            </>)
        }
        
    }

    renderConfirmationMenu(confirmMenu) {
        if (this.state.mealType === 'fall + winter dinner' || this.state.mealType === 'spring + summer dinner') {
            return (
                <><Row><Col xs={12} md={12}>
                    <p className="reservation-confirmation-header">Menu</p>
                </Col></Row>
                <Row><Col xs={12} md={12}>
                    <p className="reservation-confirmation-body">Starter: {confirmMenu.menu.starter.name}</p>
                </Col></Row>
                <Row><Col xs={12} md={12}>
                    <p className="reservation-confirmation-body">Entree: {confirmMenu.menu.entree.name}</p>
                </Col></Row>
                <Row><Col xs={12} md={12}>
                    <p className="reservation-confirmation-body">
                        Sides:
                        {
                            confirmMenu.menu.sides[0].sideOne.name === confirmMenu.menu.sides[1].sideTwo.name ? 
                            // One Double Side
                            ` ${confirmMenu.menu.sides[0].sideOne.name} (Double)` :
                            // Two Sides
                            ` ${confirmMenu.menu.sides[0].sideOne.name} and ${confirmMenu.menu.sides[1].sideTwo.name}`
                        }
                    </p>
                </Col></Row>
                <Row><Col xs={12} md={12}>
                    <p className="reservation-confirmation-body">We've got the salad and dessert.</p>
                </Col></Row> 
                </>
            )
        } else if (this.state.mealType === 'party') {
            return (
                <><Row><Col xs={12} md={12}>
                    <p className="reservation-confirmation-header">Menu</p>
                </Col></Row>    
                <Row><Col xs={12} md={12}>
                    {this.state.partyDishes.map((dish, index) => {
                        return <p className="reservation-confirmation-body" key={index}>{dish.name}</p>
                    })}
                </Col></Row></>
            )             
        } else if (this.state.mealType === 'brunch') {
            return (
                <><Row><Col xs={12} md={12}>
                    <p className="reservation-confirmation-header">Menu</p>
                </Col></Row>
                <Row><Col xs={12} md={12}>
                    <p className="reservation-confirmation-body">
                        Entrees: 
                        {
                                confirmMenu.menu.entrees[0].entreeOne.name === confirmMenu.menu.entrees[1].entreeTwo.name ?
                                // One Double Entree
                                ` ${confirmMenu.menu.entrees[0].entreeOne.name} (Double)` :
                                // Two Entreess
                                ` ${confirmMenu.menu.entrees[0].entreeOne.name} and ${confirmMenu.menu.entrees[1].entreeTwo.name}`
                            }
                    </p>
                </Col></Row>
                <Row><Col xs={12} md={12}>
                    <p className="reservation-confirmation-body">
                        Sides:
                        {
                            confirmMenu.menu.sides[0].sideOne.name === confirmMenu.menu.sides[1].sideTwo.name ? 
                            // One Double Side
                            ` ${confirmMenu.menu.sides[0].sideOne.name} (Double)` :
                            // Two Sides
                            ` ${confirmMenu.menu.sides[0].sideOne.name} and ${confirmMenu.menu.sides[1].sideTwo.name}`
                        }
                    </p>
                </Col></Row></>
            )
        } else if ('favorites') {
            return (
                <><Row><Col xs={12} md={12}>
                    <p className="reservation-confirmation-header">Menu</p>
                </Col></Row>    
                <Row><Col xs={12} md={12}>
                    <p>Favorites</p>
                </Col></Row></>
            )      
        }
    }

    renderContent() {
        var mealTypes = [
            { label: "Brunch; start the morning or weekend off right", value: "brunch" },
            { label: "Fall + Winter Dinner", value: "fall + winter dinner" },
            { label: "Spring + Summer Dinner", value: "spring + summer dinner" },
            { label: "Party; party food, small bites, appetizers, finger foods", value: "party"},
            { label: "Favorites; our top 6 menu items.", value: "favorites" }
        ]
        if (!this.state.loading) {
            // Array of disabled dates
            // var disabledDates = [ '11-20-2020', '11-25-2020', '11-26-2020', '11-27-2020', '12-12-2020', '12-22-2020', '12-23-2020','12-24-2020', '12-25-2020', '12-26-2020', '12-31-2020'];
            // // Get reservation date
            // var checkDate = moment(this.state.reservation.reservationDate.split("T")[0]);
            // let isDisabled = false;
            // // If reservation date is equal to a disabled date, set isDisabled to false
            // for (var i = 0; i < disabledDates.length; i++) {
            //     if (moment(disabledDates[i]).format("YYYY-MM-DD") === moment(checkDate).format("YYYY-MM-DD")) {
            //         isDisabled = true;
            //         break;
            //     }
            // }
            if (this.state.reservation.isConfirmed) {
                return (<>
                    <ConfirmationPage init={this.state} />
                </>)
            // If reservation date is less than 10 days from today, or a disabled date, display message to repick date.
            } 
            // else if (checkDate.isBefore(moment().add(10, 'days')) || isDisabled) {
            //     return (<>
            //         <h3>We're sorry, this date is no longer available. Please select a new date <a href="https://www.eatplacemat.com">here.</a></h3>
            //     </>)
            // } 
            else {
                switch (this.state.step) {
                    case ('reservation'):
                        return (<>
                            {this.renderReservationInfo()}
                            <Row center="xs">
                                {!this.state.showEditForm ? 
                                <p>If this information looks correct, select what type of event you are planning and hit continue to pick out your menu. If not, hit edit to make necessary changes before moving on.</p>
                                : ''
                                }
                            </Row>
                            {!this.state.showEditForm ?
                            <Row center="xs">
                                <Col xs={12} md={6}>
                                    <SelectField value={this.state.mealType} label={this.state.mealType ? this.state.mealType : "Select one..."} placeholder="Select" onChange={this.handleMealTypeChange} options={mealTypes} isSearchable={false} />
                                </Col>
                            </Row>
                            : ''
                            }
                            <Row center="xs">
                                {!this.state.showEditForm ? <Col xs={12} md={3}><PrimaryButton onClick={this.showEditForm} label="Edit" /></Col>
                                : <Col xs={12} md={3}><PrimaryButton onClick={this.hideEditForm} label="Finished Editing" disabled={this.state.isSaveEditButtonDisabled} /></Col>    
                                }
                                {!this.state.showEditForm ? <Col xs={12} md={3}><PrimaryButton onClick={this.navigateToMenu} label="Continue" disabled={this.state.isButtonDisabled} /></Col> : null }
                            </Row>
                        </>)
                    case ('menu'):
                        return <MenuStep 
                            mealType={this.state.mealType}
                            handleStarterChange={this.handleStarterChange.bind(this)}
                            handleEntreeChange={this.handleEntreeChange.bind(this)}
                            handleBrunchEntreeChange={this.handleBrunchEntreeChange.bind(this)}
                            handleSideChange={this.handleSideChange.bind(this)}
                            handlePartyDishChange={this.handlePartyDishChange.bind(this)}
                            navigateToReservation={this.navigateToReservation.bind(this)}
                            navigateToLocation={this.navigateToLocation.bind(this)}
                            focusedStarter={this.state.focusedStarter}
                            focusedSideOne={this.state.focusedSideOne}
                            focusedSideTwo={this.state.focusedSideTwo}
                            focusedBrunchEntreeOne={this.state.focusedBrunchEntreeOne}
                            focusedBrunchEntreeTwo={this.state.focusedBrunchEntreeTwo}
                            focusedEntree={this.state.focusedEntree}
                            partyDishes={this.state.partyDishes}
                        />
                    case('location'):
                        return (<>
                            <Row center="xs">
                                <p>Where will we be cooking?</p>
                            </Row>

                            {/* Street Address, Unit */}
                            <Row>
                                
                            </Row>
                            <Row>
                                <Col xs={12} md={6} mdOffset={2}>
                                    <InputField placeholder="Street Address" value={this.state.streetAddress} onChange={this.handleStreetAddressChange} type="text" />
                                    {/* <PlacesAutocomplete
                                        value={this.state.streetAddress}
                                        onChange={this.handlePlacesChange}
                                        onSelect={this.handlePlacesSelect}
                                    >
                                        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
                                        <div>
                                            <input
                                            {...getInputProps({
                                                placeholder: 'Search Places ...',
                                                className: 'location-search-input',
                                            })}
                                            // THIS IS SCARY CODE 
                                            onFocus={() => this.setState({ searchPlacesActive: true })}
                                            onKeyDownCapture={(e) => {
                                                e.persist()
                                                if ((e.which === 9 && this.state.searchPlacesActive) || (e.which === 13 && this.state.searchPlacesActive)) {
                                                    suggestions.map(suggestion => {
                                                        if (suggestion.active) {
                                                            this.setState({ 
                                                                streetAddress: suggestion.formattedSuggestion.mainText
                                                            }, () => {
                                                                this.handlePlacesSelect(suggestion.description)
                                                                if (e.which === 13) e.keyCode = 9;
                                                            })
                                                        }
                                                    })
                                                } 
                                            }}
                                            // END OF THE HAUNTED MANSION
                                            />
                                            <div className="autocomplete-dropdown-container">
                                            {loading && <div>Loading...</div>}
                                            {suggestions.map(suggestion => {
                                                const className = suggestion.active
                                                ? 'suggestion-item--active'
                                                : 'suggestion-item';
                                                // inline style for demonstration purpose
                                                const style = suggestion.active
                                                ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                                : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                                return (
                                                <div
                                                    {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                    })}
                                                >
                                                    <span>{suggestion.description}</span>
                                                </div>
                                                );
                                            })}
                                            </div>
                                        </div>
                                        )}
                                    </PlacesAutocomplete> */}
                                </Col>
                                <Col xs={12} md={3}>
                                    <InputField placeholder="Unit" value={this.state.unit} onChange={this.handleUnitChange} type="text" />
                                </Col>
                            </Row>
                            {/* City, State, Zip */}
                            <Row>
                                <Col xs={12} md={4} mdOffset={2}>
                                    <InputField placeholder="City" value={this.state.city} onChange={this.handleCityChange} type="text" />
                                </Col>
                                <Col xs={12} md={2}>
                                    {/* <SelectField
                                        value={this.state.state ? this.state.state : " " }
                                        label={this.state.state ? this.state.state : "State" }
                                        // placeholder={this.state.state}
                                        onChange={this.handleStateChange}
                                        isSearchable={true}
                                        options={states}
                                    /> */}
                                    <Select
                                        defaultValue={this.state.state === '' ? { value: '', label: 'State' } : { value: this.state.state, label: this.state.state }}
                                        // styles={selectStyles}
                                        options={states} 
                                        onChange={this.handleStateChange}
                                        isSearchable={true}
                                        // placeholder={placeholder}
                                    />
                                </Col>
                                <Col xs={12} md={3}>
                                    <InputField placeholder="Zip" value={this.state.locationZip} onChange={this.handleLocationZipChange} type="text" />
                                </Col>
                            </Row>

                            <Row center="xs">
                                <p>What time do you want to eat?</p>
                            </Row>
                            <Row center="xs">
                                <p>We show up 90 minutes prior to selected time to start prepping.</p>
                            </Row>

                            <Row center="xs" style={{ marginBottom: `2.5%` }}>
                                <Col xs={12} md={4}>
                                    {/* <TimePicker
                                        style={{ width: `100%` }}
                                        showSecond={showSecond}
                                        defaultValue={moment(this.state.time).startOf('hour')}
                                        className="xxx"
                                        onChange={this.handleTimeChange}
                                        use12Hours
                                        minuteStep={30}
                                    /> */}
                                    <SelectField 
                                        value={this.state.time}
                                        label={this.state.timelabel ? this.state.timelabel : "Select one..."}
                                        // placeholder={this.state.timelabel}
                                        onChange={this.handleTimeChange}
                                        options={this.state.mealTimes}
                                    />
                                </Col>
                            </Row>

                            <Row center="xs" style={{ margin: `2.5%` }}>
                                <p>Please specify the number of people that will be eating:</p>
                            </Row>

                            <Row center="xs" style={{ margin: `2.5%` }}>
                                <Col xs={12} md={3}>
                                    <InputField placeholder="Party Size" value={this.state.reservation.reservationSize} onChange={this.handleExactReservationSizeChange} type="number" />
                                </Col>
                            </Row>

                            <Row center="xs" style={{ margin: `2.5%` }}>
                                <Col xs={11} sm={8} md={7} lg={8} xl={8}>
                                    <p className="body-text" style={{ marginTop: 0 }}>*For parties less than 8, the pricing is: <br />$300 for parties of 2-3 <br />$400 for parties of 4 <br />$450 for parties of 5-7</p>
                                </Col>
                            </Row>

                            <Row center="xs" style={{ margin: `2.5%` }}>
                            <Col xs={12} md={8}>
                                <InputField placeholder="Code" value={`GIFT-${this.state.giftCode !== "" ? this.state.giftCode : ""}`} onChange={this.handleGiftCodeChange} type="text" />
                            </Col>
                            </Row>

                            <Row center="xs">
                                <Col xs={12} md={3}>
                                    <PrimaryButton onClick={this.navigateToMenu} label="Go Back" />
                                </Col>
                                <Col xs={12} md={3}>
                                    <PrimaryButton onClick={this.navigateToConfirm} label="Next Step" disabled={ this.state.streetAddress && this.state.city && this.state.state && this.state.locationZip && this.state.timeChanged && this.state.reservation.reservationSize >= 2 ? false : true } />
                                </Col>
                            </Row>
                        </>)
                    case('confirm'):
                        var menuItems = {}
                        if (this.state.mealType === 'party') {
                            menuItems = {
                                dishes: this.state.partyDishes
                            }
                        } else if (this.state.mealType === 'brunch') {
                            menuItems = {
                                entrees: this.state.brunchEntrees,
                                sides: this.state.sides,
                            }
                        } else {
                            menuItems = {
                                starter: this.state.starter,
                                entree: this.state.entree,
                                sides: this.state.sides,
                            }
                        }
                        let confirmMenu = {
                            ...this.state.reservation,
                            menu: menuItems,
                            streetAddress: this.state.streetAddress,
                            unit: this.state.unit,
                            city: this.state.city,
                            state: this.state.state,
                            locationZip: this.state.locationZip,
                            time: this.state.time,
                            giftCode: this.state.giftCode
                        }

                        console.log(confirmMenu);
                        
                        const { menu, streetAddress, giftCode, unit, city, state, locationZip, time } = confirmMenu;
                        const { name, email, cohostName, cohostEmail, dietaryRestrictions, reservationDate } = this.state.reservation;
                  
                        console.log(time)
                        return (<>
                            <Row center="xs"><Col xs={12} md={12}>
                                <p>Please confirm the following is correct:</p>
                            </Col></Row>
                            <div className="reservation-confirmation-wrapper">
                             {/* Time */}
                             <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-header">Date and Time</p>
                            </Col></Row>
                            <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-body">{moment(reservationDate).utc().format("MMMM D, YYYY")} at {moment(time).format('h:mm a')}</p>
                            </Col></Row>

                            {/* Location */}
                            <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-header">Location</p>
                            </Col></Row>
                            <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-body">{streetAddress} {unit ? unit : ''}</p>
                            </Col></Row>
                            <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-body">{city}, {state} {locationZip}</p>
                            </Col></Row>

                            {/* Menu */}
                            {this.renderConfirmationMenu(confirmMenu)}

                            {/* Host Info */}
                            <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-header">Host Information</p>
                            </Col></Row>
                            {/* Name */}
                            <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-body">Name: {name}</p>
                            </Col></Row>
                            {/* Email */}
                            <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-body">Email: {email}</p>
                            </Col></Row>
                            {this.state.reservation.phone ? <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-body">Phone: {this.state.reservation.phone}</p>
                            </Col></Row> : "" }

                            { cohostName === 'N/A' ? '' :
                            <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-body">Cohost Name: {cohostName}</p>
                            </Col></Row>
                            }
                            {/* Cohost */}
                            { cohostEmail === 'N/A' ? '' :
                            <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-body">Cohost Email: {cohostEmail}</p>
                            </Col></Row>
                            }
                            {/* Dietary Restrictions */}
                            <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-body">Dietary Restrictions: { dietaryRestrictions.map((restriction, index) => {
                                    if (restriction.required) { return restriction.type + ' ' }
                                })}</p>
                            </Col></Row>
                            {/* Reservation Date */}
                            <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-body">Party Size: {this.state.reservation.reservationSize}</p>
                            </Col></Row>
                            {console.log(confirmMenu)}
                            <Row><Col xs={12} md={12}>
                                <p className="reservation-confirmation-body">Gift Code: {giftCode !== "" ? `GIFT-${giftCode}` : "N/A"}</p>
                            </Col></Row>

                            
                            

                        

                            

                           
                            </div>

                            <Row center="xs">
                                <Col xs={12} md={3}>
                                    <PrimaryButton onClick={this.navigateToLocation} label="Go Back" />
                                </Col>
                                <Col xs={12} md={3}>
                                    <PrimaryButton 
                                        // onClick={this.submitMenu}
                                        onClick={this.navigateToPayment} 
                                    label="Next Step" />
                                </Col>
                            </Row>
                        </>)
                    case('payment'):
                        return (<>
                            <Row center="xs">
                                <p>Please enter your card information and hit "Pay" to make your $50 deposit and confirm your reservation.</p>
                            </Row>
                            <Row center="xs">
                                <Col xs={12} md={2} />
                                <Col xs={12} md={8}>
                                    {/* <PaymentForm
                                        // paymentForm={ window.SqPaymentForm }
                                        reservation={this.state.reservation}
                                        streetAddress={this.state.streetAddress}
                                        city={this.state.city}
                                        locationZip={this.state.locationZip}
                                        submitMenu={this.submitMenu}
                                        navigateToConfirm={this.navigateToConfirm}
                                        toggleStep={this.toggleStep}
                                    /> */}
                                    <Stripe 
                                        reservation={this.state.reservation}
                                        confirmPayment={this.confirmPayment}
                                        navigateToConfirm={this.navigateToConfirm}
                                    />
                                     <Row center="xs" style={{ width: `125%`, marginLeft: `-12.5%` }}>
                                        <Col xs={12} md={12}>
                                            <p>Your $50 deposit is to hold + confirm reservation date, which is applied to the final bill. Placemat does not store credit card information. This is a single authorization charge made by Placemat, LLC. If your reservation is cancelled more than 5 days prior to reservation date, Placemat will refund your deposit.</p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col xs={12} md={2} />
                            </Row>
                            <Row center="xs">
                                <Col xs={12} md={6}>
                                    <div className="discount-submit">
                                        <p className="discount-link" style={{ textDecoration: "underline" }} onClick={() => this.toggleStep("discount")}>Have a discount?</p>
                                    </div>
                                </Col>
                            </Row>
                        </>)
                    case('discount'):
                        return(<>
                            <Row center="xs">
                                <p>Please enter your discount code below:</p>
                            </Row>
                            <Row center="xs">
                                <Col xs={12} md={4}>
                                    <InputField placeholder="Discount Code" value={this.state.discountCode} onChange={this.handleDiscountCodeChange} type="text" />
                                </Col>
                            </Row>
                            <Row center="xs">
                                {this.state.discountCode === "Placemat2020" ? 
                                    <PrimaryButton label="Submit" onClick={this.submitMenu} />
                                    : <p>Invalid Code</p>
                                }
                            </Row>
                            <Row center="xs">
                                <p className="discount-link" onClick={() => this.toggleStep('payment')}>Go Back</p>
                            </Row>
                        </>)
                    case('receipt'):
                        return (<ConfirmationPage init={this.state} />)
                    default:
                        return;
                }
            }
        }
    }

    render() {
        return (<div className="reservation-body">
            <div className="disable-overlay" style={{ visibility: this.state.fetching ? 'visible' : 'hidden ' }}><h1 className="disable-overlay-loading">Loading</h1></div>
            <div className="reservation-card">
                <Grid fluid>
                    <Row center="xs" className="reservation-card-header">
                        <Col xs={12} md={3}>
                            <img src={Logo} alt="logo" className="reservation-card-logo" />
                        </Col>
                    </Row>
                    <Row>
                        <Col md={1} />
                        <Col xs={12} md={10}>
                            {this.renderContent()}
                        </Col>
                        <Col md={1} />
                    </Row>
                    {/* Loading */}
                    <Row center="xs" style={{ visibility: this.state.fetching ? 'visible' : 'hidden' }}>
                        <div className="loading-icon" style={{ 
                            position: 'fixed',
                            top: `30%`,
                            textAlign: 'center',
                            zIndex: 10,
                        }}>
                            <ReactLoading type="bubbles" color="black" height={100} width={150} />
                        </div>
                    </Row>
                </Grid>
                <SuccessModal
                    isOpen={this.state.showSuccessModal}
                    onClose={() => this.goHome()}
                />
            </div>
        </div>)
    } 
}

function mapStateToProps(state) { return state }
export default withRouter(connect(mapStateToProps, actions)(ReservationConfirmationPage));