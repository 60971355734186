function getNextWeekday(weekday) {
    var d = new Date();
    var offset;
    switch(weekday) {
        case("Monday"):
            offset = 1;
            break;
        case("Tuesday"):
            offset = 2;
            break;
        case("Wednesday"):
            offset = 3;
            break;
        case("Thursday"):offset = 4;
            break;
        case("Friday"):
            offset = 5;
            break;
        default:
            break;
    }
    d.setDate(d.getDate() + (7-d.getDay())%7+offset).toString()
    let month = '' + (d.getMonth() + 1),
        day = '' + d.getDate(),
        year = d.getFullYear()

    if (month.length < 2) 
        month = '0' + month;
    if (day.length < 2) 
        day = '0' + day;

    let calDate = [year, month, day].join('-');
    return calDate;
}

export default getNextWeekday;