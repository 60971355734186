import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import PrimaryButton from '../../components/PrimaryButton';
import InputField from '../../components/InputField';
import SelectField from '../../components/SelectField';

// Utils
import states from '../../../utils/states-list';

// ======================================== //
// ===== LOCATION & TRAVEL PREFERENCE ===== //
// ======================================== //
class ApplicationTwo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            city: this.props.application.city || '',
            state: this.props.application.state || '',
            zip: this.props.application.zip || '',
            travelAvailability: this.props.application.travelAvailability || '',
            isButtonDisabled: true
        }

        this.handleCityChange = this.handleCityChange.bind(this);   
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleZipChange = this.handleZipChange.bind(this);
        this.handleTravelAvailabilityChange = this.handleTravelAvailabilityChange.bind(this);
    }

    componentDidMount() {
        this.handleButtonEnabled();
    }

    handleCityChange(event) {
        this.setState({ city: event.target.value }, () => this.handleButtonEnabled())
    }

    handleStateChange(event) {
        this.setState({ state: event.value }, () => this.handleButtonEnabled())
    }

    handleZipChange(event) {
        this.setState({ zip: event.target.value }, () => this.handleButtonEnabled())
    }

    handleTravelAvailabilityChange(event) {
        this.setState({ travelAvailability: event.value },  () => this.handleButtonEnabled())
    }

    handleButtonEnabled() {
        if (this.state.city !== '' && this.state.state !== '' && this.state.zip !== '' && this.state.travelAvailability !== '' ) {
            this.setState({ isButtonDisabled: false })
        } else {
            this.setState({ isButtonDisabled: true })
        }
    }

    handleStepChange(step) { 
        let stepTwoApplication = {
            // NOT PART OF THIS STEP
            firstName: this.props.application.firstName,
            lastName: this.props.application.lastName,
            email: this.props.application.email,
            // PART OF THIS STEP
            city: this.state.city,
            state: this.state.state,
            zip: this.state.zip,
            travelAvailability: this.state.travelAvailability,
            // NOT PART OF THIS STEP
            additionalInfo: this.props.application.additionalInfo
        }
        this.props.toggleStep(step, stepTwoApplication)
    }

    render() {
        return (
            <Grid fluid>
                <Row around="xs">
                    <p>Step Two</p>
                </Row>
                <Row>
                    <Col xs={12} md={6} mdOffset={3}>
                        <InputField
                            placeholder="City"
                            value={this.state.city}
                            onChange={this.handleCityChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} mdOffset={3} style={{ padding: 0 }}>
                        <SelectField
                            value={this.state.state}
                            label={this.state.state}
                            placeholder={this.state.state === '' ? "State" : this.state.state}
                            onChange={this.handleStateChange}
                            isSearchable={true}
                            options={states}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} mdOffset={3}>
                        <InputField
                            placeholder="Zip"
                            value={this.state.zip}
                            onChange={this.handleZipChange}
                        />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6} mdOffset={3} style={{ padding: 0 }}>
                        <SelectField
                            value={this.state.travelAvailability}
                            label={this.state.travelAvailability}
                            placeholder={this.state.travelAvailability === '' ? "Travel availability" : this.state.travelAvailability}
                            onChange={this.handleTravelAvailabilityChange}
                            isSearchable={false}
                            options={[
                                { value: 'Statewide', label: 'Statewide' },
                                { value: 'Countrywide', label: 'Countrywide' },
                                { value: 'Worldwide', label: 'Worldwide' }
                            ]}
                        />
                    </Col>
                </Row>
                <Row around="xs">
                    <Col xs={12} md={6}>
                        <PrimaryButton onClick={() => this.handleStepChange('one')} label="Back" />
                    </Col>
                    <Col xs={12} md={6}>
                        <PrimaryButton onClick={() => this.handleStepChange('three')} label="Next" disabled={this.state.isButtonDisabled} />
                    </Col>
                </Row>
            </Grid>
        )
    }
}

export default ApplicationTwo;