import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';

import * as actions from '../../../actions';

// Stylesheets
import '../landing.css';

// Images
import Icon from '../../../images/icon.png';
import Logo from '../../../images/logo.png'; 

// Components
import ApplicationOne from './ApplicationOne';
import ApplicationTwo from './ApplicationTwo';
import ApplicationThree from './ApplicationThree';
import ConfirmApplication from './ConfirmApplication';

class ApplicationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            applicationStep: 'one',
            application: {
                firstName: '',
                lastName: '',
                email: '',
                city: '',
                state: '',
                zip: '',
                travelAvailability: '',
                additionalInfo: ''
            }
        }
    
        this.toggleApplicationStep = this.toggleApplicationStep.bind(this);
    }

    toggleApplicationStep(step, application) {
        this.setState({ 
            applicationStep: step, 
            application: application
        })
    }

    renderApplication(step) {
        switch (step) {
            case ('one'):
                return <ApplicationOne toggleStep={this.toggleApplicationStep} application={this.state.application} />
            case ('two'):
                return <ApplicationTwo toggleStep={this.toggleApplicationStep} application={this.state.application} />
            case ('three'):
                return <ApplicationThree toggleStep={this.toggleApplicationStep} application={this.state.application} />
            case ('confirm'):
                return <ConfirmApplication toggleStep={this.toggleApplicationStep} application={this.state.application} />
            default:
                return <ApplicationOne />
        }
    }

    render() {
        return (
        <div className="landing-body">
            <div className="landing-card">
                <Grid fluid>
                    <Row center="xs" className="landing-card-header">
                        <Col xs={12} md={1}>
                            <img src={Icon} alt="icon" className="landing-card-icon" />
                        </Col>
                        <Col md={1} />
                        <Col xs={12} md={8}>
                            <img src={Logo} alt="logo" className="landing-card-logo" />
                        </Col>
                        <Col md={2} />
                    </Row>
                    <Row around="xs">
                        <h3>Join our team!</h3>
                    </Row>
                    {this.renderApplication(this.state.applicationStep)}
                </Grid>
            </div>
        </div>
        )
    }
}

function mapStateToProps(state) {
    return { state }
}

export default withRouter(connect(mapStateToProps, actions)(ApplicationPage));