import React, { Component } from 'react';
import Modal from 'react-modal';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import PrimaryButton from '../../components/PrimaryButton';

// Styles
import "./application.css";

class SuccessModal extends Component {
    closeModal() {
        this.props.onClose();
    }

    render() {
        return (
            <Modal
                isOpen={this.props.isOpen}
                overlayClassName="success-modal-overlay"
                className="success-modal-content"
                shouldCloseOnOverlayClick={false}
            >
                <Grid fluid>
                    <Row around="xs" center="xs">
                        <Col xs={12} md={8} mdOffset={2}>
                            <p className="success-modal-header-text">Success</p>
                        </Col>
                    </Row>
                    <Row around="xs">
                        <Col xs={12} md={8} mdOffset={2}>
                            <p className="success-modal-body-text">Your application has been submitted. Check your email for a confirmation, and we'll follow up with you as soon as we can.</p> 
                        </Col>
                    </Row>
                    <Row around="xs">
                        <Col xs={12} md={8} mdOffset={2}>
                            <PrimaryButton onClick={() => this.closeModal()} label="Close" />
                        </Col>
                    </Row>
                </Grid>
            </Modal>
        )
    }
}

Modal.setAppElement('#root')

export default SuccessModal;