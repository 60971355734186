import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import moment from 'moment';

// Stylesheets
import '../landing.css';

// Images
import Icon from '../../../images/icon.png';
import Logo from '../../../images/logo.png';

// Components
import ReservationLanding from './ReservationLanding';
import ReservationOne from './ReservationOne';
import ReservationTwo from './ReservationTwo';
import ReservationThree from './ReservationThree';
import ReservationFour from './ReservationFour';
import ReservationFive from './ReservationFive';
import ConfirmReservation from './ConfirmReservation';

class ReservationPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            reservationStep: 'landing',
            loading: true,
            reservation: {
                id: '',
                name: '',
                email: '',
                phone: '',
                reservationDate: null,
                zipCode: '',
                reservationSize: '',
                partySize: '',
                cohostName: '',
                cohostEmail: '',
                dietaryRestrictions: [
                    { "none": false },
                    { "nut": false }, 
                    { "vegan": false }, 
                    { "keto": false }, 
                    { "shellfish": false },
                    { "peanut": false },
                    { "vegetarian": false },
                    { "gluten": false }, 
                    { "paleo": false }, 
                    { "soy": false }, 
                    { "whole30": false }, 
                    { "grain": false },
                    { "dairy": false },
                    { "other": "" }
                ],
                referral: '',
                giftCode: ''
            }
        }

        this.toggleReservationStep = this.toggleReservationStep.bind(this);
    }

    componentDidMount() {
        this.setState({ loading: true }, () => {
            if (this.props.location.search) {
                let query = this.props.location.search.split('?')[1].split('&');
                let zip = query[0].split('=')[1]
                let date = new Date(query[1].split('=')[1]);
                // let date = query[1].split('=')[1];
                

                // REMOVING GUESTS FOR NOW 
                let guests = query[2].split('=')[1];
                let partySize = guests;
                if (guests === "8-14") {
                    guests = 8
                } else if (guests === "15-30") {
                    guests = 15
                } else if (guests === "31+") {
                    guests = 31
                } else if (guests === "2-7") {
                    guests = 0
                }


                console.log(date);
                this.setState({
                    reservation: {
                        ...this.state.reservation,
                        zipCode: zip,
                        reservationDate: moment(date),
                        reservationSize: guests,
                        partySize: partySize,
                        // reservationSize: 0,
                        // partySize: "N/A"
                    }
                }, () => { this.setState({ loading: false }) })
            } else { 
                this.setState({ loading: false}) 
            }
        })
    }

    toggleReservationStep(step, reservation) {
        this.setState({ 
            reservationStep: step, 
            reservation: reservation
        })
    }

    renderReservationForm(step) {
        if (!this.state.loading) {
            switch(step) {
                case ('landing'):
                    return <ReservationLanding toggleStep={this.toggleReservationStep} reservation={this.state.reservation} />
                case ('one'):
                    return <ReservationOne toggleStep={this.toggleReservationStep} reservation={this.state.reservation} />
                case ('two'):
                    return <ReservationTwo toggleStep={this.toggleReservationStep} reservation={this.state.reservation} />
                case ('three'):
                    return <ReservationThree toggleStep={this.toggleReservationStep} reservation={this.state.reservation} />
                case ('four'):
                    return <ReservationFour toggleStep={this.toggleReservationStep} reservation={this.state.reservation} />
                case ('five'):
                    return <ReservationFive toggleStep={this.toggleReservationStep} reservation={this.state.reservation} />
                case ('confirm'):
                    return <ConfirmReservation toggleStep={this.toggleReservationStep} reservation={this.state.reservation} />
                default:
                    return <ReservationOne toggleStep={this.toggleReservationStep} reservation={this.state.reservation} />
            }
        }
    }
    
    render() {
        return (
        <div className="reservation-body">
            <div className="reservation-card">
                <Grid fluid>
                    <Row center="xs" className="reservation-card-header">
                        <Col xs={12} sm={3} md={3}>
                            <img src={Logo} alt="logo" className="reservation-card-logo" />
                        </Col>
                    </Row>
                    <Row middle="xs" style={{ minHeight: `50vh` }}>
                        <Col xs={12}>
                            {this.renderReservationForm(this.state.reservationStep)}
                        </Col>
                    </Row>
                </Grid>
            </div>
        </div>
        )
    }
}

export default withRouter(ReservationPage);