import React from 'react';
import { Grid, Row } from 'react-flexbox-grid';

import './InputField.css';

const InputField = ({ placeholder, value, onChange, type, showError, errorMessage }) => {
    return (<Grid className="wrapper">
        <Row>
            <input className="input-field-wrapper" 
                placeholder={placeholder} 
                value={value}
                onChange={onChange}    
                type={type ? type : 'text'}
            />
        </Row>
        { showError ?
        <Row className="input-error-container">
            <p className="input-error-message">{errorMessage}</p>
        </Row>
        : <Row className="input-error-container" />
        }
    </Grid>)
}

export default InputField;