import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';
import moment from 'moment';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import 'rc-time-picker/assets/index.css';

// Components
import InputField from '../../components/InputField';
import TextArea from '../../components/TextArea';
import SelectField from '../../components/SelectField';
import PrimaryButton from '../../components/PrimaryButton';
import { SingleDatePicker } from 'react-dates';

import states from '../../../utils/states-list';

class Two extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delivery: this.props.delivery,
            buttonDisabled: true,
            focused: true
        }

        this.handleDeliveryTimeChange = this.handleDeliveryTimeChange.bind(this);
        this.handleStreetAddressChange = this.handleStreetAddressChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handleZipChange = this.handleZipChange.bind(this);
        this.handleInstructionsChange = this.handleInstructionsChange.bind(this);

    }

    componentDidMount() {
        document.querySelector('.SingleDatePickerInput').children[0].innerHTML = "";
        return this.setState({ 
            delivery: this.props.delivery
        })

    }

    handleDeliveryTimeChange(event) {
        this.setState({
            delivery: {
                ...this.state.delivery,
                deliveryTime: event.value
            }
        })
    }

    handleStreetAddressChange(event) {
        this.setState({
            delivery: {
                ...this.state.delivery,
                streetAddress: event.target.value
            }
        })
    }

    handleCityChange(event) {
        this.setState({
            delivery: {
                ...this.state.delivery,
                city: event.target.value
            }
        })
    }

    handleStateChange(event) {
        this.setState({
            delivery: {
                ...this.state.delivery,
                state: event.value
            }
        })
    }

    handleZipChange(event) {
        this.setState({
            delivery: {
                ...this.state.delivery,
                zip: event.target.value
            }
        })
    }

    handleInstructionsChange(event) {
        console.log(event.target.value)
        this.setState({
            delivery: {
                ...this.state.delivery,
                instructions: event.target.value
            }
        })
    }
    renderMonth() {
        let monthNum = moment().month();
        let months = [ "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December" ]
        let month = months[monthNum]

        return <h3>{month}</h3>
    }

    
    
    
    render() {
        let deliveryTimeOptions = [
            { "label" : "5:00pm", "value" : "5:00pm" },
            { "label" : "6:00pm", "value" : "6:00pm" },
            { "label" : "7:00pm", "value" : "7:00pm" }
        ]
        return (<Grid fluid>
            <Row center="xs">
                <Col xs={12} md={4}>
                    {this.renderMonth()}
                </Col>
            </Row>
            <Row center="xs" style={{ height: `370px` }}>
                <Col xs={12} md={4} style={{ marginTop: `-60px` }}>
                    <SingleDatePicker
                        date={this.state.delivery.deliveryDate} 
                        onDateChange={deliveryDate => this.setState({ 
                            delivery: {
                                ...this.state.delivery,
                                deliveryDate: deliveryDate 
                            }
                        }, () => console.log(this.state)) } 
                        focused={this.state.focused}
                        onFocusChange={() => console.log("no")} 
                        id="delivery_date_picker"
                        numberOfMonths={1} 
                        keepOpenOnDateSelect
                        hideKeyboardShortcutsPanel
                        isDayBlocked={day => {
                            let dayNum = moment(day).day();
                            if (dayNum === 0 || dayNum === 6) {
                                return true;
                            }
                            return false;
                            
                        }}
                        showDefaultInputIcon={false}
                        isOutsideRange={(day) => day.isBefore(moment().add(1, 'days'))}
                    
                    />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={5}>
                    <SelectField value={this.state.delivery.deliveryTime} onChange={this.handleDeliveryTimeChange} options={deliveryTimeOptions} label={ this.state.delivery.deliveryTime ? this.state.delivery.deliveryTime : "Delivery Time" } isSearchable={true} />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={5}>
                    <InputField placeholder="Street Address" value={this.state.delivery.streetAddress} onChange={this.handleStreetAddressChange} />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={2}>
                    <InputField placeholder="City" value={this.state.delivery.city} onChange={this.handleCityChange} />
                </Col>
                <Col xs={12} md={1}>
                    <SelectField value={this.state.delivery.state} onChange={this.handleStateChange} options={[ { "label" : "TN", "value" : "TN" } ]} label={this.state.delivery.state ? this.state.delivery.state : "State"} isSearchable={true} />
                </Col>
                <Col xs={12} md={2}>
                    <InputField placeholder="Zip" value={this.state.delivery.zip} onChange={this.handleZipChange} />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={5}>
                    <TextArea placeholder="Special Delivery Instructions" value={this.state.delivery.instructions} onChange={this.handleInstructionsChange} />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={2}>
                    <PrimaryButton onClick={() => this.props.switchStep('one', this.state.delivery)} label="Back" />
                </Col>
                <Col xs={12} md={2}>
                    <PrimaryButton onClick={() => this.props.switchStep('three', this.state.delivery)} label="Next" />
                </Col>
            </Row>
        </Grid>)
    }
}

export default Two;