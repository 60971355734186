import React, { Component } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import awsmobile from '../../../aws-exports';
import * as actions from '../../../actions';

import './navigation.css';

Amplify.configure(awsmobile);

class Navigation extends Component { 
    logout() {
        return Auth.signOut()
            .then(() => {
                return this.props.history.push('/')
            })
    }

    navigate(slug) {
        this.props.history.push(slug);
    }

    render() {
        return (<ul className="admin-nav-list">
            <li className="admin-nav-link" onClick={() => this.navigate('/admin/dashboard')}>Home</li>
            <li className="admin-nav-link" onClick={() => this.navigate('/admin/calendar')}>Calendar</li>
            <li className="admin-nav-link" onClick={() => this.navigate('/admin/chef-applications')}>Chef Applications</li>
            <li className="admin-nav-link" onClick={() => this.navigate('/admin/reservations')}>Reservations</li>
            <li className="admin-nav-link" onClick={() => this.navigate('/admin/invite-chefs')}>Invite Chefs</li>
            <li className="admin-nav-link" onClick={() => this.logout()}>Logout</li>
        </ul>)
    }
}

function mapStateToProps(state) { return { state } }
export default withRouter(connect(mapStateToProps, actions)(Navigation))