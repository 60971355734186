// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile =  {
    "aws_project_region": "us-east-1",
    "aws_content_delivery_bucket": "placemat-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://placemat-dev.s3-website-us-east-1.amazonaws.com",
    "aws_cognito_identity_pool_id": "us-east-1:3844e9ff-564b-41cd-a4a7-7b6455625dda",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_erBSAxvLa",
    "aws_user_pools_web_client_id": "5oa7a04qhc9a746q6gevgqujck"
};


export default awsmobile;
