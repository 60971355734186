import React, { Component } from 'react';
import Amplify, { Auth } from 'aws-amplify';
import { Grid, Row, Col } from 'react-flexbox-grid';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import ReactLoading from 'react-loading';

import awsmobile from '../../../aws-exports';
import * as actions from '../../../actions';

// Components
import Navigation from '../navigation/navigation';
import Application from './Application';

Amplify.configure(awsmobile);

class ChefApplications extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            tabToShow: 'unconfirmed',
            applications: null
        }

        this.acceptApplication = this.acceptApplication.bind(this);
        this.denyApplication = this.denyApplication.bind(this);
    }

    componentDidMount() {
        return this.setState({ loading: true }, () => {
            return Auth.currentAuthenticatedUser()
                .then(user => {
                    if (user.signInUserSession.idToken.payload['cognito:groups'][0] !== 'admins') {
                        alert('Incorrect account type.');
                        return Auth.signOut()
                            .then(() => {
                                return this.setState({ loading: false }, () => this.props.history.push('/admin'))
                            })
                    } else {
                        return this.props.hydrateUser(user.attributes.sub, 'admin')
                            .then(() => {
                                return this.props.getChefApplications()
                                    .then(resp => {
                                        let applications = resp.applications;
                                        return this.setState({ loading: false, applications })
                                    })
                            })
                    }
                })
            .catch(error => {
                console.log(error);
                return this.props.history.push('/admin')
            })
        })
    }

    acceptApplication(application) {
        return this.setState({ loading: true }, () => {
            return this.props.acceptChefApplication(application)
                .then(resp => {
                    if (resp.code === 200) {
                        // let devUrl = `http://localhost:3001/chefs/confirm-account/${application.email}/${resp.password}`
                        let prodUrl = `https://reservations.eatplacemat.com/chefs/confirm-account/${application.email}/${resp.password}`
                        let email = {
                            recipient: application.email,
                            sender: 'no-reply@eatplacemat.com',
                            subject: `Congrats!`,
                            html: `<div>
                                <h3>You've been accepted to be a Placemat Chef!</h3>
                                <p><a href=${prodUrl}>Confirm your account</a></p>
                            </div>`
                        }
                        this.props.sendEmail(email);
                        this.setState({ loading: false }, () => { this.props.getChefApplications() })
                    } else {
                        alert("Error!")
                    }
                })
        })
    }

    denyApplication(application) {
        return this.setState({ loading: true }, () => {
            return this.props.deleteChefApplication(application)
                .then(resp => {
                    const email = {
                        recipient: application.email,
                        sender: 'no-reply@eatplacemat.com',
                        subject: `Sorry, we couldn't accept your application.`,
                        html: `<div>
                            <p>After reviewing your application, we have decided we are unable to accept your application.</p>
                        </div>`
                    }
                    this.props.sendEmail(email);
                    this.setState({ loading: false }, () => { this.props.getChefApplications() })
                })
        })
    }

    renderContent() {
        if (!this.state.loading) {
            if (this.state.applications.length !== 0) {
                return this.state.applications.map((application, index) => {
                    return <Application 
                        key={index} 
                        application={application} 
                        acceptApplication={this.acceptApplication}
                        denyApplication={this.denyApplication}
                    />
                })
            } else {
                return <p>No Applications</p>
            }
        } else {
            return <p>Loading</p>
        }
    }

    render() {
        return (<div>
            <Navigation />
            <Grid fluid>
                <Row center="xs">
                    <Col xs={12} md={12}>
                        <h3>Manage Chef Applications</h3>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={12}>
                        {this.renderContent()}
                    </Col>
                </Row>
                {/* Loading */}
                <Row center="xs" style={{ visibility: this.state.fetching ? 'visible' : 'hidden' }}>
                    <div className="loading-icon" style={{ 
                        position: 'fixed',
                        top: `30%`,
                        textAlign: 'center',
                        zIndex: 10,
                    }}>
                        <ReactLoading type="bubbles" color="black" height={100} width={150} />
                    </div>
                </Row>
            </Grid>
        </div>)
    }
}

function mapStateToProps(state) { return { state } }
export default withRouter(connect(mapStateToProps, actions)(ChefApplications))
