import React, { Component } from 'react';
import { Grid, Row, Col } from 'react-flexbox-grid';

// Components
import InputField from '../../components/InputField';
import SelectField from '../../components/SelectField';
import PrimaryButton from '../../components/PrimaryButton';

import states from '../../../utils/states-list';

class Four extends Component {
    constructor(props) {
        super(props);
        this.state = {
            delivery: this.props.delivery,
            buttonDisabled: true,
            displayErrors: false,
            errorMessage: ""
        }

        this.handleButtonDisabled = this.handleButtonDisabled.bind(this);
        this.handleEmailChange = this.handleEmailChange.bind(this);
        this.handleConfirmEmailChange = this.handleConfirmEmailChange.bind(this);
        this.handleStreetAddressChange = this.handleStreetAddressChange.bind(this);
        this.handleCityChange = this.handleCityChange.bind(this);
        this.handleStateChange = this.handleStateChange.bind(this);
        this.handlePhoneChange = this.handlePhoneChange.bind(this);
    } 

    componentDidMount() {
        this.setState({
            delivery: this.props.delivery
        }, () => this.handleButtonDisabled)
    }

    handleButtonDisabled() {
        if (!this.state.delivery.email || !this.state.delivery.streetAddress || !this.state.delivery.city || !this.state.delivery.state || !this.state.delivery.phone || this.state.displayErrors) {
            this.setState({
                buttonDisabled: true
            })
        } else {
            this.setState({ 
                buttonDisabled: false 
            })
        }
    }

    handleEmailChange(event) {
        this.setState({
            delivery: {
                ...this.state.delivery,
                email: event.target.value
            }
        }, () => this.handleButtonDisabled())
    }

    handleConfirmEmailChange(event) {
        if (event.target.value !== this.state.delivery.email) {
            this.setState({
                displayErrors: true,
                errorMessage: "Emails must match!"
            }, () => this.handleButtonDisabled())
        } else {
            this.setState({
                displayErrors: false,
                errorMessage: ""
            }, () => this.handleButtonDisabled())
        }
    }
    handleStreetAddressChange(event) {
        this.setState({
            delivery: {
                ...this.state.delivery,
                streetAddress: event.target.value
            }
        }, () => this.handleButtonDisabled())
    }

    handleCityChange(event) {
        this.setState({
            delivery: {
                ...this.state.delivery,
                city: event.target.value
            }
        }, () => this.handleButtonDisabled())
    }

    handleStateChange(event) {
        this.setState({
            delivery: {
                ...this.state.delivery,
                state: event.value
            }
        }, () => this.handleButtonDisabled())
    }

    handlePhoneChange(event) {
        this.setState({
            delivery: {
                ...this.state.delivery,
                phone: event.target.value
            }
        }, () => this.handleButtonDisabled())
    }

    renderErrors() {
        if (this.state.displayErrors) {
            return (<Row center="xs">
                <Col xs={12} md={5}>
                    {this.state.errorMessage}
                </Col>
            </Row>)
        }
    }

    render() {
        return (<Grid fluid>
            <Row center="xs">
                <Col xs={12} md={5}>
                    <InputField placeholder="Email" value={this.state.delivery.email} onChange={this.handleEmailChange} />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={5}>
                    <InputField placeholder="Confirm Email" value={this.state.delivery.confirmEmail} onChange={this.handleConfirmEmailChange} />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={5}>
                    <InputField placeholder="Street Address" value={this.state.delivery.streetAddress} onChange={this.handleStreetAddressChange} />
                </Col>
            </Row>
            <Row center="xs">
                <Col xs={12} md={3}>
                    <InputField placeholder="City" value={this.state.delivery.city} onChange={this.handleCityChange} />
                </Col>
                <Col xs={12} md={2}>
                    <SelectField value={this.state.delivery.state} onChange={this.handleStateChange} options={states} label={this.state.delivery.state ? this.state.delivery.state : "State"} isSearchable={true} />
                </Col>
            </Row>

            <Row center="xs">
                <Col xs={12} md={5}>
                    <InputField placeholder="Phone" value={this.state.delivery.phone} onChange={this.handlePhoneChange} />
                </Col>
            </Row>

            <Row center="xs">
                <Col xs={12} md={2}>
                    <PrimaryButton onClick={() => this.props.switchStep('three', this.state.delivery)} label="Back" />
                </Col>
                <Col xs={12} md={2}>
                    <PrimaryButton onClick={() => this.props.switchStep('deposit', this.state.delivery)} label="Next" disabled={this.state.buttonDisabled} />
                </Col>
            </Row>

            {this.renderErrors()}
        </Grid>)
    }
}

export default Four;