import React, { Component } from 'react';
import { Row, Grid, Col } from 'react-flexbox-grid';
import { connect } from 'react-redux';
import Amplify, { Auth } from 'aws-amplify';
import moment from 'moment';
import ReactLoading from 'react-loading';


import * as actions from '../../../actions';
import awsmobile from '../../../aws-exports';

import './reservations.css';

// Components
import Navigation from '../navigation/navigation';
import Button from '../../components/PrimaryButton';

Amplify.configure(awsmobile);
var _ = require('lodash');

class MenuReservations extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabToShow: 'unconfirmed',
            loading: true,
            allReservations: null,
            unconfirmedReservations: null,
            confirmedReservations: null
        }
        this.toggleTab = this.toggleTab.bind(this);
        this.followUp = this.followUp.bind(this);
    }

    componentDidMount() {
        return this.setState({ loading: true }, () => {
            return Auth.currentAuthenticatedUser()
                .then(user => {
                    if (user.signInUserSession.idToken.payload['cognito:groups'][0] !== 'admins') {
                        alert('Incorrect account type.');
                        return Auth.signOut()
                            .then(() => {
                                return this.setState({ loading: false }, () => this.props.history.push('/admin'))
                            })
                    } else {
                        return this.props.hydrateUser(user.attributes.sub, 'admin')
                            .then(() => {
                                return this.props.getReservations()
                                    .then(resp => {
                                        let allReservations = resp.reservations;
                                        let unconfirmedReservations = _.filter(resp.reservations, { 'isConfirmed': false })
                                        unconfirmedReservations.sort((a, b) => new Date(a.reservationDate) - new Date(b.reservationDate))
                                        let confirmedReservations = _.filter(resp.reservations, { 'isConfirmed': true })
                                        confirmedReservations.sort((a, b) => new Date(a.reservationDate) - new Date(b.reservationDate))
                                        return this.setState({ loading: false, allReservations, unconfirmedReservations, confirmedReservations }, () => console.log(this.state))
                                    })
                            })
                    }
                })
            .catch(error => {
                console.log(error);
                return this.props.history.push('/admin')
            })
        })
    }

    toggleTab(tab) {
        this.setState({ tabToShow: tab })
    }

    followUp(reservation) {
        this.setState({ fetching: true }, () => {
            const { id, name, email, createdAt, reservationDate } = reservation;
            // let devUrl=`http://localhost:3000/hosts/reservation/confirm/`
            let prodUrl=`https://reservations.eatplacemat.com/hosts/reservation/confirm/`
            let followUpEmail = {
                recipient: email,
                sender: 'reservations@eatplacemat.com',
                subject: `Still want to eat with us?`,
                html: `<div>
                    <p>${name}, its been ${moment(createdAt).fromNow(true)} since you submitted your reservation for ${moment(reservationDate).utc().format('MMMM DD, YYYY')}. Please click the link below to pick your menu and confirm your meal.</p>
                    <a href=${prodUrl}${id}>Confirm Reservation</a>
                </div>`
            }
            
            this.props.sendEmail(followUpEmail)
                .then(() => {
                    if (!reservation.reminderCount) { reservation['reminderCount'] = 0; }
                    reservation['reminderCount'] = reservation['reminderCount'] + 1;
                    if (!reservation.lastReminderSent) { reservation['lastReminderSent'] = new Date() }
                    this.props.updateReservation(reservation)
                        .then(resp => {
                            console.log(resp);
                            this.setState({
                                fetching: false
                            })
                        })
                })
        })
    }

    sendConfirmationEmail(reservation) {
        this.setState({ fetching: true }, () => {

            let formattedDate = moment(reservation.reservationDate).utc().format("MMMM D, YYYY");
            // let formattedDate = moment(this.state.reservation.reservationTime)
            let formattedTime = moment(reservation.reservationTime).format('h:mma')
    
            let hostEmail = {
                recipient: reservation.email,
                sender: 'reservations@eatplacemat.com',
                subject: `Your Placemat Reservation is Confirmed: ${reservation.mealType} for ${reservation.reservationSize} on ${formattedDate}!`,
                html: `<div>
                <p>Hello!</p>
                <p>Your reservation for ${reservation.reservationSize} with Placemat is now confirmed! We have received your deposit + menu selections for ${formattedDate}!</p>
                <p>On ${formattedDate}, your Placemat team will arrive 90 minutes prior to your selected time. The time that you selected is when we plan to serve you and your guests!</p> 
                <p>Please do not hesitate with any questions between now + your event.</p>  
                <p>We are looking forward to having you experience Placemat!</p> 
                <p>See you soon!</p>
                <p>The Placemat Team</p>
                </div>`
            }
    
            this.props.sendEmail(hostEmail)
                .then(resp => {
                    this.setState({ fetching: false })
                })
        })
    }

    renderMenu(menu, mealType) {
        switch(mealType) {
            case ('brunch'):
                return (<>
                    <Row><Col xs={12} md={12}>
                        <p>Entree One: {menu.entree[0].entreeOne.name}</p>
                    </Col></Row>
                    <Row><Col xs={12} md={12}>
                        <p>Entree Two: {menu.entree[1].entreeTwo.name ? menu.entree[1].entreeTwo.name : 'Menu set after Brunch menu was updated to allow for two entrees.'}</p>
                    </Col></Row>
                    <Row><Col xs={12} md={12}>
                        <p>Side One: {menu.sides[0].sideOne.name}</p>
                    </Col></Row>
                    <Row><Col xs={12} md={12}>
                        <p>Side Two: {menu.sides[1].sideTwo.name}</p>
                    </Col></Row>
                </>)
            case ('fall + winter dinner'):
                return (<>
                    <Row><Col xs={12} md={12}>
                        <p>Starter: {menu.starter.name}</p>
                    </Col></Row>
                    <Row><Col xs={12} md={12}>
                        <p>Entree: {menu.entree.name}</p>
                    </Col></Row>
                    <Row><Col xs={12} md={12}>
                        <p>Side One: {menu.sides[0].sideOne.name}</p>
                    </Col></Row>
                    <Row><Col xs={12} md={12}>
                        <p>Side Two: {menu.sides[1].sideTwo.name}</p>
                    </Col></Row>
                </>)
            case ('spring + summer dinner'):
                return (<>
                    <Row><Col xs={12} md={12}>
                        <p>Starter: {menu.starter.name}</p>
                    </Col></Row>
                    <Row><Col xs={12} md={12}>
                        <p>Entree: {menu.entree.name}</p>
                    </Col></Row>
                    <Row><Col xs={12} md={12}>
                        <p>Side One: {menu.sides[0].sideOne.name}</p>
                    </Col></Row>
                    <Row><Col xs={12} md={12}>
                        <p>Side Two: {menu.sides[1].sideTwo.name}</p>
                    </Col></Row>
                </>)
            case ('party'):
                return (<>
                    <Row><Col xs={12} md={12}>
                        <p>{menu.dishes[0].name}</p>
                    </Col></Row>
                    <Row><Col xs={12} md={12}>
                        <p>{menu.dishes[1].name}</p>
                    </Col></Row>
                    <Row><Col xs={12} md={12}>
                        <p>{menu.dishes[2].name}</p>
                    </Col></Row>
                    <Row><Col xs={12} md={12}>
                        <p>{menu.dishes[3].name}</p>
                    </Col></Row>
                    <Row><Col xs={12} md={12}>
                        <p>{menu.dishes[4].name}</p>
                    </Col></Row>
                </>)
            case ('favorites'):
                return (<>
                </>)
            default:
                return;
        }
        console.log(menu)
        // return menu.map((course, index) => {
        //     return course.map((dish, index) => {
        //         return <p>{dish.name}</p>
        //     })
        // })
    }

    renderReminders(reservation) {
        if (reservation.reminderCount) {
            return (<Row>
                <Col xs={12} md={6}><p>Reminders Sent: {reservation.reminderCount}</p></Col> 
                <Col xs={12} md={6}><p>Last Reminder: {reservation.lastReminderSent ? moment(reservation.lastReminderSent).format('MMMM Do, YYYY') : '' }</p></Col>
            </Row>)
        } else {
            return (<>
                <p>Reminders Sent: 0</p>
            </>)
        }
    }

    renderReservations() {
        if (!this.state.loading) {
            if (this.state.tabToShow === 'unconfirmed') {
                return (<>
                    <Row center="xs">
                        <h3>Unconfirmed Reservations</h3>
                    </Row>
                    {this.state.unconfirmedReservations.length !== 0 
                        ? this.state.unconfirmedReservations.map((reservation, index) => {
                            const today = new Date()
                            const yesterday = new Date(today)
                            yesterday.setDate(yesterday.getDate() - 1)
                            if (new Date(reservation.reservationDate) >= yesterday) {
                            const { name, email, createdAt, reservationDate, reservationSize, requiredChefs, referral } = reservation;
                            const { phone } = reservation;
                            return (<div key={index} className="reservation-card">
                                {/* First and Last Name */}
                                <Row>
                                    <Col xs={12}>
                                        <p>{name}</p>
                                        
                                    </Col>
                                </Row>

                                <Row>
                                    <Col xs={12}>
                                        <p>Phone: {phone}</p>
                                    </Col>
                                </Row>
    
                                {/* Email */}  
                                <Row>
                                    <Col xs={12}>
                                        <p>Email: {email}</p>
                                    </Col>
                                </Row> 
    
                                {/* Reservation Submitted and Reservation Date */}
                                <Row>
                                    <Col xs={6}>
                                        <p>Submitted On: {moment(createdAt).format('MMMM DD, YYYY')} ({moment(createdAt).fromNow()})</p> 
                                    </Col>
                                    <Col xs={6}>
                                        <p>Reservation Date: {moment(reservationDate).utc().format('MMMM DD, YYYY')}</p>
                                    </Col>
                                </Row>
    
                                {/* Size of Reservation and Required Chefs */}
                                <Row>
                                    <Col xs={6}>
                                        <p>Size of Reservation: {reservationSize}</p>
                                    </Col>
                                    <Col xs={6}>
                                        <p>Number of Chefs: {requiredChefs}</p>
                                    </Col>
                                </Row>

                                {/* Referral */}
                                <Row>
                                    <Col xs={6}>
                                        <p>Referral: {referral}</p>
                                    </Col>
                                </Row>
                                <hr />
    
                                {/* Follow Up Button */}
                                <Row center="xs">
                                    <Col xs={10}>
                                        {this.renderReminders(reservation)}
                                    </Col>
                                </Row>
                                
                                <Row center="xs">
                                    <Col xs={4}>
                                        <Button onClick={() => this.followUp(reservation)} label="Send Reminder" />
                                    </Col>
                                </Row>
                                
                            </div>)
                            }
                        })
                        : null
                    }
                </>)
            } else if (this.state.tabToShow === 'confirmed') {
                return (<>
                    <Row center="xs">
                        <h3>Confirmed Reservations</h3>
                    </Row>
                    {this.state.confirmedReservations.length !== 0
                        ? this.state.confirmedReservations.map((reservation, index) => {
                            const today = new Date()
                            const yesterday = new Date(today)
                            yesterday.setDate(yesterday.getDate() - 1)
                            if (new Date(reservation.reservationDate) >= yesterday) {
                                const { name, email, createdAt, reservationDate, reservationSize, requiredChefs, city, state, zipCode, mealType, streetAddress, reservationTime, menu, dietaryRestrictions } = reservation;
                                const { phone } = reservation;
                                let formattedTime;
                                if (reservation.utcOffset) { 
                                    const { utcOffset } = reservation 
                                    formattedTime = moment(reservationTime).utcOffset(utcOffset * -1).format('h:mma')
                                } else {
                                    formattedTime = moment(reservationTime).format('h:mma')
                                }
                                let formattedDate = moment(reservationDate).utc().format('MMMM DD, YYYY')
                                return (<div key={index} className="reservation-card">
                                        <Row center="xs"><Col xs={12} md={12}>
                                            <p className="confirmed-reservation-header">Time and Date</p>
                                        </Col></Row>
    
                                        <Row center="xs"><Col xs={12} md={12}>
                                            <p className="confirmed-reservation-header">{formattedTime} on {formattedDate}</p>
                                        </Col></Row>
                                    <hr />
                                    <Row>
                                    <Col xs={12} md={6}>
                                        <Row><Col xs={12} md={12}>
                                            <p className="confirmed-reservation-label">Location</p>
                                        </Col></Row>
    
                                        <Row><Col xs={12} md={12}>
                                            <p>{streetAddress}</p>
                                        </Col></Row>
                                        <Row><Col xs={12} md={12}>
                                            <p>{city}, {state} {zipCode}</p>
                                        </Col></Row>
    
                                        <Row><Col xs={12} md={12}>
                                            <p className="confirmed-reservation-label">Reservation Contact</p>
                                        </Col></Row>
                                        <Row><Col xs={12} md={12}>
                                            <p>{name} at {email}</p>
                                            <p>Phone: {phone}</p>
                                        </Col></Row>
    
                                        <Row><Col xs={12} md={12}>
                                        <p>Required Chefs: {requiredChefs}</p>
                                        </Col></Row>
                                        
                                        <Row><Col xs={12} md={12}>
                                            <p>Size of Reservation {reservationSize}</p>
                                        </Col></Row>
                                    </Col>
    
                                    <Col xs={12} md={6}>
                                        <Row><Col xs={12} md={12}>
                                            <p className="confirmed-reservation-label">Menu:</p>
                                        </Col></Row>
    
                                        <Row><Col xs={12} md={12}>
                                            <p>Meal Type: {mealType}</p>
                                        </Col></Row>
    
                                        <Row><Col xs={12} md={12}>
                                            {this.renderMenu(menu, mealType)}
                                        </Col></Row>
                                        
                                        <Row><Col xs={12} md={12}>
                                            <p className="confirmed-reservation-label">Dietary Preferences:</p>
                                        </Col></Row>

                                        <Row><Col xs={12} md={12}>
                                            {dietaryRestrictions.map((restriction, index) => {
                                                return restriction.required ?
                                                    <p key={index}>{restriction.type} </p>
                                                    : ''
                                            })}
                                        </Col></Row>

                                    </Col>
                                    </Row>
                                    <Row center="xs"><Col xs={12} md={4}>
                                        <Button onClick={() => this.sendConfirmationEmail(reservation)} label="Resend Confirmation" />
                                    </Col></Row>
                                   
                                </div>
                                )
                            }
                        })
                        : null
                    }
                </>)
            }
        }

    }
    render() {
        return (<>
        <Navigation />
        <Grid fluid>
            <Row around="xs" center="xs">
                <p>Menu Reservations</p>
            </Row>
            <Row>
                <Col xs={6}>
                    <Button onClick={() => this.toggleTab('unconfirmed')} label="Unconfirmed" />
                </Col>
                <Col xs={6}>
                    <Button onClick={() => this.toggleTab('confirmed')} label="Confirmed" />
                </Col>
            </Row>
            {this.renderReservations()}
            {/* Loading */}
            <Row center="xs" style={{ visibility: this.state.fetching ? 'visible' : 'hidden' }}>
                <div className="loading-icon" style={{ 
                    position: 'fixed',
                    top: `30%`,
                    textAlign: 'center',
                    zIndex: 10,
                }}>
                    <ReactLoading type="bubbles" color="black" height={100} width={150} />
                </div>
            </Row>
        </Grid></>)
    }
}

function mapStateToProps(state) { return state }
export default connect(mapStateToProps, actions)(MenuReservations);
